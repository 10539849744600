import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { ICustomer, IUnit, IAssignmentItem } from '../view-models/customer-view-models';

export class KESCustomerService extends BaseService {
  constructor(axios: AxiosInstance, kesApiUrl: string) {
    super(kesApiUrl, axios);
  }

  public async getAssignedSites(): Promise<ICustomer[]> {
    return this.httpClient.get<ICustomer[]>('api/customer/assignedsites');
  }

  public async getAssignmentTree(): Promise<IAssignmentItem[]> {
    return this.httpClient.get<IAssignmentItem[]>('api/assignmenttree');
  }

  public async getAssetUnits(customerKey: string, siteKey: string): Promise<IUnit[]> {
    return this.httpClient.get<IUnit[]>('api/customer/' + customerKey + '/site/' + siteKey + '/assetgroups');
  }
}
