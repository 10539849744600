



































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IBurnersOrder, IWalkingOrder, IBurnerDefinition, IZone} from '@/view-models/hierarchy-view-models';
import store from '../../store';

@Component
export default class EquipmentList extends Vue {
  @Prop({required: true})
  private walkOrder: IWalkingOrder;
  @Prop({required: true})
  private addUnassigned: () => {};
  private store = store;

  private mounted(): void {
    store.commit('hierarchyState/sortWalkingOrder');
  }

  private get burnersWithDetails(): IBurnerDefinition[] {
    const burnersWithDetails = [] as IBurnerDefinition[];
    const zones = store.state.hierarchyState.hierarchy.zones;
    for (const zone of zones) {
      const burners = zone.burnerDefinitions;
      if (burners && burners.length) {
        for (const burner of burners) {
          if (burner.burnerName) {
            burnersWithDetails.push(burner);
          }
        }
      }
    }
    return burnersWithDetails;
  }

  private moveOrderUpOrDown(order: number, isDown: boolean): void {
    store.commit('hierarchyState/moveOrder', {
      walkOrder: this.walkOrder,
      source: order,
      target: isDown ? order + 1 : order - 1
    });
  }

  private handleDrop(item: IBurnersOrder, event: DragEvent): void {
    const data = event.dataTransfer.getData('text/plain');
    if (parseInt(data, 10)) {
      store.commit('hierarchyState/moveOrder', {
        walkOrder: this.walkOrder,
        source: parseInt(data, 10),
        target: item.order
      });
    } else {
      store.commit('hierarchyState/addZoneBurnersToWalkingOrder', {
        walkOrder: this.walkOrder,
        target: item.order,
        zoneKey: JSON.parse(data).associatedZoneKey
      });
    }
  }

  private getBurnerNameByKey(item: IBurnersOrder): string {
    const burnerByKey: IBurnerDefinition = this.burnersWithDetails.find((burner) => {
      return burner.burnerKey === item.burnerKey;
    });
    return burnerByKey && burnerByKey.burnerName ? burnerByKey.burnerName : '';
  }

  private getZoneNameByKey(item: IBurnersOrder): string {
    const zoneByKey: IZone = store.state.hierarchyState.hierarchy.zones.find((zone) => {
      return zone.zoneKey === item.zoneKey;
    });
    return zoneByKey && zoneByKey.zoneName ? zoneByKey.zoneName : '';
  }

  private getBurnerTypeNameByKey(item: IBurnersOrder): string {
    const burnerByKey: IBurnerDefinition = this.burnersWithDetails.find((burner) => {
      return burner.burnerKey === item.burnerKey;
    });
    return burnerByKey && burnerByKey.burnerTypeName ? burnerByKey.burnerTypeName : '';
  }
}
