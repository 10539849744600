








import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class FormField extends Vue {
  @Prop({required: false, default: false})
  private isNumber: boolean;
  @Prop({required: false, default: ''})
  private placeholder: string;
  @Prop({required: false, default: ''})
  private header: string;
  @Model('change')
  private value: string | number;

  private localValue: string | number = null;

  @Watch('localValue', { immediate: true })
  private onLocalValueChange(): void {
    this.$emit('change', this.localValue);
  }

  @Watch('value', { immediate: true })
  private onValueChange(): void {
    this.localValue = this.value;
  }
}
