





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SelectDropdown extends Vue {
  @Prop({ required: true })
  private dropdownId: string;
  @Prop({ required: true , default:''})
  public value: string;
  @Prop({ required: false, default: false})
  public disabled: boolean;

  private showDropdown = false;

  private toggleMenu(): void {
    this.showDropdown = !this.showDropdown;
  }

  private async closeMenu(): Promise<void> {
    this.showDropdown = false;
  }

  public mounted(): void {
    window.addEventListener('click', (e) => {
      const t = e.target as HTMLElement;
      if (!document?.querySelector('.custom-select')?.contains(t)) {
        if (this.showDropdown) {
          this.toggleMenu();
        }
      }
    });
  }
}
