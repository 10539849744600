










import { Component, Vue, Prop } from 'vue-property-decorator';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import { TreeActionTypeEnums } from '@/enums/treeActions';

@Component
export default class ElementListItem extends Vue {
  @Prop({ required: true })
  private burner: object;

  private startDrag(event: DragEvent, burner: { name: string, burnerTypeKey: string }): void {
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.effectAllowed = 'copy';
    event.dataTransfer.setData('text/plain', JSON.stringify(burner));
    hbEventBus.$emit(TreeActionTypeEnums.StartDragBurner);
  }

  private endDrag(): void {
    hbEventBus.$emit(TreeActionTypeEnums.EndDragBurner);
  }
}
