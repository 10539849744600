






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {
  @Prop({ required: true })
  private dropdownId: string;
  @Prop({ required: false })
  private relative: boolean;
  @Prop({ required: false })
  private iconDirection: string;
  @Prop({ required: false })
  private nofloatIcon: boolean;
  @Prop({ required: false })
  private width: string;
  @Prop({ required: false })
  private leftAdjustment: number;
  @Prop({ required: false })
  private topAdjustment: number;
  private showDropdown: boolean = false;

  private toggleMenu(): void {
    this.showDropdown = !this.showDropdown;
  }

  private async closeMenu(ms: number): Promise<void> {
    await this.delay(ms);
    this.showDropdown = false;
  }

  private delay(ms: number): Promise<NodeJS.Timeout> {
    return new Promise( (resolve) => setTimeout(resolve, ms) );
  }
}
