import Parse from 'csv-parse';
import { HierarchyFile } from '@/components/hierarchy/modals/hierarchy-file';
import { NativeFileRecord, COLUMNS } from '@/components/hierarchy/modals/native-file-record';

export class NativeFile extends HierarchyFile {
  public static isValidHeader(fileContents: string): boolean {
    return fileContents.startsWith(Object.values(COLUMNS).join(','));
  }

  private isRootFound: boolean = false;

  constructor(fileContents: string) {
    super(fileContents);
  }

  protected getColumns(): string[] {
    return Object.values(COLUMNS);
  }

  protected getDelimiter(): string {
    return ',';
  }

  protected onRecord(record: any, context: Parse.CastingContext): NativeFileRecord {
    const newRecord = new NativeFileRecord(record, this.isRootFound, context.lines - 1);
    if (newRecord.isRootZone()) {
      this.isRootFound = true;
    }

    return newRecord;
  }

  protected preProcessRecords(): void {
    /* no-op */
  }

  protected sanitizeFileContents(): void {
    /* no-op */
  }
}
