import { IAssignmentItem } from '@/view-models/customer-view-models';
import { IAsset } from '@/view-models/assets-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';

export interface IAssetStoreState {
  selectedSite: IAssignmentItem;
  selectedAsset: IAsset;
}

export interface IAssetStoreGetters extends GetterTree<IAssetStoreState, IRootState> {
  selectedSite(state: IAssetStoreState): IAssignmentItem;
  selectedAsset(state: IAssetStoreState): IAsset;
}

export interface IAssetStoreMutations extends MutationTree<IAssetStoreState> {
  initialize(state: IAssetStoreState): void;
  selectSite(state: IAssetStoreState, selected: IAssignmentItem): void;
  selectAsset(state: IAssetStoreState, selected: IAsset): void;
}

export interface IAssetStoreActions extends ActionTree<IAssetStoreState, IRootState> {}

export type IAssetContext = ActionContext<IAssetStoreState, IRootState>;

export const AssetStore = {
  namespaced: true as true,
  state: {
    selectedSite: null,
    selectedAsset: null,
  } as IAssetStoreState,
  getters:  {
    selectedSite(state: IAssetStoreState): IAssignmentItem {
      return state.selectedSite;
    },
    selectedAsset(state: IAssetStoreState): IAsset {
      return state.selectedAsset;
    },
  } as IAssetStoreGetters,
  mutations: {
    initialize(state: IAssetStoreState): void {
      state.selectedAsset = null;
      state.selectedSite = null;
    },
    selectSite(state: IAssetStoreState, selected: IAssignmentItem): void {
      state.selectedSite = selected;
    },
    selectAsset(state: IAssetStoreState, selected: IAsset): void {
      state.selectedAsset = selected;
    }
  } as IAssetStoreMutations,
  actions: {} as IAssetStoreActions
};
