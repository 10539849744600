import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState} from '..';

export interface IAppStoreState {
  isLoading: boolean;
}

export interface AppStoreGetters extends GetterTree<IAppStoreState, IRootState> {
  isLoading(state: IAppStoreState): boolean;
}

export interface AppStoreMutations extends MutationTree<IAppStoreState> {
  updateIsLoading(state: IAppStoreState, newIsLoading: boolean): void;
}

export interface AppStoreActions extends ActionTree<IAppStoreState, IRootState> {}

export type AppContext = ActionContext<IAppStoreState, IRootState>;

export const AppStore = {
  namespaced: true as true,
  state: {
    isLoading: true,
  } as IAppStoreState,
  getters:  {
    isLoading(state: IAppStoreState): boolean {
      return state.isLoading;
    }
  } as AppStoreGetters,
  mutations: {
    updateIsLoading(state: IAppStoreState, newIsLoading: boolean): void {
      state.isLoading = newIsLoading;
    }
  } as AppStoreMutations,
  actions: {} as AppStoreActions
};
