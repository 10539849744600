







































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import HelperMethods from '@/shared/helper-methods';
import { ATTRIBUTE_DATA_TYPES } from '@/enums/hierarchy-builder-types';

@Component({
  name: 'asset-property',
  components: {
  }
})
export default class AssetProperty extends Vue {
  @Prop({ required: true })
  public id: string;
  @Prop({ required: true })
  public isReadonly: boolean;
  @Prop({required: true})
  public showPrevious: boolean;
  @Prop({required: true})
  public isSaved: boolean;
  @Prop({required: true})
  public type: ATTRIBUTE_DATA_TYPES;
  @Model('change')
  public value: any;

  // tslint:disable-next-line:variable-name
  public Attribute_Data_Type = ATTRIBUTE_DATA_TYPES;
  public isDirty: boolean = false;

  public originalValue: any = null;
  public localValue: any = null;
  public localPreviousValue: boolean = false;

  public mounted() {
    this.originalValue = this.value;
    this.localPreviousValue = this.showPrevious;
  }

  public onPropertyValueChange(value: any): void {
    this.localValue = value;
    this.isDirty = this.originalValue === value ? false : true;
  }

  @Watch('isSaved', { immediate: true, deep: true })
  public saveComplete() {
    if (this.isSaved) {
      this.reset();
    }
  }

  @Watch('value', { immediate: true, deep: true })
  private onValueChange(value: any): void {
    this.localValue = HelperMethods.isNullOrUndefined(value) ? null : value;
  }

  @Watch('localValue', { immediate: true, deep: true })
  private onLocalValueChange(localValue): void {
    if (typeof localValue === 'string' && HelperMethods.isStringEmpty(localValue)) {
      localValue = null;
    }
    this.$emit('change', localValue);
  }

  public reset() {
    this.isDirty = false;
    this.originalValue = this.value;
  }

}
