import store from '../store';
import { IZone } from '@/view-models/hierarchy-view-models';

export function findZoneIndex(key: string): number {
  return store.state.hierarchyState.hierarchy.zones.findIndex((z) =>
    z.zoneKey === key);
}

export function findZone(key: string): IZone {
  return store.state.hierarchyState.hierarchy.zones.find((z) => z.zoneKey === key);
}

export function findBurnerIndex(key: string, zoneIndex: number): number {
  return store.state.hierarchyState.hierarchy.zones[zoneIndex]
    .burnerDefinitions.findIndex((z) => z.burnerKey === key);
}

export function gatherChildrenKeys(parent: string, source: any[]): string[] {
  const keys: string[] = [];
  keys.push(parent);
  let hierarchyChildrenExist: boolean = true;
  do {
    source.forEach((i) => {
      if (keys.some((key) => key === (i.parentKey ? i.parentKey : i.zoneParentKey))) {
        keys.push((i.key ? i.key : i.zoneKey));
      } else {
        hierarchyChildrenExist = false;
      }
    });
  } while (hierarchyChildrenExist);
  return keys;
}

export function getZoneNamesFromRootToChild(source: string): string[] {
  const zone = findZone(source);
  if (!zone) {
    return [];
  } else if (zone.zoneParentKey) {
    const parentZoneNames: string[] = [zone.zoneName];
    let currentParentZone = findZone(zone.zoneParentKey);
    while (currentParentZone.zoneParentKey) {
      parentZoneNames.push(currentParentZone.zoneName);
      currentParentZone = findZone(currentParentZone.zoneParentKey);
    }
    parentZoneNames.push(currentParentZone.zoneName);
    return parentZoneNames;
  } else {
    return [zone.zoneName];
  }
}

export enum HIERARCHY_STATES {
  // Must match the API (HierarchyStatus)
  NONE = 0,
  DRAFT,
  VALIDATION_PENDING,
  VALIDATION_ERROR,
  VALIDATION_COMPLETE,
  PUBLISHED,
  REMOVED,
  TESTING,

  // Transitional states
  PUBLISH = 255
}

export function getHierarchyStateString(state: HIERARCHY_STATES): string {
  const statusStrings = ['None', 'Draft', 'Validation Pending', 'Validation Error', 'Validation Complete', 'Published', 'Removed', 'Testing'];
  return statusStrings[state];
}
