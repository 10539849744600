




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Subzone from './Subzone.vue';
import SubzoneItem from './SubzoneItem.vue';
import Dropdown from '../common/Dropdown.vue';
import FormField from '../common/FormField.vue';
import OnpointModal from '../common/OnpointModal.vue';
import store from '../../store';
import { ITreeItem, ITreeLiaison, IZone, IUpdateZoneDetails } from '@/view-models/hierarchy-view-models';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import { cloneDeep } from 'lodash';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import { showAndCommitError, showError } from '@/utils/Helpers';

@Component({
  components: { Dropdown, FormField, OnpointModal, Subzone, SubzoneItem }
})
export default class RootZoneGridView extends Vue {
  @Prop({ required: true })
  private item: ITreeItem;
  private store = store;
  private showDetailsModal: boolean = false;
  private showClearModal: boolean = false;
  private opportunityPriority: number = this.getRefreshedOpportunityPriority();
  private opportunityScoreType: string = this.getRefreshedOpportunityScoreType();
  private lowerTolerance: number = this.getRefreshedLowerTolerance();
  private upperTolerance: number = this.getRefreshedUpperTolerance();
  private notes: string = this.getRefreshedNotes();

  private mounted(): void {
    hbEventBus.$on(TreeActionTypeEnums.UpdateZoneDetails, (item: ITreeItem) => {
      if (item && item.key === this.item.key) {
        this.opportunityPriority = this.getRefreshedOpportunityPriority();
        this.opportunityScoreType = this.getRefreshedOpportunityScoreType();
        this.lowerTolerance = this.getRefreshedLowerTolerance();
        this.upperTolerance = this.getRefreshedUpperTolerance();
        this.notes = this.getRefreshedNotes();
      }
    });
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.UpdateZoneDetails);
  }

  private getRefreshedOpportunityPriority(): number {
    return this.fullZoneData && this.fullZoneData.opportunityPriority ? this.fullZoneData.opportunityPriority : 1;
  }

  private getRefreshedOpportunityScoreType(): string {
    return this.fullZoneData && this.fullZoneData.opportunityScoreType ? this.fullZoneData.opportunityScoreType : null;
  }

  private getRefreshedLowerTolerance(): number {
    return this.fullZoneData && this.fullZoneData.lowerTolerance ? this.fullZoneData.lowerTolerance : null;
  }

  private getRefreshedUpperTolerance(): number {
    return this.fullZoneData && this.fullZoneData.upperTolerance ? this.fullZoneData.upperTolerance : null;
  }

  private getRefreshedNotes(): string {
    return this.fullZoneData && this.fullZoneData.notes ? this.fullZoneData.notes : '';
  }

  private addSubzone(item: ITreeItem): void {
    if (!this.isOpen) {
      this.toggleIsOpen(this.item);
    }
    const emitOb: ITreeLiaison = {
      source: 'newSubZone',
      target: item
    };
    store.commit('hierarchyState/updateHierarchySubzones', emitOb);
  }

  private editZoneDetails(): void {
    this.showDetailsModal = true;
  }

  private async submitDetails(): Promise<void> {
    if (!this.opportunityPriority) {
      showError('Missing value for required field.');
      return;
    }
    const zoneDetails: IUpdateZoneDetails = {
      opportunityPriority: this.opportunityPriority,
      opportunityScoreType: this.opportunityScoreType,
      lowerTolerance: this.lowerTolerance,
      upperTolerance: this.upperTolerance,
      notes: this.notes,
      zoneKey: this.item.associatedZoneKey,
      treeItemKey: this.item.key
    };

    const prevOppScore = this.fullZoneData.opportunityPriority || 1;
    const prevOppScoreType = this.fullZoneData.opportunityScoreType;
    const prevLowerTolerance = this.fullZoneData.lowerTolerance;
    const prevUpperTolerance = this.fullZoneData.upperTolerance;
    const prevNotes = this.fullZoneData.notes || '';

    store.commit('hierarchyState/updateSubzone', zoneDetails);
    this.hideDetailsModal();

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      hbEventBus.$emit(TreeActionTypeEnums.UpdateZoneDetails, this.item);
    } catch (error) {
      showAndCommitError(error);
      const prevDetails: IUpdateZoneDetails = {
        opportunityPriority: prevOppScore,
        opportunityScoreType: prevOppScoreType,
        lowerTolerance: prevLowerTolerance,
        upperTolerance: prevUpperTolerance,
        notes: prevNotes,
        zoneKey: this.item.associatedZoneKey,
        treeItemKey: this.item.key
      };
      store.commit('hierarchyState/updateSubzone', prevDetails);

      this.opportunityPriority = prevOppScore;
      this.opportunityScoreType = prevOppScoreType;
      this.lowerTolerance = prevLowerTolerance;
      this.upperTolerance = prevUpperTolerance;
      this.notes = prevNotes;
    }
  }

  private hideDetailsModal(): void {
    this.showDetailsModal = false;
  }

  private openClearModal(): void {
    this.showClearModal = true;
  }

  private hideClearModal(): void {
    this.showClearModal = false;
  }

  private async clearZone(): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    store.commit('hierarchyState/clearHierarchy');
    this.hideClearModal();

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private dropItem(target: ITreeItem, event: DragEvent): void {
    const element: HTMLElement = event.target as HTMLElement;

    // if event target is in a subzone and not the root, return
    if (element.closest('.subzone')) { return; }

    if (!this.isOpen) {
      this.toggleIsOpen(this.item);
    }

    // get data being transfered
    const source = JSON.parse(event.dataTransfer.getData('text/plain'));

    let sourceExistsInTarget = false;

    for (const burner of this.childBurners) {
      if (source.burnerTypeKey === burner.burnerTypeKey) {
        sourceExistsInTarget = true;
      }
    }

    // can't add a burner type if it already exists in subzone
    if (sourceExistsInTarget) { return; }

    if (!source.key) {
      const newElement: ITreeLiaison = {
        source,
        target
      };
      store.dispatch('hierarchyState/handleLeafToZoneDrag', newElement);
      return;
    }

    const actionType: TreeActionTypeEnums =
      source.type === 'Zone'
        ? TreeActionTypeEnums.ZoneToZone
        : TreeActionTypeEnums.LeafToZone;

    const data: ITreeLiaison = {
      source,
      target
    };

    if (actionType === TreeActionTypeEnums.LeafToZone) {
      store.dispatch('hierarchyState/handleLeafToZoneDrag', data);
    } else if (actionType === TreeActionTypeEnums.ZoneToZone) {
      store.commit('hierarchyState/handleZoneToZoneDrag', data);
    }
  }

  private toggleIsOpen(item: ITreeItem): void {
    store.commit('hierarchyState/toggleZone', item.key);
  }

  get isOpen(): boolean {
    return store.state.hierarchyState.openZones.includes(this.item.key);
  }

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/treeItems']();
  }

  private get childSubzones(): ITreeItem[] {
    return this.treeItems.filter((item: ITreeItem) => {
      return item.parentKey === this.item.key && item.type === 'Zone';
    });
  }

  private get childBurners(): ITreeItem[] {
    return this.treeItems.filter((item: ITreeItem) => {
      return item.parentKey === this.item.key && item.type === 'Leaf';
    });
  }

  private get zones(): IZone[] {
    return store.state.hierarchyState.hierarchy.zones;
  }

  private get fullZoneData(): IZone {
    return this.zones.find((zone) => {
      return zone.zoneKey === this.item.associatedZoneKey;
    });
  }
}
