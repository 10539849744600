


































































































































import { BootstrapVue, BDropdown } from 'bootstrap-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import store from '../../store';
import Loading from '../common/Loading.vue';
import Navbar from '../common/Navbar.vue';
import SelectDropdown from '@/components/common/SelectDropdown.vue';
import { IAssignmentItem } from '@/view-models/customer-view-models';
import { IAsset } from '@/view-models/assets-view-models';
import OnpointModal from '@/components/common/OnpointModal.vue';
import { changeParentRoute, showAndCommitError, showError } from '@/utils/Helpers';
import { HIERARCHY_STATES, getHierarchyStateString } from '@/utils/StoreHelpers';
import HelperMethods from '@/shared/helper-methods';

Vue.use(BootstrapVue);

@Component({
  components: { Loading, Navbar, OnpointModal, SelectDropdown }
})
export default class Header extends Vue {
  @Prop({ required: false, default: false})
  private disabled: boolean;

  private store = store;
  private editName: boolean = false;
  private hierarchyName: string = store.state.hierarchyState.hierarchy.hierarchyName || '';
  private utilitiesLink: string = '/utilities';
  private siteDropdownHelper: boolean = false;
  private assetDropdownHelper: boolean = false;
  private selectedSite: IAssignmentItem = null;
  private selectedAsset: IAsset = null;
  private HIERARCHY_STATES = HIERARCHY_STATES;
  private getHierarchyStateString = getHierarchyStateString;
  private publishModalIsOpen: boolean = false;
  private testModalIsOpen: boolean = false;
  private revertDraftModalIsOpen: boolean = false;

  private get assetKeyEmpty(): boolean {
    return HelperMethods.isStringEmpty(store.state.hierarchyState.hierarchy?.assetKey);
  }

  private async mounted(): Promise<void> {
    this.selectedSite = store.getters['asset/selectedSite'];
    this.selectedAsset = store.getters['asset/selectedAsset'];

    // Ensure all dropdown actions are captured
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'create-hierarchy-site-selector-dropdown' &&
          !this.siteDropdownHelper) {
        this.siteDropdownHelper = true;
      } else if (bvEvent.componentId === 'create-hierarchy-asset-selector-dropdown' &&
                 !this.assetDropdownHelper) {
        this.assetDropdownHelper = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'create-hierarchy-site-selector-dropdown' &&
          this.siteDropdownHelper) {
        bvEvent.preventDefault();
        this.siteDropdownHelper = false;
      } else if (bvEvent.componentId === 'create-hierarchy-asset-selector-dropdown' &&
                 this.assetDropdownHelper) {
        bvEvent.preventDefault();
        this.assetDropdownHelper = false;
      }
    });
  }

  private async submitAssetForm(): Promise<void> {
    const isValid = await this.$validator.validate();
    if (!isValid) {
      return;
    }
    // guard clause to make sure an asset is selected and name exists
    if (!this.selectedAssetKey || !this.selectedAssetData || !this.hierarchyName) {
      showError('Missing value for required field.');
      return;
    }

    // adds asset and site data to hierarchy state
    const newHierarchy = Object.assign(store.state.hierarchyState.hierarchy, {
      hierarchyName: this.hierarchyName,
      assetKey: this.selectedAssetKey,
      assetName: this.selectedAssetData.equipmentName,
      siteKey: this.selectedSite?.key,
      siteName: this.selectedSiteData.name,
      customerKey: store.state.hierarchyState.user?.activeCustomerKey,
      customerName: store.state.hierarchyState.availableAssignments
        .find((assignment) => assignment.entityType === 'Customer').name
    });

    this.$emit('create-hierarchy', newHierarchy);
  }

  private showEditName(): void {
    this.editName = true;
    this.$nextTick().then(() => {
      const input: HTMLElement = this.$refs.name as HTMLElement;
      input.focus();
    });
  }

  private async updateName(newName: string): Promise<void> {
    // in case assetKeyEmpty is true and user is trying to create the hierarchy, return
    if (this.assetKeyEmpty) { return; }

    const prevName = store.state.hierarchyState.hierarchy.hierarchyName;

    // if no change was made to name field, set editName to false and return to make no changes
    if (newName === prevName) {
      this.editName = false;
      return;
    }

    // if name field is blank, throw error
    if (!newName) {
      this.editName = false;
      this.hierarchyName = prevName;
      showError('Missing value for name. No changes were made.');
      return;
    }

    const newHierarchy = Object.assign(store.state.hierarchyState.hierarchy, {
      hierarchyName: newName,
    });

    this.editName = false;
    store.commit('hierarchyState/updateHierarchy', newHierarchy);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      const revertHierarchy = Object.assign(store.state.hierarchyState.hierarchy, {
          hierarchyName: prevName,
        });
      store.commit('hierarchyState/updateHierarchy', revertHierarchy);
    }
  }

  private get sites(): IAssignmentItem[] {
    const assignments = this.store.state.hierarchyState.availableAssignments.filter(
      (assignment: IAssignmentItem) => assignment.entityType === 'CustomerSite'
    );
    if (assignments) {
      return assignments.sort((assignmentX, assignmentY) => assignmentX.name > assignmentY.name ? 1 : -1);
    } else {
      return [];
    }
  }

  private get assets(): IAsset[] {
    const filteredAssets = store.state.hierarchyState.assets.filter((asset: IAsset) => {
      return asset.siteKey === this.selectedSite?.key;
    });
    return filteredAssets.sort((assetX, assetY) => assetX.equipmentName > assetY.equipmentName ? 1 : -1);
  }

  private get selectedSiteData(): IAssignmentItem {
    return this.sites.find((site) => {
      return site.key === this.selectedSite?.key;
    });
  }

  private get selectedAssetData(): IAsset {
    return this.assets.find((asset: IAsset) => {
      return asset.key === this.selectedAssetKey;
    });
  }

  private get selectedAssetKey(): string {
    return this.selectedAsset?.key;
  }

  private get status(): HIERARCHY_STATES {
    return store.state.hierarchyState.hierarchy.status;
  }

  private redirectToUtilities(): void {
    changeParentRoute(this.utilitiesLink);
  }

  private toggleSiteDropdown(): void {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.createHierarchySiteSelectorDropdown as BDropdown;
    const isDropdownOpen = dropdown.visible;
    if (!isDropdownOpen) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
    this.siteDropdownHelper = false;
  }

  private toggleAssetDropdown(): void {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.createHierarchyAssetSelectorDropdown as BDropdown;
    const isDropdownOpen = dropdown.visible;
    if (!isDropdownOpen) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
    this.assetDropdownHelper = false;
  }

  private async selectModelSite(val: IAssignmentItem): Promise<void> {
    // Set site
    this.selectedSite = val;
    this.selectedAsset = null;
  }

  private selectModelAsset(val: IAsset): void {
    // Set asset
    this.selectedAsset = val;
  }

  private openPublishModal(): void {
    this.publishModalIsOpen = true;
  }

  private closePublishModal(): void {
    this.publishModalIsOpen = false;
  }

  private openTestModal(): void {
    this.testModalIsOpen = true;
  }

  private closeTestModal(): void {
    this.testModalIsOpen = false;
  }

  private openRevertDraftModal(): void {
    this.revertDraftModalIsOpen = true;
  }

  private closeRevertDraftModal(): void {
    this.revertDraftModalIsOpen = false;
  }

  private async updateHierarchyStatus(newStatus: HIERARCHY_STATES): Promise<void> {
    const hierarchy = cloneDeep(store.state.hierarchyState.hierarchy);
    hierarchy.status = newStatus;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', hierarchy);
    } catch (error) {
      showAndCommitError(error);
    }
  }

  private async submitTestModal(): Promise<void> {
    this.closeTestModal();
    await this.updateHierarchyStatus(HIERARCHY_STATES.TESTING);
  }

  private async submitPublishModal(): Promise<void> {
    this.closePublishModal();
    await this.updateHierarchyStatus(HIERARCHY_STATES.PUBLISH);
  }

  private async submitRevertDraftModal(): Promise<void> {
    this.closeRevertDraftModal();
    await this.updateHierarchyStatus(HIERARCHY_STATES.DRAFT);
  }

  @Watch('store.state.hierarchyState.hierarchy', { immediate: true, deep: true })
  private onHierarchyChange(): void {
    this.hierarchyName = store.state.hierarchyState.hierarchy.hierarchyName || '';
    this.selectedSite = store.getters['asset/selectedSite'];
    this.selectedAsset = store.getters['asset/selectedAsset'];
  }
}
