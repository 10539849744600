export enum OBJECT_TYPES {
  ZONE = 'ZONE',
  ATTRIBUTE = 'ATTRIBUTE',
  BURNER = 'BURNER',
}

export enum ATTRIBUTE_TYPES {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
  FORMULA = 'FORMULA'
}

export enum ATTRIBUTE_DIRECTIONS {
  INPUT = 'INPUT',
  RESULT = 'RESULT'
}

export enum ATTRIBUTE_DATA_TYPES {
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
}
