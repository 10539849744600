export enum TreeActionTypeEnums {
  LeafToZone = 'LeafToZone',
  ZoneToZone = 'ZoneToZone',
  DeleteLeaf = 'DeleteLeaf',
  RenameLeaf = 'RenameLeaf',
  BurnerCountUpdated = 'BurnerCountUpdated',
  ItemOpen = 'ItemOpen',
  LeafSelected = 'LeafSelected',
  LeafSelectedUndo = 'LeafSelectedUndo',
  ZoneSelected = 'ZoneSelected',
  LeafUndo = 'LeafUndo',
  RenameZone = 'RenameZone',
  UpdateZoneDetails = 'UpdateZoneDetails',
  UpdateBurnerInputs = 'UpdateBurnerInputs',
  StartDragBurner = 'StartDragBurner',
  EndDragBurner = 'EndDragBurner',
  ConfirmHierarchyClone = 'confirmHierarchyClone'
}
