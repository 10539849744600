import Parse from 'csv-parse';
import { HierarchyFile, ROOT_ZONE_KEY } from '@/components/hierarchy/modals/hierarchy-file';
import { PiAfFileRecord, PI_AF_NAME, COLUMNS } from '@/components/hierarchy/modals/pi-af-file-record';
import { IZone, IAttribute, IBurnerDefinition } from '@/view-models/hierarchy-view-models';
import HelperMethods from '@/shared/helper-methods';

const SELECTED_MARKER = 'x';

export class PiAfFile extends HierarchyFile {
  public static isValidHeader(fileContents: string): boolean {
    const columns = fileContents.split(/\r?\n/)[0].split(',');
    return Object.values(COLUMNS).every((column) => columns.includes(column));
  }

  private columns: string[] = [];
  private rootZonePath: string = '';
  private pathIdMap: Map<string, string> = new Map();
  private burners: string[] = [];

  constructor(fileContents: string) {
    super(fileContents);

    this.columns = fileContents.split(/\r?\n/)[0].split(',');
    this.buildPathMap(this.hierarchy.zones, ROOT_ZONE_KEY);
  }

  protected getColumns(): string[] {
    return this.columns;
  }

  protected getDelimiter(): string {
    return ',';
  }

  protected onRecord(record: any, context: Parse.CastingContext): PiAfFileRecord {
    if (record[COLUMNS.SELECTED] !== SELECTED_MARKER) {
      return null;
    }

    const newRecord = new PiAfFileRecord(record, this.rootZonePath, context.lines - 1);
    if (newRecord.isRootZone()) {
      this.rootZonePath = record[COLUMNS.PARENT] + '\\' + record[COLUMNS.NAME];
    }

    // Resolve ids for existing entities
    const id = this.pathIdMap.get(newRecord.path);
    if (id) {
      newRecord.id = id;
    }

    // If this is a burner identifier, retain the burner path (parent)
    if (newRecord.isAttribute() && newRecord.name === PI_AF_NAME.BURNER_IDENTIFIER) {
      this.burners.push(newRecord.parent);
    }

    return newRecord;
  }

  protected preProcessRecords(): void {
    // For each saved burner path, find the burner record and set the isObjectBurner flag
    this.burners.forEach((burnerPath) => {
      this.records.filter((record) => record.path === burnerPath).forEach((record) => {
        const burnerRecord = record as PiAfFileRecord;
        if (burnerRecord) {
          burnerRecord.isObjectBurner = true;
          burnerRecord.isObjectZone   = false;
        }
      });
    });
  }

  protected sanitizeFileContents(): void {
    // csv-parse does not handle escaped line breaks properly - remove them
    const charRegExp = /[\"\n]/g;
    let match: RegExpExecArray | null;
    let isEscaped = false;
    const lineBreaks: number[] = [];
    while ((match = charRegExp.exec(this.fileContents)) !== null) {
      if (match[0] === '"') {
        isEscaped = !isEscaped;
      } else if (match[0] === '\n' && isEscaped) {
        lineBreaks.push(match.index);
      }
    }

    // Remove the escaped line breaks
    // Start from the end of the string so as to not corrupt the indexes of line breaks
    lineBreaks.reverse().forEach((lineBreak) => {
      let index = lineBreak;
      if (this.fileContents[lineBreak - 1] === '\r') {
        index--;
      }
      this.fileContents = this.fileContents.slice(0, index) + this.fileContents.slice(lineBreak + 1);
    });
  }

  private buildPathMap(entity: any, parentPath: string): void {
    let id: string = '';
    let path: string = parentPath;

    if (HelperMethods.isNullOrUndefined(entity)) {
      return;
    }

    if (Array.isArray(entity)) {
      for (const value of entity) {
        this.buildPathMap(value, parentPath);
      }
      return;
    }

    if ('burnerKey' in entity) {
      const burner = entity as IBurnerDefinition;
      id = burner.burnerKey;
      path = parentPath + '/' + burner.burnerName;
    } else if ('zoneKey' in entity) {
      const zone = entity as IZone;
      // Ignore the root zone
      if (zone.zoneName !== this.hierarchy.assetName) {
        id = zone.zoneKey;
        path = parentPath + '/' + zone.zoneName;
      }
    } else if ('attributeKey' in entity) {
      const attribute = entity as IAttribute;
      id = attribute.attributeKey;
      path = parentPath + '/' + attribute.name;
    } else {
      return;
    }

    if (id !== '') {
      this.pathIdMap.set(path, id);
    }

    for (const key in entity) {
      const value = entity[key];
      if (typeof value === 'object' || Array.isArray(value)) {
        this.buildPathMap(value, path);
      }
    }
  }
}
