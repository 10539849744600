import Parse from 'csv-parse';
import { v4 as uuid, validate as uuidIsValid } from 'uuid';
import store from '@/store';
import { IParserError, ParserError } from '@/view-models/parser-error';
import { IParserResult, ParserResult } from '@/view-models/parser-result';
import { IZone, IAttribute, IBurnerDefinition, IHierarchyDefiniton, IFormulaAttribute } from '@/view-models/hierarchy-view-models';
import { IAsset } from '@/view-models/assets-view-models';
import { IBurnerViewModel } from '@/view-models/burner-view-model';
import * as ImportValidator from '@/components/hierarchy/modals/import-validator';
import { ATTRIBUTE_TYPES, ATTRIBUTE_DIRECTIONS } from '@/enums/hierarchy-builder-types';
import { HierarchyFileRecord } from '@/components/hierarchy/modals/hierarchy-file-record';

export const ROOT_ZONE_KEY = 'root';

export abstract class HierarchyFile {
  public static buildIdMap(
    obj: any,
    zoneIdMap: Map<string, number>,
    attributeIdMap: Map<string, number>,
    burnerIdMap: Map<string, number>
  ): void {
    for (const key in obj) {
      const value = obj[key];

      // Check for key ID match
      if (key === 'zoneKey') {
        zoneIdMap.set(value, 1);
      } else if (key === 'attributeKey') {
        attributeIdMap.set(value, 2);
      } else if (key === 'burnerKey') {
        burnerIdMap.set(value, 3);
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        this.buildIdMap(value, zoneIdMap, attributeIdMap, burnerIdMap);
      }

      // If object is array, recursively search array
      if (Array.isArray(value)) {
        for (const subValue of value) {
          this.buildIdMap(subValue, zoneIdMap, attributeIdMap, burnerIdMap);
        }
      }
    }
  }

  protected hierarchy: IHierarchyDefiniton = store.state.hierarchyState.hierarchy;
  protected fileContents: string;
  protected records: HierarchyFileRecord[] = [];
  private errors: IParserError[] = [];
  private attributeHashTable: any = {};
  private attributeTagNames: string[] = [];
  private burnerHashTable: any = {};
  private zones: IZone[] = [];
  private zonePaths: string[] = [];
  private keyToNameTable: any = {};
  private parentKeys: any[] = [];
  private zoneNames: any = {};

  constructor(fileContents: string) {
    this.fileContents = fileContents;
  }

  public parse(): IParserResult {
    const parser = Parse({
      columns: this.getColumns(),
      delimiter: ',',
      from_line: 2, // Skip the header
      rtrim: true,
      on_record: (record: any, context: Parse.CastingContext) => this.onRecord(record, context)
    });

    parser.on('readable', () => {
      let record: any;
      while ((record = parser.read())) {
        this.records.push(record);
      }
    });

    try {
      this.sanitizeFileContents();
      parser.write(this.fileContents);
      parser.end();
    } catch (error) {
      this.errors.push(new ParserError(0, error.toString()));
    }

    this.preProcessRecords();
    this.processRecords();
    this.processZones();
    this.processAttributes();
    this.mapAttributesAndBurners();
    this.setParentZoneKeys();
    ImportValidator.checkBurnersForMissingBurnerKeys(this.burnerHashTable, this.parentKeys, this.errors);
    ImportValidator.checkAttributesForMissingParentKeys(this.attributeHashTable, this.parentKeys, this.errors);
    ImportValidator.validateAllFormulae(this.attributeHashTable, this.errors);

    this.errors.sort((error) => error.line);
    return new ParserResult(this.zones, this.errors);
  }

  protected abstract getDelimiter(): string;
  protected abstract getColumns(): string[];
  protected abstract onRecord(record: any, context: Parse.CastingContext): any;
  protected abstract preProcessRecords(): void;
  protected abstract sanitizeFileContents(): void;

  private getBurnerTypeName(burnerTypeKey: string): string {
    const assets = store.state.hierarchyState.assets;
    if (assets) {
      const asset = assets.find((a: IAsset) => a.key === this.hierarchy.assetKey);
      if (asset && asset.burnerList) {
        const burner = asset.burnerList.find((b: IBurnerViewModel) => b.burnerKey === burnerTypeKey);
        if (burner) {
          return burner.burnerName;
        }
      }
    }
  }

  private createZone(record: HierarchyFileRecord, isRootZone: boolean): void {
    const recordId = record.id !== '' ? record.id : uuid();
    const newZone: IZone = {
      index: record.lineNumber,
      zoneName: isRootZone ? this.hierarchy.assetName : record.name,
      zoneKey: isRootZone ? this.hierarchy.zones[0]?.zoneKey : recordId,
      zoneParentKey: isRootZone ? '' : record.parent,
      upperTolerance: record.upperTolerance,
      lowerTolerance: record.lowerTolerance,
      opportunityPriority: record.opportunityPriority,
      notes: record.notes
    };
    if (isRootZone) {
      newZone.opportunityScoreType = record.opportunityScoreType;
    }

    // Add name to to key translate
    this.keyToNameTable[record.path] = newZone?.zoneKey;

    // Add zone to result
    if (isRootZone) {
      // Root zone must be first
      this.zones.splice(0, 0, newZone);
    } else {
      this.zones.push(newZone);
    }
    this.zonePaths.push(isRootZone ? ROOT_ZONE_KEY : record.path);
  }

  private createBurner(record: HierarchyFileRecord): void {
    const newBurner: IBurnerDefinition = {
      index: record.lineNumber,
      burnerKey: record.id !== '' ? record.id : uuid(),
      burnerTypeName: this.getBurnerTypeName(record.burnerTypeKey),
      burnerTypeKey: record.burnerTypeKey,
      burnerName: record.name,
      upperTolerance: record.upperTolerance,
      lowerTolerance: record.lowerTolerance,
      opportunityPriority: record.opportunityPriority,
      notes: record.notes
    };

    // If burner type is unknown, then file error
    if (newBurner.burnerTypeName === undefined) {
      this.errors.push(
        new ParserError(
          record.lineNumber,
          `Invalid BurnerTypeID for this asset. Received ${newBurner.burnerTypeKey}.`
        )
      );
    }

    // Add to lookup for that parent key
    if (record.parent !== undefined && record.parent in this.burnerHashTable) {
      this.burnerHashTable[record.parent].push(newBurner);
    } else if (record.parent !== undefined) {
      this.burnerHashTable[record.parent] = [newBurner];
    }
  }

  private createAttribute(record: HierarchyFileRecord): void {
    const newAttribute: IAttribute = {
      index: record.lineNumber,
      attributeKey: record.id !== '' ? record.id : uuid(),
      name: record.name,
      direction: record.attributeDirection,
      dataType: record.attributeDataType,
      attributeType: record.attributeType,
      value: record.attributeValue !== '' ? record.attributeValue : undefined,
      tagName: record.attributeTagName !== '' ? record.attributeTagName : undefined,
      notes: record.notes
    };

    if (record.attributeType === ATTRIBUTE_TYPES.FORMULA) {
      (newAttribute as IFormulaAttribute).rawFormula   = record.formulaRaw;
      (newAttribute as IFormulaAttribute).operands     = record.formulaOperands;
      (newAttribute as IFormulaAttribute).supportedFns = record.formulaSupportedFns;
    }

    // Add to lookup for that parent key
    if (record.parent !== undefined && record.parent in this.attributeHashTable) {
      this.attributeHashTable[record.parent].push(newAttribute);
    } else if (record.parent !== undefined) {
      this.attributeHashTable[record.parent] = [newAttribute];
    }

    // Check DYNAMIC RESULT attributes for unique tag names
    if (
      newAttribute.attributeType === ATTRIBUTE_TYPES.DYNAMIC &&
      newAttribute.direction === ATTRIBUTE_DIRECTIONS.RESULT
    ) {
      if (ImportValidator.validateUniqueAttributeTagNames(this.attributeTagNames, newAttribute, this.errors)) {
        this.attributeTagNames.push(newAttribute.tagName);
      }
    }
  }

  private processRecords(): void {
    // Define variables used to check for existing elements
    const zoneIdMap: Map<string, number> = new Map();
    const attributeIdMap: Map<string, number> = new Map();
    const burnerIdMap: Map<string, number> = new Map();
    for (const zone of this.hierarchy.zones) {
      HierarchyFile.buildIdMap(zone, zoneIdMap, attributeIdMap, burnerIdMap);
    }

    // Define variable used to track if root zone is found
    let isRootZoneFound = false;
    const usedIds = [];
    this.records.forEach((record: HierarchyFileRecord) => {
      record.validateFields(this.errors);

      // Check id is in GUID form, if present
      if (record.id !== '') {
        if (!uuidIsValid(record.id)) {
          this.errors.push(
            new ParserError(
              record.lineNumber,
              `Invalid ID found. Provided ID must be in the form of a GUID. Received: ${record.id}.`
            )
          );
        } else if (usedIds.includes(record.id)) {
          this.errors.push(
            new ParserError(
              record.lineNumber,
              `Invalid ID found. Provided ID must be unique. Received: ${record.id}.`
            )
          );
        } else {
          usedIds.push(record.id);
        }
      }

      if (record.isRootZone()) {
        isRootZoneFound = true;

        // Check if root zone has id
        if (record.id !== '') {
          this.errors.push(new ParserError(record.lineNumber, `Root zone cannot have ID.  Received: ${record.id}.`));
        }

        // Check if root zone has parent
        if (record.parent !== '') {
          this.errors.push(
            new ParserError(record.lineNumber, `Root zone cannot have Parent. Received: ${record.parent}.`)
          );
        }

        this.createZone(record, true);
      } else if (record.isZone()) {
        this.createZone(record, false);
      } else if (record.isBurner()) {
        this.createBurner(record);
      } else if (record.isAttribute()) {
        this.createAttribute(record);
      }
    });

    // Check if root zone was found
    if (!isRootZoneFound) {
      this.errors.push(new ParserError(0, 'No root zone found.'));
    }
  }

  private processZones(): void {
    // Add root zone
    this.parentKeys.push(ROOT_ZONE_KEY);

    // Add parent keys
    this.zones.forEach((zone: IZone) => {
      // Get key identifier for this zone
      const zoneIdentifier = zone.zoneParentKey ? zone.zoneParentKey + '/' + zone.zoneName : ROOT_ZONE_KEY;
      if (zoneIdentifier !== ROOT_ZONE_KEY) {
        this.parentKeys.push(zoneIdentifier);
      }

      if (zone.zoneParentKey && this.zonePaths.indexOf(zone.zoneParentKey) < 0) {
        this.errors.push(
          new ParserError(
            zone.index || -1,
            `Invalid Parent for Zone. Received parent '${zone.zoneParentKey}' is not a zone.`
          )
        );
      }

      // Add burners and burner attributes for this zone
      if (this.burnerHashTable[zoneIdentifier]) {
        // Add burners for this zone
        zone.burnerDefinitions = this.burnerHashTable[zoneIdentifier];

        // Add parent keys for the burners
        zone.burnerDefinitions?.forEach((burnerDefinition) => {
          const subIdentifier = zoneIdentifier + '/' + burnerDefinition.burnerName;
          this.parentKeys.push(subIdentifier);
        });
      }
    });
  }

  private processAttributes(): void {
    const attributeKeys = Object.keys(this.attributeHashTable).sort();
    attributeKeys.forEach((attributeKey: string) => {
      this.attributeHashTable[attributeKey].forEach((levelAttribute: IAttribute) => {
        const attributeIdentifier = attributeKey + '/' + levelAttribute.name;
        if (this.attributeHashTable[attributeIdentifier]) {
          // Only push new parent key if parent key already exists
          if (this.parentKeys.indexOf(attributeKey) > -1) {
            this.parentKeys.push(attributeIdentifier);
          }
          levelAttribute.attributes = this.attributeHashTable[attributeIdentifier];

          ImportValidator.validateUniqueAttributeNames(
            this.attributeHashTable[attributeIdentifier],
            attributeIdentifier,
            this.errors
          );
        } else {
          levelAttribute.attributes = [];
        }
      });
    });
  }

  private validateZone(zone: IZone): void {
    // Check if zone parent key exists.
    if (zone.zoneParentKey && this.parentKeys.indexOf(zone.zoneParentKey) < 0) {
      this.errors.push(
        new ParserError(
          zone.index || -1,
          `Invalid Parent for Zone. Received parent '${zone.zoneParentKey}' does not exist.`
        )
      );
    }

    // Check if zone name is unique
    if (zone.zoneParentKey !== undefined && zone.zoneParentKey in this.zoneNames) {
      if (this.zoneNames[zone.zoneParentKey].indexOf(zone.zoneName) > -1) {
        if (zone.zoneParentKey !== undefined && zone.zoneParentKey !== null && zone.zoneParentKey !== '') {
          this.errors.push(
            new ParserError(
              zone.index || -1,
              `Invalid ObjectName. Received name '${zone.zoneName}' is not unique for parent ${zone.zoneParentKey}`
            )
          );
        }
      } else {
        this.zoneNames[zone.zoneParentKey].push(zone.zoneName);
      }
    } else if (zone.zoneParentKey !== undefined) {
      this.zoneNames[zone.zoneParentKey] = [zone.zoneName];
    }
  }

  private mapAttributesAndBurners(): void {
    const assetName = !!this.hierarchy.assetName ? this.hierarchy.assetName : '';

    // Link zones to their burners/attributes and check zone parents exist
    this.zones.forEach((zone: IZone) => {
      this.validateZone(zone);

      // Get key identifier for this zone
      let zoneIdentifier = (zone.zoneParentKey ? zone.zoneParentKey + '/' : '') + zone.zoneName;

      // If zone identifier is root zone, translate back to root zone key for matching purposes
      if (zoneIdentifier === assetName) {
        zoneIdentifier = ROOT_ZONE_KEY;
      }
      this.parentKeys.push(zoneIdentifier);

      // Map attributes for this zone
      if (this.attributeHashTable[zoneIdentifier]) {
        zone.attributes = this.attributeHashTable[zoneIdentifier];

        // Validate unique attribute names for this parent
        ImportValidator.validateUniqueAttributeNames(
          this.attributeHashTable[zoneIdentifier],
          zoneIdentifier,
          this.errors
        );
      } else {
        zone.attributes = [];
      }

      // Map burners and burner attributes for this zone
      if (this.burnerHashTable[zoneIdentifier]) {
        // Add burners for this zone
        zone.burnerDefinitions = this.burnerHashTable[zoneIdentifier];

        // Validate unique burner names for this parent
        ImportValidator.validateUniqueBurnerNames(this.burnerHashTable[zoneIdentifier], zoneIdentifier, this.errors);
        // Add burner attributes
        this.addBurnerAttributes(zone, zoneIdentifier, assetName);
      } else {
        zone.burnerDefinitions = [];
      }
    });
  }

  private addBurnerAttributes(zone: IZone, zoneIdentifier: string, assetName: string): void {
    zone.burnerDefinitions?.forEach((burnerDefinition) => {
      // Add burner zone key and elo name
      burnerDefinition.eloName =
        zoneIdentifier
          .replace('root', assetName)
          .replace(/\//g, '_')
          .replace(/ /g, '_') + '_' + burnerDefinition.burnerName?.replace(/ /g, '_');
      burnerDefinition.zoneKey = this.keyToNameTable[zoneIdentifier];

      // Add attributes
      const subIdentifier = zoneIdentifier + '/' + burnerDefinition.burnerName;
      this.parentKeys.push(subIdentifier);
      if (this.attributeHashTable[subIdentifier]) {
        burnerDefinition.attributes = this.attributeHashTable[subIdentifier];
        // Validate unique attribute names for this parent
        ImportValidator.validateUniqueAttributeNames(
          this.attributeHashTable[subIdentifier],
          subIdentifier,
          this.errors
        );
      } else {
        burnerDefinition.attributes = [];
      }
    });
  }

  private setParentZoneKeys(): void {
    this.zones.forEach((zone: IZone) => {
      // Translate parent zone to key
      if (zone.zoneParentKey !== undefined) {
        try {
          zone.zoneParentKey = this.keyToNameTable[zone.zoneParentKey];
        } catch (error) {
          this.errors.push(
            new ParserError(zone.index || -1, `Unknown parent key for zone. Received value ${zone.zoneParentKey}.`)
          );
        }
      }
    });
  }
}
