import store from '@/store';

export default class HelperMethods {
  /**
   * replace a regex string with another string
   * @param string string to perform the replacement on
   * @param replaceWith string to replace with (Default: ' — ' (space em-dash space))
   * @param replaceWhat RegEx to be replaced (Default: /\|/gi)
   * @returns {string}
   */
  public static replacePipe(text: string, replaceWith: string = ' — ', replaceWhat: RegExp = /\|/gi): string {
    return text.replace(replaceWhat, replaceWith);
  }

  /**
   * String shortening. Allows for ellipsis to be on ends of text or to be shown after a set number of characters.
   * @param text : string
   * @param maxLength : number
   * @param clip: number
   * @param reverse: boolean
   * @param separator: string
   */
  public static shortenText(
    text: string,
    maxLength: number = 15,
    clip: number = 0,
    reverse: boolean = false,
    separator: string = '...'
  ): string {
    if (text === '') {
      return text;
    }
    text = text.trim();
    if (text.length <= maxLength) {
      return text;
    }
    if (clip >= maxLength) {
      clip = 0;
    }

    const clippedText: string = reverse ? text.substring(0, clip) : text.substring(text.length - clip, text.length);
    const shortenedText: string = reverse
      ? text.substring(text.length - maxLength + (separator.length + clip), text.length)
      : text.substring(0, maxLength - (separator.length + clip));

    return reverse ? clippedText + separator + shortenedText : shortenedText + separator + clippedText;
  }

  /**
   * Get the number of days in a particular month.
   * @param date: Date
   * @returns numberOfDays: number
   */
  public static getDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  public static spaceCase(text: string): string {
    return text.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
  }

  public static equalsIgnoreCase(text1: string, text2: string): boolean {
    return text1.toLowerCase() === text2.toLowerCase();
  }

  public static convertDate(input: string): Date {
    return this.isStringEmpty(input) ? new Date(2000,1,1) : new Date(input);
  }

  public static isNullOrUndefined(value: any): boolean {
    return value === null || typeof value === 'undefined';
  }

  public static isStringEmpty(text: string): boolean {
    return this.isNullOrUndefined(text) || text.trim() === '';
  }

  public static isArrayEmpty(array: any[]): boolean {
    return this.isNullOrUndefined(array) || array.length === 0;
  }

  public static sortString(a: string, b: string, alphabetical: boolean = true): number {
    const above = alphabetical ? -1 : 1;
    const below = alphabetical ? 1 : -1;

    if (a == null) {
      return b == null ? 0 : above;
    }
    if (b == null) {
      return below;
    }

    const textA = a.toLowerCase();
    const textB = b.toLowerCase();

    if (textA < textB) {
      return above;
    }
    if (textA > textB) {
      return below;
    }
    return 0;
  }
}

export function valueOr(...variables: any[]): any {
  for (const v of variables) {
    if (v != null) {
      return v;
    }
  }
  return null;
}

export function toCamelCase(str: string): string {
  return convertToCase(str, (word, index) => {
    const head = index === 0 ? word.charAt(0).toLowerCase() : word.charAt(0).toUpperCase();
    return `${head}${word.slice(1).toLowerCase()}`;
  });
}

export function copyToClipBoard(text: string): void {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy');
  } catch (e) {
    store.dispatch('error/setError', {
      error: e,
      errorString: 'Failed to copy, please try again\n',
      handleError: true,
      routeHomeAfterError: false
    });
  } finally {
    document.body.removeChild(textarea);
    window.getSelection()?.removeAllRanges();
  }
}

function convertToCase(str: string, converter: ((word: string, index?: number) => string)): string {
  return str
    .replace(/(?:[_\-+]+)/g, ' ')
    .trim()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, converter)
    .replace(/\s+/g, '');
}
