import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { Vue } from 'vue-property-decorator';
import { IRootState } from '..';
import { HierarchyService } from '@/services/hierarchy-builder-service';
import { KESCustomerService } from '@/services/customer-service';
import { UserManagementService } from '@/services/user-management-service';
import { KESCustomerAssetService } from '@/services/customer-asset-service';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config-factory';
import { IAssignmentItem } from '@/view-models/customer-view-models';
import { IUser } from '@/view-models/user-view-models';
import { IAsset } from '@/view-models/assets-view-models';
import {
  IBurnerDefinition,
  ITreeLiaison,
  IHierarchyDefiniton,
  ITreeItem,
  IZone,
  IWalkingOrder,
  IBurnersOrder,
  IAttribute,
  IZoneDetails,
  IWalkingOrderTreeItem,
  IUpdateZoneDetails,
  IBurnerConfigsPlaceHolder,
} from '@/view-models/hierarchy-view-models';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { hbEventBus } from '@/eventBus/hierarchy-event-bus';
import {
  findBurnerIndex,
  findZone,
  findZoneIndex,
  gatherChildrenKeys,
  HIERARCHY_STATES,
} from '@/utils/StoreHelpers';
import { IBurnerViewModel } from '@/view-models/burner-view-model';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import DateUtil from '@/utils/dateUtil';
import HelperMethods from '@/shared/helper-methods';

export interface IHierarchyStoreState {
  service: HierarchyService;
  customerService: KESCustomerService;
  customerAssetService: KESCustomerAssetService;
  userManagementService: UserManagementService;
  user: IUser;
  availableAssignments: IAssignmentItem[];
  assets: IAsset[];
  hierarchy: IHierarchyDefiniton;
  prevHierarchy: IHierarchyDefiniton;
  selectedWalkingOrder?: IWalkingOrder;
  hierarchies: IHierarchyDefiniton[];
  selectedHierarchySaved: string;
  selectedTreeItem: ITreeItem ;
  copiedZoneDetails: IZoneDetails ;
  copiedAttribute: IAttribute ;
  copiedAttributes: IAttribute[] ;
  openZones: string[];
  burnerConfigsPlaceHolder: IBurnerConfigsPlaceHolder[];
  cloneModalIsOpen: boolean;
  piAfBurnerTypeKeys: Map<string, string>;
}

export interface IHierarchyStoreGetters extends GetterTree<IHierarchyStoreState, IRootState> {
  getBurners(state: IHierarchyStoreState): IBurnerViewModel[];
  getBurnersByZoneAndType(): (lookup: { subzone: string; typeKey: string }) => IBurnerDefinition[];
  getBurner(
    state: IHierarchyStoreState
  ): (item: { zoneKey: string; burnerKey: string }) => IBurnerDefinition[];
  getZone(
    state: IHierarchyStoreState
  ): (item: { zoneKey: string; burnerKey: string }) => IZone;
  newlyDefinedBurnersExist(
    state: IHierarchyStoreState
  ): (WO: IWalkingOrder) => boolean;
  allDefinedBurnersForZoneAreAssignedToWalkingOrder(
    state: IHierarchyStoreState
  ): (zoneKey: string) => boolean;
  countOfDefinedBurnersForZoneInWalkingOrder(
    state: IHierarchyStoreState
  ): (zoneKey: string) => number;
  treeItems(state: IHierarchyStoreState): () => any[];
  attributesTreeItems(state: IHierarchyStoreState): () => any[];
  walkingOrderTreeItems(state: IHierarchyStoreState): () => any[];
  burnerTypeAlreadyExistInZone(state: IHierarchyStoreState): (data: ITreeLiaison) => boolean;
}

export interface IHierarchyStoreMutations extends MutationTree<IHierarchyStoreState> {
  setPiAfBurnerTypeKeys( state: IHierarchyStoreState, burnerTypeIds: Map<string, string>): void;
  updateUser(state: IHierarchyStoreState, user: IUser): void;
  updateAssignments(
    state: IHierarchyStoreState,
    assignmentItems: IAssignmentItem[]
  ): void;
  updateService(
    state: IHierarchyStoreState,
    service: HierarchyService
  ): void;
  updateCustomerService(
    state: IHierarchyStoreState,
    service: KESCustomerService
  ): void;
  updateCustomerAssetService(
    state: IHierarchyStoreState,
    service: KESCustomerAssetService
  ): void;
  updateAccountService(
    state: IHierarchyStoreState,
    service: UserManagementService
  ): void;
  updateAssets(state: IHierarchyStoreState, assets: IAsset[]): void;
  updateHierarchies(
    state: IHierarchyStoreState,
    hierarchies: IHierarchyDefiniton[]
  ): void;
  handleZoneToZoneDrag(
    state: IHierarchyStoreState,
    data: ITreeLiaison
  ): void;
  updateHierarchy(
    state: IHierarchyStoreState,
    hierarchy: IHierarchyDefiniton
  ): void;
  updatePrevHierarchy(
    state: IHierarchyStoreState,
    hierarchy: IHierarchyDefiniton
  ): void;
  updateHierarchySubzones(
    state: IHierarchyStoreState,
    data: ITreeLiaison
  ): void;
  initRootZone(state: IHierarchyStoreState): void;
  renameSubzone(
    state: IHierarchyStoreState,
    item: { item: ITreeItem; value: string }
  ): void;
  addRootAttribute(
    state: IHierarchyStoreState,
    payload: { attribute: IAttribute; zoneKey: string; burnerKey?: string }
  ): void;
  copyAttribute(state: IHierarchyStoreState, attribute: IAttribute): void;
  updateSubzone(state: IHierarchyStoreState, item: IUpdateZoneDetails): void;
  newHierarchy(state: IHierarchyStoreState): void;
  clearHierarchy(state: IHierarchyStoreState): void;
  updateSelectedHierarchySaved(
    state: IHierarchyStoreState,
    saveString: string
  ): void;
  undoWalkingOrder(
    state: IHierarchyStoreState,
    item: IWalkingOrderTreeItem
  ): void;
  removeRootZone(state: IHierarchyStoreState): void;
  moveOrder(
    state: IHierarchyStoreState,
    payload: { walkOrder: IWalkingOrder; source: number; target: number }
  ): void;
  removeOrder(
    state: IHierarchyStoreState,
    payload: { walkOrder: IWalkingOrder; indexToDelete: number }
  ): void;
  sortWalkingOrder(state: IHierarchyStoreState): void;
  updateSelectedWalkingOrder(
    state: IHierarchyStoreState,
    walkOrder: IWalkingOrder
  ): void;
  addZoneBurnersToWalkingOrder(
    state: IHierarchyStoreState,
    payload: { walkOrder: IWalkingOrder; target: number; zoneKey: string }
  ): void;
  updateWalkingOrder(
    state: IHierarchyStoreState,
    walkingOrder: IWalkingOrder
  ): void;
  deleteWalkingOrder(
    state: IHierarchyStoreState,
    walkingOrder: IWalkingOrder
  ): void;
  cleanHierarchy(state: IHierarchyStoreState): void;
  selectTreeItem(state: IHierarchyStoreState, item: ITreeItem): void;
  clearSelectedTreeItem(state: IHierarchyStoreState): void;
  addNewlyDefinedBurners(
    state: IHierarchyStoreState,
    WO: IWalkingOrder
  ): void;
  copyZoneDetails(state: IHierarchyStoreState, item: ITreeItem): void;
  pasteZoneDetails(state: IHierarchyStoreState, item: ITreeItem): void;
  updateBurner(
    state: IHierarchyStoreState,
    payload: { burnerKey: string; zoneKey: string; burner: IBurnerDefinition }
  ): void;
  updateZone(
    state: IHierarchyStoreState,
    payload: { zoneKey: string; zone: IZone }
  ): void;
  copyAttributes(
    state: IHierarchyStoreState,
    copiedAttributes: IAttribute[]
  ): void;
  pasteAttributesToZones(state: IHierarchyStoreState, item: ITreeItem): void;
  pasteAttributesToBurners(state: IHierarchyStoreState, item: ITreeItem): void;
  toggleZone(state: IHierarchyStoreState, zoneKey: string): void;
  moveBurnersInWalkingOrders(
    state: IHierarchyStoreState,
    data: ITreeLiaison
  ): void;
  moveBurnersFromZoneToZone(state: IHierarchyStoreState, data: ITreeLiaison): void;
  addBurnerPlaceHolder(state: IHierarchyStoreState, data: ITreeLiaison): void;
  deletePlaceHolderByZone(state: IHierarchyStoreState, item: ITreeItem): void;
  deleteZoneFromHierarchy(state: IHierarchyStoreState, item: ITreeItem): void;
  deleteBurnersFromWalkingOrders(
    state: IHierarchyStoreState,
    item: ITreeItem
  ): void;
  deleteBurnerFromZone(
    state: IHierarchyStoreState,
    burner: IBurnerDefinition
  ): void;
  deleteBurnerFromWalkingOrders(
    state: IHierarchyStoreState,
    burner: IBurnerDefinition
  ): void;
  createBurners(
    state: IHierarchyStoreState,
    data: { count: number; item: ITreeItem }
  ): void;
  increaseBurners(
    state: IHierarchyStoreState,
    data: { count: number; item: ITreeItem }
  ): void;
  deletePlaceHolder(state: IHierarchyStoreState, placeHolderIndex: number): void;
  addToWalkingOrder(
    state: IHierarchyStoreState,
    item: IWalkingOrderTreeItem
  ): void;
  createAndAddToWalkingOrder(
    state: IHierarchyStoreState,
    item: IWalkingOrderTreeItem
  ): void;
  deleteBurnerTypeFromWalkingOrder(
    state: IHierarchyStoreState,
    burnerKeysForDeletion: string[]
  ): void;
  updateBurnersForZone(
    state: IHierarchyStoreState,
    payload: { newDefinitions: IBurnerDefinition[]; zoneIndex: number }
  ): void;
  updateZones(state: IHierarchyStoreState, zones: IZone[]): void;
  toggleCloneModal(state: IHierarchyStoreState): void;
}

export interface IHierarchyStoreActions extends ActionTree<IHierarchyStoreState, IRootState> {
  retrieveServiceConfig(context: IAssetContext): Promise<void>;
  retrieveAssets(context: IAssetContext): Promise<void>;
  retrieveHierarchies(context: IAssetContext): Promise<void>;
  retrieveZones(
    context: IAssetContext,
    hierarchy: IHierarchyDefiniton
  ): Promise<void>;
  deleteHierarchy(context: IAssetContext, key: string): Promise<void>;
  saveHierarchy(
    context: IAssetContext,
    hierarchy: IHierarchyDefiniton
  ): Promise<void>;
  handleLeafToZoneDrag(context: IAssetContext, data: ITreeLiaison): void;
  deleteSubzone(context: IAssetContext, item: ITreeItem): void;
  deleteBurner(context: IAssetContext, burner: IBurnerDefinition): void;
  generateBurners(
    context: IAssetContext,
    data: { count: number; item: ITreeItem }
  ): void;
  generateWalkingOrder(
    context: IAssetContext,
    item: IWalkingOrderTreeItem
  ): void;
  handleDeleteLeaf(context: IAssetContext, item: ITreeItem): void;
}

export type IAssetContext = ActionContext<IHierarchyStoreState, IRootState>;

export interface IHierarchyStore {
  mutations: IHierarchyStoreMutations;
  state: IHierarchyStoreState;
  getters: IHierarchyStoreGetters;
  actions: IHierarchyStoreActions;
  namespaced: boolean;
}

export const HierarchyStore: IHierarchyStore = {
  namespaced: true,
  state: {
    service: undefined,
    customerService: undefined,
    customerAssetService: undefined,
    userManagementService: undefined,
    user: undefined,
    selectedWalkingOrder: undefined,
    availableAssignments: [],
    assets: [],
    hierarchies: [],
    hierarchy: {
      key: uuid(),
      assetName: '',
      assetKey: '',
      customerName: '',
      customerKey: '',
      siteName: '',
      siteKey: '',
      dateCreated: '',
      updatedBy: '',
      dateUpdated: '',
      hierarchyName: '',
      zones: [],
      status: HIERARCHY_STATES?.NONE,
      walkingOrders: [],
    },
    prevHierarchy: {
      key: uuid(),
      assetName: '',
      assetKey: '',
      customerName: '',
      customerKey: '',
      siteName: '',
      siteKey: '',
      dateCreated: '',
      updatedBy: '',
      dateUpdated: '',
      hierarchyName: '',
      zones: [],
      status: HIERARCHY_STATES?.NONE,
      walkingOrders: [],
    },
    selectedTreeItem: null,
    selectedHierarchySaved: 'All changes saved',
    copiedAttribute: undefined,
    copiedAttributes: undefined,
    copiedZoneDetails: {
      opportunityPriority: 0,
      lowerTolerance: null,
      upperTolerance: null,
    },
    openZones: [],
    burnerConfigsPlaceHolder: [],
    cloneModalIsOpen: false,
    piAfBurnerTypeKeys: new Map<string, string>()
  } as IHierarchyStoreState,
  getters: {
    getBurners(state: IHierarchyStoreState): IBurnerViewModel[] {
      const assetData = state.assets.find((asset) => {
        return asset.key === state.hierarchy.assetKey;
      });
      if (assetData) {
        return assetData.burnerList;
      } else {
        return [];
      }
    },
    getBurnersByZoneAndType(): (lookup: { subzone: string; typeKey: string }) => IBurnerDefinition[] {
      return (lookup: {
        subzone: string;
        typeKey: string;
      }): IBurnerDefinition[] => {
        const zone = findZone(lookup.subzone);
        if (zone) {
          return zone.burnerDefinitions.filter(
            (def) => def.burnerTypeKey === lookup.typeKey
          );
        }
        return [];
      };
    },
    getBurner(
      state: IHierarchyStoreState
    ): (item: { zoneKey: string; burnerKey: string }) => IBurnerDefinition[] {
      return (item: {
        zoneKey: string;
        burnerKey: string;
      }): IBurnerDefinition[] => {
        const zone = findZoneIndex(item.zoneKey);
        return state.hierarchy.zones[zone].burnerDefinitions.filter(
          (def) => def.burnerKey === item.burnerKey
        );
      };
    },
    getZone(
      state: IHierarchyStoreState
    ): (item: { zoneKey: string; burnerKey: string }) => IZone {
      return (item: { zoneKey: string; burnerKey: string }): IZone => {
        const zone = findZoneIndex(item.zoneKey);
        return state.hierarchy.zones[zone];
      };
    },
    newlyDefinedBurnersExist(
      state: IHierarchyStoreState
    ): (WO: IWalkingOrder) => boolean {
      return (WO: IWalkingOrder): boolean => {
        // get all defined burners for all zones
        const allDefinedBurners: string[] = [];
        state.hierarchy.zones.forEach((zone) => {
          zone.burnerDefinitions.forEach((def) => {
            if (def.burnerName) {
              allDefinedBurners.push(def.burnerKey || '');
            }
          });
        });
        return allDefinedBurners.length !== WO.burnerOrder?.length;
      };
    },
    allDefinedBurnersForZoneAreAssignedToWalkingOrder(
      state: IHierarchyStoreState
    ): (zoneKey: string) => boolean {
      return (zoneKey: string) => {
        const walkingOrder = state.selectedWalkingOrder;
        const definedBurnerKeys: string[] = getDefinedBurnerKeys(zoneKey);
        let filteredBurnerOrder: IBurnersOrder[] = [];
        if (walkingOrder) {
          filteredBurnerOrder = walkingOrder.burnerOrder.filter(
            (bo) =>
              bo.zoneKey === zoneKey && definedBurnerKeys.includes(bo.burnerKey)
          );
        }
        return (
          filteredBurnerOrder.length > 0 &&
          filteredBurnerOrder.length === definedBurnerKeys.length
        );
      };
    },
    countOfDefinedBurnersForZoneInWalkingOrder(
      state: IHierarchyStoreState
    ): (zoneKey: string) => number {
      return (zoneKey: string) => {
        const walkingOrder = state.selectedWalkingOrder;
        const definedBurnerKeys: string[] = getDefinedBurnerKeys(zoneKey);
        const filteredBurnerOrder = walkingOrder.burnerOrder.filter(
          (bo) =>
            bo.zoneKey === zoneKey && definedBurnerKeys.includes(bo.burnerKey)
        );

        return filteredBurnerOrder.length;
      };
    },
    treeItems(state: IHierarchyStoreState): () => any[] {
      return () => {
        let items: ITreeItem[] = createTreeItems(state);
        const burnerTypes: Array<{
          type: string;
          zone: string;
          count: number;
        }> = countUniqueBurnerTypes(state.hierarchy.zones);
        items = updateBurnerCountOnBurnerTreeItem(items, burnerTypes);
        const placeholderBurners = createPlaceHolderTreeItems(
          state.burnerConfigsPlaceHolder
        );
        return [...items, ...placeholderBurners];
      };
    },
    attributesTreeItems(state: IHierarchyStoreState): () => any[] {
      return (): ITreeItem[] => {
        const zoneItems: ITreeItem[] = createZoneTreeItems(state);
        let burners: IBurnerDefinition[] = gatherBurnersForZones(
          state,
          zoneItems
        );

        // flatten array
        burners = burners.reduce((acc, val: any) => acc.concat(val), []);
        const burnerItems = createAttributeBurnerTreeItems(burners, zoneItems);
        return [...zoneItems, ...burnerItems];
      };
    },
    walkingOrderTreeItems(state: IHierarchyStoreState): () => any[] {
      return (): ITreeItem[] => {
        let treeItems: ITreeItem[] = createZoneTreeItems(state);
        treeItems = createWalkingOrderBurnerTreeItems(state, treeItems);
        return treeItems;
      };
    },
    burnerTypeAlreadyExistInZone(state: IHierarchyStoreState): (data: ITreeLiaison) => boolean {
      return (data: ITreeLiaison) => {
        const foundInPlaceHolders = state.burnerConfigsPlaceHolder.find(
          (ti) => {
            return (
              ti.burnerTypeKey === data.source.burnerTypeKey &&
              ti.zoneKey === data.target.associatedZoneKey
            );
          }
        );
        const zone = findZone(data.target.associatedZoneKey || '');
        const foundInZone = zone.burnerDefinitions.some(
          (def) => def.burnerTypeKey === data.source.burnerTypeKey
        );

        // only allow 1 burnertype config per zone
        if (foundInPlaceHolders || foundInZone) {
          return true;
        } else {
          return false;
        }
      };
    },
  } as IHierarchyStoreGetters,
  mutations: {
    setPiAfBurnerTypeKeys( state: IHierarchyStoreState, burnerTypeIds: Map<string, string>): void {
      state.piAfBurnerTypeKeys = burnerTypeIds;
    },
    updateUser(state: IHierarchyStoreState, user: IUser): void {
      state.user = user;
    },
    updateAssignments(
      state: IHierarchyStoreState,
      assignmentItems: IAssignmentItem[]
    ): void {
      state.availableAssignments = assignmentItems;
    },
    updateService(
      state: IHierarchyStoreState,
      service: HierarchyService
    ): void {
      state.service = service;
    },
    updateCustomerService(
      state: IHierarchyStoreState,
      service: KESCustomerService
    ): void {
      state.customerService = service;
    },
    updateCustomerAssetService(
      state: IHierarchyStoreState,
      service: KESCustomerAssetService
    ): void {
      state.customerAssetService = service;
    },
    updateAccountService(
      state: IHierarchyStoreState,
      service: UserManagementService
    ): void {
      state.userManagementService = service;
    },
    updateAssets(state: IHierarchyStoreState, assets: IAsset[]): void {
      state.assets = assets;
    },
    updateHierarchies(
      state: IHierarchyStoreState,
      hierarchies: IHierarchyDefiniton[]
    ): void {
      state.hierarchies = hierarchies;
    },
    handleZoneToZoneDrag(
      state: IHierarchyStoreState,
      data: ITreeLiaison
    ): void {
      if (data.source.isRoot) {
        return;
      }
      let targetIsDecendant: boolean = false;
      const targetZone: IZone = findZone(
        data.target?.associatedZoneKey ? data.target.associatedZoneKey : ''
      );
      const sourceZone: IZone = findZone(data.source.associatedZoneKey);

      const targetParentKeys: string[] = getParentKeys(targetZone, []);

      for (const key of targetParentKeys) {
        if (key === sourceZone.zoneKey) {
          targetIsDecendant = true;
          break;
        }
      }

      if (targetIsDecendant) {
        const parentItem = findZone(sourceZone.zoneParentKey);
        Vue.set(targetZone, 'zoneParentKey', parentItem.zoneKey);
      }

      Vue.set(sourceZone, 'zoneParentKey', targetZone.zoneKey);
    },
    updateHierarchy(
      state: IHierarchyStoreState,
      hierarchy: IHierarchyDefiniton
    ): void {
      state.hierarchy = hierarchy;

      if (hierarchy && state.hierarchies) {
        const index = state.hierarchies.findIndex(
          (h) => h.key === hierarchy.key
        );
        if (index >= 0) {
          state.hierarchies[index] = hierarchy;
        } else {
          state.hierarchies.push(hierarchy);
        }
      }
    },
    updatePrevHierarchy(
      state: IHierarchyStoreState,
      hierarchy: IHierarchyDefiniton
    ): void {
      state.prevHierarchy = hierarchy;
    },
    updateHierarchySubzones(
      state: IHierarchyStoreState,
      data: ITreeLiaison
    ): void {
      const subZone: IZone = {
        zoneName: '',
        zoneKey: uuid(),
        zoneParentKey: data.target.associatedZoneKey,
        opportunityPriority: 1,
        lowerTolerance: null,
        upperTolerance: null,
        burnerDefinitions: [],
        attributes: [],
      };
      state.hierarchy.zones.push(subZone);
    },
    initRootZone(state: IHierarchyStoreState): void {
      const id = uuid();
      const rootZone: IZone = {
        zoneName: state.hierarchy.assetName,
        zoneKey: id,
        zoneParentKey: '',
        opportunityPriority: 1,
        lowerTolerance: null,
        upperTolerance: null,
        burnerDefinitions: [],
        attributes: [],
      };
      Vue.set(state.hierarchy.zones, 0, rootZone);
    },
    renameSubzone(
      state: IHierarchyStoreState,
      item: { item: ITreeItem; value: string }
    ): void {
      const foundZoneIndex = findZoneIndex(item.item.associatedZoneKey || '');
      if (foundZoneIndex !== -1) {
        Vue.set(state.hierarchy.zones[foundZoneIndex], 'zoneName', item.value);
      }
    },
    addRootAttribute(
      state: IHierarchyStoreState,
      payload: { attribute: IAttribute; zoneKey: string; burnerKey?: string }
    ): void {
      const zone = findZone(payload.zoneKey);
      if (payload.burnerKey) {
        const burner = zone.burnerDefinitions.find(
          (def) => def.burnerKey === payload.burnerKey
        );
        burner.attributes.push(payload.attribute);
      } else {
        zone.attributes.push(payload.attribute);
      }
    },
    copyAttribute(state: IHierarchyStoreState, attribute: IAttribute): void {
      state.copiedAttribute = attribute;
    },
    updateSubzone(state: IHierarchyStoreState, item: IUpdateZoneDetails): void {
      const zone = findZone(item.zoneKey);
      const rootZone = zone.zoneParentKey === '';
      zone.opportunityPriority = item.opportunityPriority;
      zone.upperTolerance = item.upperTolerance;
      zone.lowerTolerance = item.lowerTolerance;
      zone.notes = item.notes;
      if (rootZone) {
        zone.opportunityScoreType = item.opportunityScoreType;
      } else if (!rootZone) {
        zone.zoneName = item.name;
      }
    },
    newHierarchy(state: IHierarchyStoreState): void {
      state.hierarchy = {
        key: uuid(),
        assetName: '',
        assetKey: '',
        customerName: '',
        customerKey: '',
        siteName: '',
        siteKey: '',
        dateCreated: new Date().toDateString(),
        updatedBy: state.user.username,
        dateUpdated: '',
        hierarchyName: '',
        zones: [],
        status: HIERARCHY_STATES.DRAFT,
        walkingOrders: [],
      };
      state.prevHierarchy = cloneDeep(state.hierarchy);
    },
    clearHierarchy(state: IHierarchyStoreState): void {
      state.hierarchy.zones.splice(1);
      state.hierarchy.zones[0].burnerDefinitions.splice(0);
      state.hierarchy.walkingOrders = [];
      state.openZones = [];
      state.burnerConfigsPlaceHolder = [];
    },
    updateSelectedHierarchySaved(
      state: IHierarchyStoreState,
      saveString: string
    ): void {
      state.selectedHierarchySaved = saveString;
    },
    undoWalkingOrder(
      state: IHierarchyStoreState,
      item: IWalkingOrderTreeItem
    ): void {
      const newOrders: IBurnersOrder[] = state.selectedWalkingOrder.burnerOrder.filter(
        (bo: IBurnersOrder) => bo.zoneKey !== item.zoneKey
      );

      state.selectedWalkingOrder.burnerOrder.splice(0);
      state.selectedWalkingOrder.burnerOrder.push(...newOrders);
      state.selectedWalkingOrder.burnerOrder.forEach((bo, index) => {
        bo.order = index + 1;
      });
    },
    removeRootZone(state: IHierarchyStoreState): void {
      state.hierarchy.zones = [];
    },
    moveOrder(
      state: IHierarchyStoreState,
      payload: { walkOrder: IWalkingOrder; source: number; target: number }
    ): void {
      if (HelperMethods.isNullOrUndefined(state.selectedWalkingOrder)) {
        return;
      }
      const sourceItem =
        state.selectedWalkingOrder.burnerOrder[payload.source - 1];
      sourceItem.order = payload.target;

      state.selectedWalkingOrder.burnerOrder.filter((item) => item !== sourceItem).forEach((item) => {
        if (payload.source < payload.target) {
          if (item.order <= payload.target && item.order > payload.source) {
            item.order--;
          }
        } else {
          if (item.order >= payload.target && item.order < payload.source) {
            item.order++;
          }
        }
      });
      state.selectedWalkingOrder.burnerOrder.sort(sortByOrder);
    },
    removeOrder(
      state: IHierarchyStoreState,
      payload: { walkOrder: IWalkingOrder; indexToDelete: number }
    ): void {
      payload.walkOrder.burnerOrder.splice(payload.indexToDelete, 1);
    },
    sortWalkingOrder(state: IHierarchyStoreState): void {
      if (state.selectedWalkingOrder) {
        state.selectedWalkingOrder.burnerOrder.sort(sortByOrder);
      }
    },
    updateSelectedWalkingOrder(
      state: IHierarchyStoreState,
      walkOrder: IWalkingOrder
    ): void {
      state.selectedWalkingOrder = walkOrder;
    },
    addZoneBurnersToWalkingOrder(
      state: IHierarchyStoreState,
      payload: { walkOrder: IWalkingOrder; target: number; zoneKey: string }
    ): void {
      if (state.selectedWalkingOrder) {
        const burnersToAdd = state.hierarchy.zones.find(
          (zone) => zone.zoneKey === payload.zoneKey
        )?.burnerDefinitions;
        state.selectedWalkingOrder.burnerOrder.forEach((item) => {
          if (item.order > payload.target && burnersToAdd) {
            item.order += burnersToAdd.length + 1;
          }
        });
        if (burnersToAdd) {
          burnersToAdd.forEach((burner, index) => {
            if (burner.burnerName) {
              state.selectedWalkingOrder.burnerOrder.push({
                burnerKey: burner.burnerKey,
                zoneKey: payload.zoneKey,
                order: payload.target + index + 1,
              });
            }
          });
        }
        if (state.selectedWalkingOrder) {
          state.selectedWalkingOrder.burnerOrder.sort(sortByOrder);
        }
        state.selectedWalkingOrder.burnerOrder.forEach((bo, index) => {
          bo.order = index + 1;
        });
      }
    },
    updateWalkingOrder(
      state: IHierarchyStoreState,
      walkingOrder: IWalkingOrder
    ): void {
      let itemIndex = -1;
      state.hierarchy.walkingOrders.forEach((item, index) => {
        if (item.walkingOrderKey === walkingOrder.walkingOrderKey) {
          itemIndex = index;
        }
      });
      if (itemIndex !== -1) {
        state.hierarchy.walkingOrders[itemIndex] = walkingOrder;
      }
    },
    deleteWalkingOrder(
      state: IHierarchyStoreState,
      walkingOrder: IWalkingOrder
    ): void {
      state.hierarchy.walkingOrders.splice(
        state.hierarchy.walkingOrders.indexOf(walkingOrder),
        1
      );
    },
    cleanHierarchy(state: IHierarchyStoreState): void {
      const cleanHierarchy = {
        key: '',
        assetName: '',
        assetKey: '',
        customerName: '',
        customerKey: '',
        siteName: '',
        siteKey: '',
        dateCreated: '',
        updatedBy: '',
        hierarchyName: '',
        zones: [],
        status: HIERARCHY_STATES.NONE,
        walkingOrders: [],
      };
      Vue.set(state, 'hierarchy', cleanHierarchy);
    },
    selectTreeItem(state: IHierarchyStoreState, item: ITreeItem): void {
      state.selectedTreeItem = item;
    },
    clearSelectedTreeItem(state: IHierarchyStoreState): void {
      state.selectedTreeItem = null;
    },
    addNewlyDefinedBurners(
      state: IHierarchyStoreState,
      WO: IWalkingOrder
    ): void {
      const WOkeys = WO.burnerOrder.map((wo) => wo.burnerKey);
      const allDefinedBurners: IBurnersOrder[] = gatherDefinedBurners(
        state.hierarchy.zones
      );
      const allDefinedBurnersKeys: string[] = allDefinedBurners.map(
        (burner) => burner.burnerKey
      );
      const burnerKeysToAdd = allDefinedBurnersKeys.filter(
        (burn) => !WOkeys.includes(burn)
      );
      const burnersToAdd = allDefinedBurners.filter((burn) =>
        burnerKeysToAdd.includes(burn.burnerKey)
      );

      burnersToAdd.forEach((burner) => {
        state.selectedWalkingOrder.burnerOrder.push({
          burnerKey: burner.burnerKey,
          zoneKey: burner.zoneKey,
          order: state.selectedWalkingOrder.burnerOrder.length + 1,
        });
      });
      state.selectedWalkingOrder.burnerOrder.forEach((item, index) => {
        item.order = index + 1;
      });
      if (state.selectedWalkingOrder) {
        state.selectedWalkingOrder.burnerOrder.sort(sortByOrder);
      }
    },
    copyZoneDetails(state: IHierarchyStoreState, item: ITreeItem): void {
      const zone = findZone(item.associatedZoneKey || '');
      if (zone) {
        state.copiedZoneDetails.lowerTolerance = zone.lowerTolerance;
        state.copiedZoneDetails.upperTolerance = zone.upperTolerance;
        state.copiedZoneDetails.opportunityPriority = zone.opportunityPriority;
      }
    },
    pasteZoneDetails(state: IHierarchyStoreState, item: ITreeItem): void {
      const zoneIndex = findZoneIndex(item.associatedZoneKey || '');
      Vue.set(
        state.hierarchy.zones[zoneIndex],
        'lowerTolerance',
        state.copiedZoneDetails.lowerTolerance
      );
      Vue.set(
        state.hierarchy.zones[zoneIndex],
        'upperTolerance',
        state.copiedZoneDetails.upperTolerance
      );
      Vue.set(
        state.hierarchy.zones[zoneIndex],
        'opportunityPriority',
        state.copiedZoneDetails.opportunityPriority
      );
    },
    updateBurner(
      state: IHierarchyStoreState,
      payload: { burnerKey: string; zoneKey: string; burner: IBurnerDefinition }
    ): void {
      const zoneIndex = findZoneIndex(payload.zoneKey);
      const burnerIndex = findBurnerIndex(payload.burnerKey, zoneIndex);
      state.hierarchy.zones[zoneIndex].burnerDefinitions[burnerIndex] =
        payload.burner;
    },
    updateZone(
      state: IHierarchyStoreState,
      payload: { zoneKey: string; zone: IZone }
    ): void {
      const zoneIndex = findZoneIndex(payload.zoneKey);
      state.hierarchy.zones[zoneIndex] = payload.zone;
    },
    copyAttributes(
      state: IHierarchyStoreState,
      copiedAttributes: IAttribute[]
    ): void {
      state.copiedAttributes = copiedAttributes;
    },
    pasteAttributesToZones(state: IHierarchyStoreState, item: ITreeItem): void {
      const startZone = findZone(item.associatedZoneKey || ''); // zone that the details were pasted onto
      const hierarchyKeysToUpdate = gatherChildrenKeys(
        startZone.zoneKey,
        state.hierarchy.zones
      );

      state.hierarchy.zones.forEach((zone) => {
        if (hierarchyKeysToUpdate.includes(zone.zoneKey)) {
          zone.attributes = state.copiedAttributes;
        }
      });
    },
    pasteAttributesToBurners(state: IHierarchyStoreState, item: ITreeItem): void {
      const zone = findZone(item.associatedZoneKey || '');
      zone.burnerDefinitions.forEach((def) => {
        def.attributes = state.copiedAttributes;
      });
    },
    toggleZone(state: IHierarchyStoreState, zoneKey: string): void {
      if (state.openZones.includes(zoneKey)) {
        state.openZones = state.openZones.filter((zone) => zone !== zoneKey);
      } else {
        state.openZones.push(zoneKey);
      }
    },
    moveBurnersInWalkingOrders(
      state: IHierarchyStoreState,
      data: ITreeLiaison
    ): void {
      const burnerKeysToUpdate: string[] = [];
      const foundZone = findZone(data.source.associatedZoneKey);
      foundZone.burnerDefinitions.forEach((def: IBurnerDefinition) => {
        if (def.burnerTypeKey === data.source.burnerTypeKey) {
          burnerKeysToUpdate.push(def.burnerKey);
        }
      });
      state.hierarchy.walkingOrders.forEach((wo) => {
        wo.burnerOrder.forEach((bo) => {
          if (burnerKeysToUpdate.includes(bo.burnerKey)) {
            bo.zoneKey = data.target.associatedZoneKey;
          }
        });
      });
    },
    moveBurnersFromZoneToZone(state: IHierarchyStoreState, data: ITreeLiaison): void {
      const sourceZoneIndex = findZoneIndex(data.source.associatedZoneKey);
      const targetZoneIndex = findZoneIndex(
        data.target.associatedZoneKey || ''
      );
      const movedBurners = state.hierarchy.zones[
        sourceZoneIndex
      ].burnerDefinitions?.filter(
        (def) => def.burnerTypeKey === data.source.burnerTypeKey
      );
      if (movedBurners) {
        movedBurners.forEach(
          (burner) => (burner.zoneKey = data.target.associatedZoneKey)
        );
        state.hierarchy.zones[
          targetZoneIndex
        ].burnerDefinitions = state.hierarchy.zones[
          targetZoneIndex
        ].burnerDefinitions.concat(movedBurners);
      }
      state.hierarchy.zones[
        sourceZoneIndex
      ].burnerDefinitions = state.hierarchy.zones[
        sourceZoneIndex
      ].burnerDefinitions.filter(
        (def) => def.burnerTypeKey !== data.source.burnerTypeKey
      );
    },
    addBurnerPlaceHolder(state: IHierarchyStoreState, data: ITreeLiaison): void {
      if (data.source.associatedZoneKey) {
        const placeHolderFound = state.burnerConfigsPlaceHolder.find(
          (config) =>
            config.burnerTypeKey === data.source.burnerTypeKey &&
            config.zoneKey === data.source.associatedZoneKey
        );
        if (placeHolderFound) {
          placeHolderFound.zoneKey = data.target.associatedZoneKey || '';
        }
      } else {
        state.burnerConfigsPlaceHolder.push({
          zoneKey: data.target.associatedZoneKey || '',
          burnerTypeKey: data.source.burnerTypeKey,
          name: data.source.name,
        });
      }
    },
    deletePlaceHolderByZone(state: IHierarchyStoreState, item: ITreeItem): void {
      state.burnerConfigsPlaceHolder = state.burnerConfigsPlaceHolder.filter(
        (config) => config.zoneKey !== item.associatedZoneKey
      );
    },
    deleteZoneFromHierarchy(state: IHierarchyStoreState, item: ITreeItem): void {
      const deletedHierarchyItem = findZone(item.associatedZoneKey || '');
      const hierarchyKeysToDelete = gatherChildrenKeys(
        deletedHierarchyItem.zoneKey,
        state.hierarchy.zones
      );

      const newSubzones = state.hierarchy.zones.filter(
        (i) => !hierarchyKeysToDelete.includes(i.zoneKey)
      );
      Vue.set(state.hierarchy, 'zones', newSubzones);
    },
    deleteBurnersFromWalkingOrders(
      state: IHierarchyStoreState,
      item: ITreeItem
    ): void {
      const hierarchyKeysToDelete = gatherChildrenKeys(
        item.associatedZoneKey,
        state.hierarchy.zones
      );

      state.hierarchy.walkingOrders.forEach((wo, index) => {
        const burnersToRemove: string[] = [];
        wo.burnerOrder.forEach((bo) => {
          if (
            bo.zoneKey === item.associatedZoneKey ||
            hierarchyKeysToDelete.includes(bo.zoneKey)
          ) {
            burnersToRemove.push(bo.burnerKey);
          }
        });
        const newBurnerOrder = wo.burnerOrder.filter(
          (bo) => !burnersToRemove.includes(bo.burnerKey)
        );
        wo.burnerOrder.splice(0);
        Vue.set(
          state.hierarchy.walkingOrders[index],
          'burnerOrder',
          newBurnerOrder
        );

        // this is needed to make sure order remains consecutive after deletions
        wo.burnerOrder.forEach((bo, boIndex) => {
          bo.order = boIndex + 1;
        });
      });
    },
    deleteBurnerFromZone(
      state: IHierarchyStoreState,
      burner: IBurnerDefinition
    ): void {
      let zoneIndex = null;
      if (burner.zoneKey) {
        zoneIndex = findZoneIndex(burner.zoneKey);
        const burnerIndex = state.hierarchy.zones[
          zoneIndex
        ].burnerDefinitions.findIndex(
          (def) => def.burnerKey === burner.burnerKey
        );
        state.hierarchy.zones[zoneIndex].burnerDefinitions.splice(
          burnerIndex,
          1
        );
      }
    },
    deleteBurnerFromWalkingOrders(
      state: IHierarchyStoreState,
      burner: IBurnerDefinition
    ): void {
      state.hierarchy.walkingOrders.forEach((wo) => {
        wo.burnerOrder.forEach((bo, index, array) => {
          if (bo.burnerKey === burner.burnerKey) {
            array.splice(index, 1);
          }
        });
        // this is needed to make sure order remains consecutive after deletions
        wo.burnerOrder.forEach((bo, index) => {
          bo.order = index + 1;
        });
      });
    },
    createBurners(
      state: IHierarchyStoreState,
      data: { count: number; item: ITreeItem }
    ): void {
      const zoneIndex = findZoneIndex(data.item.associatedZoneKey || '');
      const found = state.assets.find(
        (asset) => asset.key === state.hierarchy.assetKey
      );
      const foundBurner = found.burnerList.find(
        (burner) => burner.burnerKey === data.item.burnerTypeKey
      );
      for (let i = 0; i < data.count; i++) {
        state.hierarchy.zones[zoneIndex].burnerDefinitions.push({
          attributes: [],
          burnerTypeName: data.item.name,
          burnerTypeKey: foundBurner.burnerKey,
          burnerKey: uuid(),
          opportunityPriority: 1,
          description: foundBurner.burnerDetail.zoneType,
          zoneKey: data.item.associatedZoneKey,
        });
      }
    },
    increaseBurners(
      state: IHierarchyStoreState,
      data: { count: number; item: ITreeItem }
    ): void {
      const zoneIndex = findZoneIndex(data.item.associatedZoneKey || '');
      const found = state.assets.find(
        (asset) => asset.key === state.hierarchy.assetKey
      );
      const existingBurnerCount = state.hierarchy.zones[zoneIndex].burnerDefinitions.filter(
        (def) => def.burnerTypeKey === data.item.burnerTypeKey
      );
      const burnerDescription = existingBurnerCount?.[0]?.description
        ?? found.burnerList.find((burner) => burner.burnerKey === data.item.burnerTypeKey).burnerDetail.zoneType;
      const increaseAmount = data.count - existingBurnerCount.length;
      for (let i = 0; i < increaseAmount; i++) {
        state.hierarchy.zones[zoneIndex].burnerDefinitions.push({
          attributes: [],
          burnerTypeName: data.item.name,
          burnerTypeKey: data.item.burnerTypeKey,
          burnerKey: uuid(),
          opportunityPriority: 1,
          description: burnerDescription,
          zoneKey: data.item.associatedZoneKey,
        });
      }
    },
    deletePlaceHolder(state: IHierarchyStoreState, placeHolderIndex: number): void {
      if (placeHolderIndex > -1) {
        state.burnerConfigsPlaceHolder.splice(placeHolderIndex, 1);
      }
    },
    addToWalkingOrder(
      state: IHierarchyStoreState,
      item: IWalkingOrderTreeItem
    ): void {
      const zoneIndex = findZoneIndex(item.zoneKey || '');
      const burnerCount = state.hierarchy.zones[zoneIndex].burnerDefinitions
        .length;
      const walkingOrderIndex = state.hierarchy.walkingOrders.findIndex(
        (wo) => wo.name === item.walkingOrderName
      );
      if (burnerCount > 0) {
        state.hierarchy.zones[zoneIndex].burnerDefinitions.forEach((def) => {
          if (def.burnerName) {
            state.hierarchy.walkingOrders[walkingOrderIndex].burnerOrder.push({
              order: 0,
              burnerKey: def.burnerKey || '',
              zoneKey: item.zoneKey || '',
            });
          }
        });
      }
      state.hierarchy.walkingOrders[walkingOrderIndex].burnerOrder.forEach(
        (burnerOrder, index) => {
          burnerOrder.order = index + 1;
        }
      );
    },
    createAndAddToWalkingOrder(
      state: IHierarchyStoreState,
      item: IWalkingOrderTreeItem
    ): void {
      const zoneIndex = findZoneIndex(item.zoneKey || '');
      const burnerCount = state.hierarchy.zones[zoneIndex].burnerDefinitions
        .length;
      const walkingOrder: IWalkingOrder = {
        status: 'Draft',
        name: item.walkingOrderName || '',
        walkingOrderKey: uuid(),
        burnerOrder: [],
      };
      if (burnerCount > 0) {
        state.hierarchy.zones[zoneIndex].burnerDefinitions.forEach((def) => {
          if (def.burnerName) {
            walkingOrder.burnerOrder.push({
              order: 0,
              burnerKey: def.burnerKey,
              zoneKey: item.zoneKey || '',
            });
          }
        });
      }
      walkingOrder.burnerOrder.forEach((burnerOrder, index) => {
        burnerOrder.order = index + 1;
      });
      state.hierarchy.walkingOrders = [];
      Vue.set(state.hierarchy.walkingOrders, 0, walkingOrder);
    },
    deleteBurnerTypeFromWalkingOrder(
      state: IHierarchyStoreState,
      burnerKeysForDeletion: string[]
    ): void {
      state.hierarchy.walkingOrders.forEach((wo, index) => {
        const newBurnerOrder = wo.burnerOrder.filter(
          (bo) => !burnerKeysForDeletion.includes(bo.burnerKey)
        );
        wo.burnerOrder.splice(0);
        Vue.set(
          state.hierarchy.walkingOrders[index],
          'burnerOrder',
          newBurnerOrder
        );

        // this is needed to make sure order remains consecutive after deletions
        wo.burnerOrder.forEach((bo, boIndex) => {
          bo.order = boIndex + 1;
        });
      });
    },
    updateBurnersForZone(
      state: IHierarchyStoreState,
      payload: { newDefinitions: IBurnerDefinition[]; zoneIndex: number }
    ): void {
      state.hierarchy.zones[payload.zoneIndex].burnerDefinitions?.splice(0);
      Vue.set(
        state.hierarchy.zones[payload.zoneIndex],
        'burnerDefinitions',
        payload.newDefinitions
      );
    },
    updateZones(state: IHierarchyStoreState, zones: IZone[]): void {
      state.hierarchy.zones = zones;
    },
    toggleCloneModal(state: IHierarchyStoreState): void {
      state.cloneModalIsOpen = !state.cloneModalIsOpen;
    },
  } as IHierarchyStoreMutations,
  actions: {
    async retrieveServiceConfig(context: IAssetContext): Promise<void> {
      if (
        process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL &&
        process.env.VUE_APP_PORTAL_API_URL
      ) {
        context.commit(
          'updateService',
          new HierarchyService(
            sharedAxiosInstance.sharedHbAxiosInstance,
            process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL
          )
        );
        context.commit(
          'updateCustomerService',
          new KESCustomerService(
            sharedAxiosInstance.sharedKesAxiosInstance,
            process.env.VUE_APP_PORTAL_API_URL
          )
        );
        context.commit(
          'updateAccountService',
          await UserManagementService.factory()
        );
      } else {
        const conf = await ConfigFactory.GetConfig();
        context.commit(
          'updateService',
          new HierarchyService(
            sharedAxiosInstance.sharedHbAxiosInstance,
            conf.get('apiBaseUrl')
          )
        );
        context.commit(
          'updateCustomerService',
          new KESCustomerService(
            sharedAxiosInstance.sharedKesAxiosInstance,
            conf.get('portalApiUrl')
          )
        );
        context.commit(
          'updateAccountService',
         await UserManagementService.factory()
        );
      }
      await context.state
        .userManagementService.getUser()
        .then((response) => context.commit('updateUser', response));
      await context.state
        .customerService.getAssignmentTree()
        .then((response) => context.commit('updateAssignments', response));
    },
    async retrieveAssets(context: IAssetContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const url = conf.get('caeApiUrl');
      context.commit(
        'updateCustomerAssetService',
        new KESCustomerAssetService(
          sharedAxiosInstance.sharedCAEAxiosInstance,
          url
        )
      );
      await context.state
        .customerAssetService.getActiveCustomerAssets(
          context.state.user.activeCustomerKey
        )
        .then((response) => {
          // Filter out Flare equipmentTypes here and check that user is assigned to each asset
          const filteredAssets = response.filter(
            (asset) =>
              asset.equipmentType !== 'Flare' &&
              context.state.availableAssignments.filter(
                (assignment) => assignment.key === asset.key
              ).length > 0
          );
          context.commit('updateAssets', filteredAssets);
        });
    },
    async retrieveHierarchies(context: IAssetContext): Promise<void> {
      await context.state
        .service.getActiveCustomerHierarchies(
          context.state.user.activeCustomerKey
        )
        .then((response) => {
          response?.forEach((h) => {
            h.dateCreated = DateUtil.translateDate(h.dateCreated);
            h.dateUpdated = DateUtil.translateDate(h.dateUpdated);
          });
          context.commit('updateHierarchies', response);
        });
    },
    async retrieveZones(
      context: IAssetContext,
      hierarchy: IHierarchyDefiniton
    ): Promise<void> {
      if (!hierarchy) {
        return;
      }
      await context.state
        .service.getHierarchyZones(
          hierarchy.customerKey,
          hierarchy.assetKey,
          hierarchy.key
        )
        .then((response) => {
          hierarchy.zones = response;
        });
    },
    async deleteHierarchy(context: IAssetContext, key: string): Promise<void> {
      await context.state.service.deleteHierarchy(key);
    },
    async saveHierarchy(
      context: IAssetContext,
      hierarchy: IHierarchyDefiniton
    ): Promise<void> {
      if (hierarchy.status === HIERARCHY_STATES.PUBLISHED) {
        context.commit('toggleCloneModal');
        hbEventBus.$on(TreeActionTypeEnums.ConfirmHierarchyClone, async () => {
          const hierarchyClone = cloneDeep(hierarchy);
          hierarchyClone.key = uuid();
          hierarchyClone.status = HIERARCHY_STATES.DRAFT;
          context.commit('updateHierarchy', hierarchyClone);
          context.commit('updateSelectedHierarchySaved', 'Saving');
          context.commit('toggleCloneModal');
          hbEventBus.$off(TreeActionTypeEnums.ConfirmHierarchyClone);
          hierarchy.updatedBy = context.state.user
            ? context.state.user.name
            : 'USER_NOT_FOUND';
          await context.state
            .service.saveHierarchy(hierarchyClone)
            .then((response) => {
              hierarchyClone.dateCreated = DateUtil.translateDate(
                response.dateCreated
              );
              hierarchyClone.dateUpdated = DateUtil.translateDate(response.dateUpdated);
              hierarchyClone.updatedBy = response.updatedBy;
              context.commit('updateHierarchy', hierarchyClone);
              context.commit('updatePrevHierarchy', cloneDeep(hierarchyClone));
              context.commit(
                'updateSelectedHierarchySaved',
                'All changes saved'
              );
            });
        });
      } else {
        if (hierarchy.status === HIERARCHY_STATES.PUBLISH) {
          hierarchy.status = HIERARCHY_STATES.PUBLISHED;
        }
        context.commit('updateSelectedHierarchySaved', 'Saving');
        hierarchy.updatedBy = context.state.user
          ? context.state.user.name
          : 'USER_NOT_FOUND';
        await context.state
          .service.saveHierarchy(hierarchy)
          .then((response) => {
            hierarchy.dateCreated = response.dateCreated;
            hierarchy.dateUpdated = response.dateUpdated;
            hierarchy.updatedBy = response.updatedBy;
            context.commit('updateHierarchy', hierarchy);
            context.commit('updatePrevHierarchy', cloneDeep(hierarchy));
            context.commit('updateSelectedHierarchySaved', 'All changes saved');
          });
      }
    },
    handleLeafToZoneDrag(context: IAssetContext, data: ITreeLiaison): void {
      if (context.getters.burnerTypeAlreadyExistInZone(data)) {
        return;
      }
      const physicalBurnersExist: boolean = data.source.burnerCount > 0;
      if (physicalBurnersExist) {
        context.commit('moveBurnersInWalkingOrders', data);
        context.commit('moveBurnersFromZoneToZone', data);
      } else {
        context.commit('addBurnerPlaceHolder', data);
      }
      const zoneToggledOpen = context.state.openZones.includes(
        data.target.associatedZoneKey || ''
      );
      if (!zoneToggledOpen) {
        context.commit('toggleZone', data.target?.associatedZoneKey);
      }
    },
    deleteSubzone(context: IAssetContext, item: ITreeItem): void {
      // dont delete root
      if (!item.parentKey) {
        return;
      }
      context.commit('deletePlaceHolderByZone', item);
      context.commit('deleteZoneFromHierarchy', item);
      context.commit('deleteBurnersFromWalkingOrders', item);
    },
    deleteBurner(context: IAssetContext, burner: IBurnerDefinition): void {
      context.commit('deleteBurnerFromZone', burner);
      context.commit('deleteBurnerFromWalkingOrders', burner);
    },
    generateBurners(
      context: IAssetContext,
      data: { count: number; item: ITreeItem }
    ): void {
      const zoneIndex = findZoneIndex(data.item.associatedZoneKey || '');
      const existingBurnerCount = context.state.hierarchy.zones[
        zoneIndex
      ].burnerDefinitions.filter(
        (def) => def.burnerTypeKey === data.item.burnerTypeKey
      );
      if (existingBurnerCount.length === 0) {
        context.commit('createBurners', data);
        const found = context.state.assets.find(
          (asset) => asset.key === context.state.hierarchy.assetKey
        );
        const foundBurner = found.burnerList.find(
          (burner) => burner.burnerKey === data.item.burnerTypeKey
        );
        const placeHolderIndex = context.state.burnerConfigsPlaceHolder.findIndex(
          (config) =>
            config.burnerTypeKey === foundBurner.burnerKey &&
            config.zoneKey === data.item.associatedZoneKey
        );
        if (placeHolderIndex > -1) {
          context.commit('deletePlaceHolder', placeHolderIndex);
        }
      } else {
        context.commit('increaseBurners', data);
      }
    },
    generateWalkingOrder(
      context: IAssetContext,
      item: IWalkingOrderTreeItem
    ): void {
      context.state.hierarchy.walkingOrders.length > 0
        ? context.commit('addToWalkingOrder', item)
        : context.commit('createAndAddToWalkingOrder', item);
    },
    handleDeleteLeaf(context: IAssetContext, item: ITreeItem): void {
      const foundZoneIndex = findZoneIndex(item.associatedZoneKey || '');
      const burnerDefinitions =
        context.state.hierarchy.zones[foundZoneIndex].burnerDefinitions;
      const burnerKeysForDeletion: string[] = gatherBurnerKeysForZoneAndOfType(
        item,
        burnerDefinitions
      );

      context.commit('deleteBurnerTypeFromWalkingOrder', burnerKeysForDeletion);
      const placeHolderIndex = context.state.burnerConfigsPlaceHolder.findIndex(
        (config) =>
          config.burnerTypeKey === item.burnerTypeKey &&
          config.zoneKey === item.associatedZoneKey
      );
      if (placeHolderIndex > -1) {
        context.commit('deletePlaceHolder', placeHolderIndex);
      }
      const newDefinitions: IBurnerDefinition[] = burnerDefinitions.filter(
        (def) => def.burnerTypeKey !== item.burnerTypeKey
      );
      const payload = {
        newDefinitions,
        zoneIndex: foundZoneIndex,
      };
      context.commit('updateBurnersForZone', payload);
    }
  } as IHierarchyStoreActions
};

function gatherBurnerKeysForZoneAndOfType(
  item: ITreeItem,
  burnerDefinitions: IBurnerDefinition[]
): string[] {
  const burnerKeysForDeletion: string[] = [];
  burnerDefinitions.forEach((def) => {
    if (def.burnerTypeKey === item.burnerTypeKey && def.burnerKey) {
      burnerKeysForDeletion.push(def.burnerKey);
    }
  });
  return burnerKeysForDeletion;
}

function createWalkingOrderBurnerTreeItems(
  state: IHierarchyStoreState,
  items: ITreeItem[]
): ITreeItem[] {
  const burnerItems = items.slice(0);
  burnerItems.forEach((item: ITreeItem) => {
    const zoneIndex = findZoneIndex(item.associatedZoneKey || '');
    const definedBurners = state.hierarchy.zones[
      zoneIndex
    ]?.burnerDefinitions?.filter((def) => def.burnerName !== null);
    if (definedBurners && definedBurners.length > 0) {
      burnerItems.push({
        name: item.name,
        key: uuid(),
        parentKey: item.key,
        associatedZoneKey: item.associatedZoneKey,
        type: 'Leaf',
      });
    }
  });
  return burnerItems;
}

function createZoneTreeItems(state: IHierarchyStoreState): ITreeItem[] {
  return state.hierarchy.zones.map((zone) => {
    return {
      name: zone.zoneName,
      key: zone.zoneKey,
      parentKey: zone.zoneParentKey,
      associatedZoneKey: zone.zoneKey,
      type: 'Zone',
      isOpen: !!state.openZones.includes(zone.zoneKey || ''),
      isRoot: zone.zoneParentKey === '',
    };
  });
}

function createAttributeBurnerTreeItems(
  burners: IBurnerDefinition[],
  items: ITreeItem[]
): ITreeItem[] {
  return burners.map((burner: IBurnerDefinition) => ({
    name: burner.burnerName,
    key: uuid(),
    parentKey: items
      .filter((item) => item.associatedZoneKey === burner.zoneKey)
      .map((item) => item.key)[0],
    associatedZoneKey: items
      .filter((item) => item.associatedZoneKey === burner.zoneKey)
      .map((item) => item.associatedZoneKey)[0],
    associatedDefinitionKey: burner.burnerKey,
    type: 'Leaf',
    isOpen: false,
    isRoot: false,
    burnerCount: 0,
    burnerKey: burner.burnerKey,
    burnerTypeName: burner.burnerTypeName,
  }));
}

function gatherBurnersForZones(
  state: IHierarchyStoreState,
  items: ITreeItem[]
): IBurnerDefinition[] {
  const burners: IBurnerDefinition[] = [];
  items.forEach((item: ITreeItem) => {
    const zoneIndex = findZoneIndex(item.associatedZoneKey || '');
    if (zoneIndex !== -1) {
      burners.push(
        state.hierarchy.zones[zoneIndex].burnerDefinitions.map(
          (def: IBurnerDefinition) => def
        ) as IBurnerDefinition
      );
    }
  });
  return burners;
}

function gatherDefinedBurners(zones: IZone[]): IBurnersOrder[] {
  const allDefinedBurners: IBurnersOrder[] = [];
  zones.forEach((zone) => {
    zone.burnerDefinitions.forEach((def) => {
      if (def.burnerName) {
        allDefinedBurners.push({
          burnerKey: def.burnerKey != null ? def.burnerKey : '',
          zoneKey: def.zoneKey != null ? def.zoneKey : '',
          order: 0,
        });
      }
    });
  });
  return allDefinedBurners;
}

function createPlaceHolderTreeItems(
  burnerConfigsPlaceHolder: IBurnerConfigsPlaceHolder[]
): ITreeItem[] {
  return burnerConfigsPlaceHolder.map((config) => {
    return {
      name: config.name,
      key: uuid(),
      parentKey: config.zoneKey,
      associatedZoneKey: config.zoneKey,
      type: 'Leaf',
      burnerCount: 0,
      burnerKey: uuid(),
      burnerTypeKey: config.burnerTypeKey,
    };
  });
}

function updateBurnerCountOnBurnerTreeItem(
  items: ITreeItem[],
  burnerTypes: Array<{ type: string; zone: string; count: number }>
): ITreeItem[] {
  const treeItems: ITreeItem[] = items.slice(0);
  treeItems.forEach((item) => {
    if (item.type === 'Leaf') {
      const burnerTypeFound = burnerTypes.find(
        (burnerType) =>
          burnerType.zone === item.associatedZoneKey &&
          burnerType.type === item.burnerTypeKey
      );
      if (burnerTypeFound) {
        item.burnerCount = burnerTypeFound.count;
      }
    }
  });
  return treeItems;
}

function countUniqueBurnerTypes(
  zones: IZone[]
): Array<{ type: string; zone: string; count: number }> {
  const burnerTypes: Array<{ type: string; zone: string; count: number }> = [];
  zones.forEach((zone) => {
    if (zone.burnerDefinitions && zone.burnerDefinitions.length > 0) {
      // gather count of unique types of burners
      zone.burnerDefinitions.forEach((def) => {
        if (
          !burnerTypes.some(
            (burnerType) =>
              burnerType.zone === def.zoneKey &&
              burnerType.type === def.burnerTypeKey
          )
        ) {
          burnerTypes.push({
            type: def.burnerTypeKey || '',
            zone: def.zoneKey || '',
            count: 1,
          });
        } else {
          const zoneAndBurnerTypeFound = burnerTypes.find(
            (burnerType) =>
              burnerType.zone === def.zoneKey &&
              burnerType.type === def.burnerTypeKey
          );
          zoneAndBurnerTypeFound.count++;
        }
      });
    }
  });
  return burnerTypes;
}

function createTreeItems(state: IHierarchyStoreState): ITreeItem[] {
  let items: ITreeItem[] = [];
  state.hierarchy.zones.forEach((zone) => {
    const zoneItem = {
      name: zone.zoneName,
      key: zone.zoneKey,
      parentKey: zone.zoneParentKey,
      associatedZoneKey: zone.zoneKey,
      type: 'Zone',
      isOpen: !!state.openZones.includes(zone.zoneKey || ''),
      isRoot: zone.zoneParentKey === '',
    };
    items.push(zoneItem);
    if (zone.burnerDefinitions) {
      items = items.concat(createBurnerTreeItems(zone.burnerDefinitions));
    }
  });
  return items;
}

function createBurnerTreeItems(
  burnerDefinitions: IBurnerDefinition[]
): ITreeItem[] {
  const items: ITreeItem[] = [];
  if (burnerDefinitions.length > 0) {
    burnerDefinitions.forEach((def) => {
      if (
        !items.some(
          (item) =>
            item.associatedZoneKey === def.zoneKey &&
            item.burnerTypeKey === def.burnerTypeKey
        )
      ) {
        const burnerItem = {
          name: def.burnerTypeName,
          key: def.burnerKey,
          parentKey: def.zoneKey,
          associatedZoneKey: def.zoneKey,
          type: 'Leaf',
          burnerCount: 0,
          burnerKey: def.burnerKey,
          burnerTypeKey: def.burnerTypeKey,
          burnerTypeName: def.burnerTypeName,
        };
        items.push(burnerItem);
      }
    });
  }
  return items;
}

function sortByOrder(n1: { order: number }, n2: { order: number }): number {
  return n1.order - n2.order;
}

function getParentKeys(item: IZone, keys: string[]): string[] {
  const parentExist = item.zoneParentKey;
  if (!parentExist) {
    return keys;
  }
  keys.push(item.zoneParentKey);
  const parentItem = findZone(item.zoneParentKey);
  return getParentKeys(parentItem, keys);
}

function getDefinedBurnerKeys(zoneKey: string): string[] {
  const definedBurnerKeys: string[] = [];
  const zone = findZone(zoneKey);
  zone.burnerDefinitions.forEach((def) => {
    if (def.burnerName !== '' && def.burnerName != null) {
      definedBurnerKeys.push(def.burnerKey || '');
    }
  });
  return definedBurnerKeys;
}
