
























import HelperMethods from '@/shared/helper-methods';
import { Component, Vue } from 'vue-property-decorator';
import OnpointModal from '../../components/common/OnpointModal.vue';
import store from '../../store';

@Component({
  components: { OnpointModal }
})
export default class Navbar extends Vue {
  private store = store;
  private showWarningModal: boolean = false;

  private get tabs(): Array<{name: string, value: string}> {
    return [
      {name: 'Hierarchy', value: 'hierarchy'},
      // {name: 'Data Mapping', value: 'data-mapping'},
      {name: 'Burners', value: 'burners'},
      {name: 'Attributes', value: 'attributes'},
      {name: 'Walking Order', value: 'walking-order'},
      {name: 'Quick Edits', value: 'quick-edits'}
    ];
  }

  private hideWarningModal(): void {
    this.showWarningModal = false;
  }

  private isActiveLink(routeName: string): boolean {
    const currentRoute = this.$route.name;
    return routeName === currentRoute;
  }

  private get hierarchyCreated(): boolean {
    return store.state.hierarchyState.hierarchy.hierarchyName !== '';
  }

  private handleAttemptedRoute(destination: string): void {
    const hierarchyKey = store.state.hierarchyState.hierarchy?.key;
    if (this.hierarchyCreated && !HelperMethods.isStringEmpty(hierarchyKey)) {
      this.$router.replace(`/hierarchy/${hierarchyKey}/${destination}`);
    } else {
      this.showWarningModal = true;
    }
  }
}
