// Copied from team 1 project.. will most likely change
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';

export interface INavStoreState {
  prevNavKey: string;
  selectedAssetTab: string;
  assetTabEnabled: boolean;
  selectedBurnerTab: string;
}

export interface INavStoreGetters extends GetterTree<INavStoreState, IRootState> {}

export interface INavStoreMutations extends MutationTree<INavStoreState> {
  updateSelectedNav(state: INavStoreState, navKey: string): void;
  updateAssetTabEnabled(state: INavStoreState, value: boolean): void;
  updateSelectedAssetTab(state: INavStoreState, assetTab: string): void;
  updateSelectedBurnerTab(state: INavStoreState, burnerTab: string): void;
  resetAssetTabs(state: INavStoreState): void;
}

export interface INavStoreActions extends ActionTree<INavStoreState, IRootState> {}

export type INavContext = ActionContext<INavStoreState, IRootState>;

export const NavStore = {
  namespaced: true,
  state: {
    prevNavKey: '/',
    selectedAssetTab: 'info',
    assetTabEnabled: true,
    selectedBurnerTab: 'config'
  } as INavStoreState,
  getters: {} as INavStoreGetters,
  mutations: {
    updateSelectedNav(state: INavStoreState, navKey: string): void {
      state.prevNavKey = navKey;
    },
    updateAssetTabEnabled(state: INavStoreState, value: boolean): void {
      state.assetTabEnabled = value;
    },
    updateSelectedAssetTab(state: INavStoreState, assetTab: string): void {
      state.selectedAssetTab = assetTab;
    },
    updateSelectedBurnerTab(state: INavStoreState, burnerTab: string): void {
      state.selectedBurnerTab = burnerTab;
    },
    resetAssetTabs(state: INavStoreState): void {
      state.assetTabEnabled = true;
    }
  } as INavStoreMutations,
  actions: {} as INavStoreActions
};
