import { render, staticRenderFns } from "./Subzone.vue?vue&type=template&id=f5e57a4a&scoped=true&"
import script from "./Subzone.vue?vue&type=script&lang=ts&"
export * from "./Subzone.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Subzone.vue?vue&type=style&index=0&id=f5e57a4a&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f5e57a4a",
  null
  ,true
)

export default component.exports