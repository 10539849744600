






export default {
  name: 'PageNotFound',
};
