





































































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import store from '@/store/';
import Loading from '@/components/common/Loading.vue';
import { IZone } from '@/view-models/hierarchy-view-models';
import {showAndCommitError} from '@/utils/Helpers';
import { IParserError } from '@/view-models/parser-error';
import { parseFile } from '@/components/hierarchy/modals/import-parser';
import { IParserResult } from '@/view-models/parser-result';

Vue.use(BootstrapVue);

@Component({
  name: 'import-hierarchy-modal',
  components: {
    Loading
  }
})
export default class ImportHierarchyModal extends Vue {
  private file: File = null;
  private fileName: string = 'Browse for local file';
  private isUploadDisabled: boolean = false;
  private isProcessing = false;
  private parserErrors: IParserError[] = [];
  private zones: IZone[] = [];

  public mounted(): void {
    // Reset old file data on remount
    this.file = null;
    this.fileName = 'Browse for local file';
    this.isUploadDisabled = false;
  }

  public clearUpload(): void {
    this.file = null;
    this.fileName = 'Browse for local file';
    this.isUploadDisabled = false;
    this.parserErrors = [];
    this.zones = [];
    const fileInput: any =
      document?.querySelector('#hierarchy-builder')?.shadowRoot?.getElementById('file-for-upload');
    fileInput.value = null;
  }

  public onCancel(): void {
    this.clearUpload();
    this.$bvModal.hide('import-hierarchy-modal');
  }

  public async onImport(): Promise<void> {
    this.isProcessing = true;
    // Import hierarchy
    const tempHierarchy: any = {...store.state.hierarchyState.hierarchy};
    tempHierarchy.zones = this.zones;
    store.commit('hierarchyState/updateHierarchy', tempHierarchy);
    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy).then(() => {
        this.$bvModal.show('import-success');
      });
    } catch (error) {
      showAndCommitError(error);
    }
    // Refresh UI
    this.$bvModal.hide('import-hierarchy-modal');
    this.isProcessing = false;
  }

  public updateFile(): void {
    // Reset the burnerTypeIdCache
    store.commit('hierarchyState/setPiAfBurnerTypeKeys', new Map<string, string>());

    this.isProcessing = true;
    // Grab file from upload
    this.file = (document
      ?.querySelector('#hierarchy-builder')
      ?.shadowRoot?.getElementById('file-for-upload') as any)?.files[0];
    if (this.file !== null) {
      this.fileName = this.file.name;
      this.isUploadDisabled = true;
    }
    // Process file
    this.processFile();
  }

  public processFile(): void {
    if (this.file !== null) {
      const reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = (evt) => {
        const fileText = (evt.target?.result as string);
        const parserResult: IParserResult = parseFile(fileText);
        this.zones = parserResult.zones;
        this.parserErrors = parserResult.errors;
        this.isProcessing = false;
      };
    }
  }

  get isImportValid(): boolean {
    if (this.file && this.zones !== undefined && this.parserErrors.length === 0 && !this.isProcessing) {
      return true;
    } else {
      return false;
    }
  }
}
