



















import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component
export default class RadioField extends Vue {
  @Prop({required: true})
  private items: string[];
  @Prop({required: false, default: ''})
  private header: string;
  @PropSync('selected', {required: true})
  private selectedItem: string | number;
}
