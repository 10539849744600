import store from '@/store';

export function showError(message: string): void {
  store.commit('errorState/setError', {
    error: message,
    config: {
      sendLog: false
    },
    uiError: true
  });
}

export function parentEventBus(): Vue {
  return (window as any).eftEventBus as Vue;
}

export function changeParentRoute(newParentRoute: string): void {
  if (inParentApp()) {
    parentEventBus().$emit('GLOBAL_ROUTE_CHANGE', newParentRoute);
  } else {
    window.location.href = newParentRoute;
  }
}

export function inParentApp(): boolean {
  return parentEventBus() != null;
}

export function showAndCommitError(error: any): void {
  const errorMessage = error.response ? error.response.data.message : error.message;
  showError(errorMessage);
  store.commit('hierarchyState/updateSelectedHierarchySaved', 'Failed to save changes');
}
