





































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IAttribute, IBurnerDefinition, IZone} from '@/view-models/hierarchy-view-models';
import Dropdown from '@/components/common/Dropdown.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import FormField from '@/components/common/FormField.vue';
import RadioField from '@/components/common/RadioField.vue';
import { cloneDeep } from 'lodash';
import store from '../../store';
import { showAndCommitError, showError } from '@/utils/Helpers';
import {v4 as uuid} from 'uuid';
import AttributeUtil from '@/utils/attributeUtil';

@Component({
  components: {Dropdown, OnpointModal, FormField, RadioField, NestedAttribute}
})
export default class NestedAttribute extends Vue {
  private static assignAttributeProps(toAttribute: IAttribute, fromAttribute: IAttribute): void {
    toAttribute.attributeType = fromAttribute.attributeType;
    toAttribute.attributeKey = fromAttribute.attributeKey;
    toAttribute.dataType = fromAttribute.dataType;
    toAttribute.direction = fromAttribute.direction;
    toAttribute.name = fromAttribute.name;
    toAttribute.notes = fromAttribute.notes;
    if (toAttribute.attributeType === 'DYNAMIC') {
      toAttribute.tagName = fromAttribute.tagName;
      toAttribute.value = undefined;
    } else {
      toAttribute.value = fromAttribute.value;
      toAttribute.tagName = undefined;
    }
    toAttribute.attributes = cloneDeep(fromAttribute.attributes);
  }

  @Prop({required: true})
  private nestedAttribute: IAttribute;
  @Prop({required: true})
  private level: number;
  @Prop({required: false})
  private zoneKey?: string;
  @Prop({required: false})
  private burnerKey?: string;
  @Prop({required: false})
  private parentAttribute?: IAttribute;
  @Prop({required: false})
  private rootAttributes?: IAttribute[];
  @Prop( {required: true})
  private selection: IBurnerDefinition | IZone;
  @Prop({required: true})
  private isBurner: boolean;
  private isExpanded: boolean = true;
  private showAttributeModal: boolean = false;
  private isEdit: boolean = false;
  private attributeToCreate: IAttribute = {};
  private showDeleteModal: boolean = false;
  private attributeToDelete: IAttribute = {};

  private async createAttribute(): Promise<void> {
    const validationError = this.dataTypeValidationError;
    if (validationError) { return showError(validationError); }
    if (!this.attributeToCreate.name ||
      !this.attributeToCreate.attributeType ||
      !this.attributeToCreate.dataType ||
      !this.attributeToCreate.direction ||
      (!this.attributeToCreate.value && this.attributeToCreate.attributeType !== 'DYNAMIC')) { return showError('Enter a value for all fields.'); }

    if (this.isEdit) {
      const tempAttribute = {...this.nestedAttribute};
      NestedAttribute.assignAttributeProps(this.nestedAttribute, this.attributeToCreate);
      this.updateSelection();

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.closeAttributeModal();
      } catch (error) {
        showAndCommitError(error);
        NestedAttribute.assignAttributeProps(this.nestedAttribute, tempAttribute);
      }
      this.updateSelection();

    } else {
      if (!this.nestedAttribute.attributes) {
        this.nestedAttribute.attributes = [];
      }
      this.attributeToCreate.attributeKey = uuid();
      this.nestedAttribute.attributes.push({...this.attributeToCreate});
      this.updateSelection();

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.closeAttributeModal();
      } catch (error) {
        showAndCommitError(error);
        this.nestedAttribute.attributes.pop();
      }
      this.updateSelection();
    }
  }

  private closeAttributeModal(): void {
    this.showAttributeModal = false;
    this.attributeToCreate = {};
    if (this.isEdit) {
      this.isEdit = false;
    }
  }

  private editAttribute(attribute: IAttribute): void {
    this.isEdit = true;
    this.showAttributeModal = true;
    this.attributeToCreate = {...attribute};
  }

  private openDelete(attribute: IAttribute): void {
    this.attributeToDelete = attribute;
    this.showDeleteModal = true;
  }

  private closeDelete(): void {
    this.showDeleteModal = false;
  }

  private async deleteAttribute(): Promise<void> {
    if (this.level > 1) {
      this.parentAttribute.attributes.splice(this.parentAttribute.attributes.indexOf(this.nestedAttribute), 1);
      this.updateSelection();
      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.closeDelete();
      } catch (error) {
        showAndCommitError(error);
        this.parentAttribute.attributes.push(this.nestedAttribute);
      }
      this.updateSelection();
      this.$parent.$forceUpdate();
    } else {
      this.rootAttributes.splice(this.rootAttributes.indexOf(this.nestedAttribute), 1);
      this.updateSelection();
      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.closeDelete();
      } catch (error) {
        showAndCommitError(error);
        this.rootAttributes.push(this.nestedAttribute);
      }
      this.updateSelection();
    }
  }

  private updateSelection(): void {
    if (this.isBurner) {
      store.commit('hierarchyState/updateBurner', {
        burnerKey: (this.selection as IBurnerDefinition).burnerKey,
        zoneKey: (this.selection as IBurnerDefinition).zoneKey,
        burner: this.selection as IBurnerDefinition
      });
    } else {
      store.commit('hierarchyState/updateZone', {
        zoneKey: (this.selection as IZone).zoneKey,
        zone: this.selection as IZone
      });
    }
  }

  private get dataTypeValidationError(): string {
    return AttributeUtil.dataTypeValidationError(this.attributeToCreate);
  }
}
