// attribute utilities
import moment from 'moment';

export default class DateUtil {
  // translate to local date.
  public static translateDate(dateString: string): string {
    if (dateString) {
      return moment(dateString).format('MM/DD/YY');
    } else {
      return '';
    }
  }
}
