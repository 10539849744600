
























































import { Component, PropSync, Prop, Vue } from 'vue-property-decorator';
import { ITreeItem, IWalkingOrderTreeItem, IWalkingOrder } from '@/view-models/hierarchy-view-models';
import FormField from '@/components/common/FormField.vue';
import NoBurnersMessage from '@/components/common/NoBurnersMessage.vue';
import AssetTree from '@/components/common/AssetTree.vue';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import { hbEventBus } from '@/eventBus/hierarchy-event-bus';
import store from '@/store';
import EquipmentList from '@/components/walkOrder/EquipmentList.vue';
import { showAndCommitError, showError } from '@/utils/Helpers';

@Component({
  components: { FormField, AssetTree, NoBurnersMessage, EquipmentList },
})
export default class CreateWalkingOrder extends Vue {
  @PropSync('close', { required: true })
  private isOpen: boolean;
  private assetName = store.state.hierarchyState.hierarchy.assetName;
  @Prop({ required: true })
  private walkOrder: IWalkingOrder;
  private creatingOrder: boolean = false;
  private tempOrder: IWalkingOrder = {
    status: '',
    name: '',
    walkingOrderKey: '',
    burnerOrder: [],
  };

  private get burnersWithDetails(): number {
    let burnersWithDetails = 0;
    const zones = store.state.hierarchyState.hierarchy.zones;
    for (const zone of zones) {
      const burners = zone.burnerDefinitions;
      if (burners && burners.length) {
        for (const burner of burners) {
          if (burner.burnerName) {
            burnersWithDetails++;
          }
        }
      }
    }
    return burnersWithDetails;
  }

  private get hasUnassignedBurners(): boolean {
    return store.getters['hierarchyState/newlyDefinedBurnersExist'](
      this.walkOrder
    );
  }

  private get existingOrder(): IWalkingOrder {
    return store.state.hierarchyState.hierarchy.walkingOrders.find(
      (item) => item.walkingOrderKey === this.walkOrder.walkingOrderKey
    );
  }

  private get currentPage(): string {
    return this.$route?.name;
  }

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/walkingOrderTreeItems']();
  }

  private handleSelection(item: IWalkingOrderTreeItem): void {
    if (this.walkOrder.name !== '') {
      item.walkingOrderName = this.walkOrder.name;
      store.dispatch('hierarchyState/generateWalkingOrder', item);
    }
  }

  private handleSelectionUndo(item: IWalkingOrderTreeItem): void {
    item.walkingOrderName = this.walkOrder.name;
    store.commit('hierarchyState/undoWalkingOrder', item);
  }

  private mounted(): void {
    hbEventBus.$on(
      TreeActionTypeEnums.LeafSelectedUndo,
      (item: IWalkingOrderTreeItem) => {
        this.handleSelectionUndo(item);
      }
    );
    if (this.existingOrder) {
      this.tempOrder = { ...this.walkOrder };
    }
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.ItemOpen);
    hbEventBus.$off(TreeActionTypeEnums.LeafSelectedUndo);
  }

  private addUnassignedBurners(): void {
    store.commit('hierarchyState/addNewlyDefinedBurners', this.walkOrder);
    hbEventBus.$emit('ALL_BURNERS_ADDED');
  }

  private async saveWalkOrder(): Promise<void> {
    if (!this.walkOrder.name) {
      showError('Missing value for walking order name.');
      return;
    }
    if (this.burnersWithDetails === this.walkOrder.burnerOrder?.length) {
      this.walkOrder.status = 'Complete';
    }
    if (!this.existingOrder) {
      this.creatingOrder = true;
      store.state.hierarchyState.hierarchy.walkingOrders.push(this.walkOrder);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.isOpen = false;
        this.creatingOrder = false;
      } catch (error) {
        showAndCommitError(error);
        store.state.hierarchyState.hierarchy.walkingOrders.pop();
        this.creatingOrder = false;
      }
    } else {
      const tempOrder = { ...this.existingOrder };
      store.commit('hierarchyState/updateWalkingOrder', this.walkOrder);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.isOpen = false;
      } catch (error) {
        showAndCommitError(error);
        store.commit('hierarchyState/updateWalkingOrder', tempOrder);
      }
    }
  }
}
