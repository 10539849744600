























import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/common/Loading.vue';
import { BvTableFieldArray } from 'bootstrap-vue';

@Component({
  name: 'data-mapping',
  components: {
    Loading
  }
})
export default class DataMapping extends Vue {
  private get dataMappingFields(): BvTableFieldArray {
    return [
      { label: 'Property', key: 'property', sortable: true, thStyle: { width: '16%' } },
      { label: 'Asset Name', key: 'assetName', sortable: true, thStyle: { width: '16%' } },
      { label: 'Data Source', key: 'dataSource', sortable: true, thStyle: { width: '16%' } },
      { label: 'Type', key: 'type', sortable: true, thStyle: { width: '16%' } },
      { label: 'Value', key: 'value', sortable: true, thStyle: { width: '16%' } },
      { label: 'Data Identifier', key: 'dataIdentifier', sortable: false, thStyle: { width: '20%' } }
    ];
  }

  private get mockItems(): object[] {
    return [
      {
        property: 'Property 1',
        assetName: 'Mock Asset',
        dataSource: 'Static',
        type: 'String',
        value: 'some text',
        dataIdentifier: 'c8ef8599-f02b-4378-b3c7-b51b4e84e6ba'
      },
      {
        property: 'Property 2',
        assetName: 'Mock Asset',
        dataSource: 'Formula',
        type: 'Integer',
        value: '123',
        dataIdentifier: '0c6ef622-687e-4f80-ae46-abc18f591623'
      },
      {
        property: 'Property 3',
        assetName: 'Mock Asset',
        dataSource: 'List',
        type: 'String',
        value: '?????',
        dataIdentifier: '1c2f5032-eae0-4218-9288-64f76d0e5a76'
      }
    ];
  }
}
