import { IFormulaFunction, IFormulaOperand } from '@/view-models/hierarchy-view-models';
import { IParserError } from '@/view-models/parser-error';

export abstract class HierarchyFileRecord {
  public abstract get lineNumber(): number;
  public abstract get id(): string;
  public abstract get parent(): string;
  public abstract get name(): string;
  public abstract get path(): string;
  public abstract get opportunityScoreType(): string;
  public abstract get opportunityPriority(): number;
  public abstract get lowerTolerance(): number;
  public abstract get upperTolerance(): number;
  public abstract get burnerTypeKey(): string;
  public abstract get attributeDirection(): string;
  public abstract get attributeDataType(): string;
  public abstract get attributeType(): string;
  public abstract get attributeValue(): string;
  public abstract get attributeTagName(): string;
  public abstract get notes(): string;
  public abstract get formulaRaw(): string;
  public abstract get formulaOperands(): IFormulaOperand[];
  public abstract get formulaSupportedFns(): IFormulaFunction[];

  public abstract isAttribute(): boolean;
  public abstract isBurner(): boolean;
  public abstract isZone(): boolean;
  public abstract isRootZone(): boolean;
  public abstract validateFields(errors: IParserError[]): void;
}
