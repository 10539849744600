import axios from 'axios';
import ConfigFactory from './config-factory';
import authInterceptor from './auth-interceptor';

const sharedHbAxiosInstance = axios.create();
sharedHbAxiosInstance.interceptors.request.use(authInterceptor);

const sharedKesAxiosInstance = axios.create();
sharedKesAxiosInstance.interceptors.request.use(authInterceptor);

const sharedCAEAxiosInstance = axios.create();
sharedCAEAxiosInstance.interceptors.request.use(authInterceptor);

const sharedPCAxiosInstance = axios.create();
sharedPCAxiosInstance.interceptors.request.use(authInterceptor);

const sharedUmAxiosInstance = axios.create();
sharedUmAxiosInstance.interceptors.request.use(authInterceptor);

export default {
  sharedHbAxiosInstance,
  sharedKesAxiosInstance,
  sharedCAEAxiosInstance,
  sharedPCAxiosInstance,
  sharedUmAxiosInstance
};

export async function getUmApiUrl(): Promise<string> {
  const conf = await ConfigFactory.GetConfig();
  return process.env.VUE_APP_UM_API_URL ? process.env.VUE_APP_UM_API_URL : conf.get('umApiUrl');
}
