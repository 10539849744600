import { IZone, IAttribute, IBurnerDefinition, IHierarchyDefiniton } from '@/view-models/hierarchy-view-models';
import { IAsset } from '@/view-models/assets-view-models';
import {getZoneNamesFromRootToChild} from '@/utils/StoreHelpers';

enum OBJECT_TYPES {
  ZONE = 'Zone',
  ATTRIBUTE = 'Attribute',
  BURNER = 'Burner',
}

// note when changing this make sure to add data type to
// IColumn.
const COLUMNS = [
  'Parent',
  'ID',
  'Name',
  'ObjectType',
  'OpportunityScoreType',
  'OpportunityScoreMultiplier',
  'LowerTolerance',
  'UpperTolerance',
  'BurnerTypeID',
  'AttributeDirection',
  'AttributeType',
  'AttributeDataType',
  'AttributeValue',
  'AttributeTagName',
  'Notes',
];

export interface IColumn {
  Parent: string;
  ID: string;
  Name: string;
  ObjectType: string;
  OpportunityScoreType: string;
  OpportunityScoreMultiplier: string;
  LowerTolerance: string;
  UpperTolerance: string;
  BurnerTypeID: string;
  AttributeDirection: string;
  AttributeType: string;
  AttributeDataType: string;
  AttributeValue: number | string;
  AttributeTagName: string;
  Notes: string;
}

const BURNER_TYPE_COLUMNS = [
  'BurnerTypeName',
  'BurnerTypeID',
  'BurnerZoneType',
];

let fileOutput = '';

export function exportHierarchy(hierarchy: IHierarchyDefiniton): string {
  // Clear out fileOutput and set to just the header row.
  fileOutput = COLUMNS.join(',') + '\n';
  // For each zone...
  hierarchy.zones.forEach((zone) => {
    // Get array of zone names from the root node to the current zone and join with '/'.
    const zonePath = getZoneNamesFromRootToChild(zone.zoneKey)
      .reverse().join('/').replace(hierarchy.assetName, 'root');
    // Parent is 'root' when this is the root zone.
    if (!zone.zoneParentKey) {
      addRowToOutput(getZoneRow(zone, ''));
    } else {
      // Remove current zone name from parent path
      addRowToOutput(getZoneRow(zone, zonePath.replace('/' + zone.zoneName, '')));
    }
    // For each zone attribute...
    if (zone.attributes) {
      zone.attributes.forEach((attribute) => {
        exportAttributesRecursively(attribute, zonePath);
      });
    }
    // For each zone burner...
    if (zone.burnerDefinitions) {
      zone.burnerDefinitions.forEach((burner) => {
        const burnerPath = zonePath + '/' + burner.burnerName;
        addRowToOutput(getBurnerRow(burner, zonePath));
        // For each burner attribute
        if (burner.attributes) {
          burner.attributes.forEach((attribute) => {
            exportAttributesRecursively(attribute, burnerPath);
          });
        }
      });
    }
  });
  return fileOutput;
}

function exportAttributesRecursively(attribute: IAttribute, currentPath: string): void {
  const attributePath = currentPath + '/' + attribute.name;
  addRowToOutput(getAttributeRow(attribute, currentPath));
  // For each nested attribute at the current level...
  if (attribute.attributes) {
    attribute.attributes.forEach((nestedAttribute) => {
      exportAttributesRecursively(nestedAttribute, attributePath);
    });
  }
}

function getZoneRow(zone: IZone, path: string): string {
  let zoneRow = '';
  // Determine if this is the root row by checking if the Path is empty.
  if (path) {
    zoneRow = zoneRow + getAsCell(path) + ','; // Parent
    zoneRow = zoneRow + getAsCell(zone.zoneKey) + ','; // ID
    zoneRow = zoneRow + getAsCell(zone.zoneName) + ','; // Name
  } else {
    zoneRow = zoneRow + ',,root,'; // No Parent or ID when root row
  }
  zoneRow = zoneRow + getAsCell(OBJECT_TYPES.ZONE) + ','; // ObjectType
  zoneRow = zoneRow + getAsCell(
    (zone.opportunityScoreType ? zone.opportunityScoreType : '')
  ) + ','; // OpportunityScoreType
  zoneRow = zoneRow + getAsCell(
    (zone.opportunityPriority || zone.opportunityPriority === 0 ? zone.opportunityPriority : '').toString()
  ) + ','; // OpportunityScoreMultiplier
  zoneRow = zoneRow + getAsCell(
    (zone.lowerTolerance || zone.lowerTolerance === 0 ? zone.lowerTolerance : '').toString()
  ) + ','; // LowerTolerance
  zoneRow = zoneRow + getAsCell(
    (zone.upperTolerance || zone.upperTolerance === 0 ? zone.upperTolerance : '').toString()
  ) + ',,,,,,,'; // UpperTolerance
  // Other fields blank...
  zoneRow = zoneRow + getAsCell((zone.notes ? zone.notes : '')); // Notes
  return zoneRow;
}

function getBurnerRow(burner: IBurnerDefinition, path: string): string {
  let burnerRow = getAsCell(path) + ','; // Parent
  burnerRow = burnerRow + getAsCell(burner.burnerKey) + ','; // ID
  burnerRow = burnerRow + getAsCell(burner.burnerName) + ','; // Name
  burnerRow = burnerRow + getAsCell(OBJECT_TYPES.BURNER) + ','; // ObjectType
  burnerRow = burnerRow + ','; // OpportunityScoreType
  burnerRow = burnerRow + getAsCell(
    (burner.opportunityPriority || burner.opportunityPriority === 0 ? burner.opportunityPriority : '').toString()
  ) + ','; // OpportunityScoreMultiplier
  burnerRow = burnerRow + getAsCell(
    (burner.lowerTolerance || burner.lowerTolerance === 0 ? burner.lowerTolerance : '').toString()
  ) + ','; // LowerTolerance
  burnerRow = burnerRow + getAsCell(
    (burner.upperTolerance || burner.upperTolerance === 0 ? burner.upperTolerance : '').toString()
  ) + ','; // UpperTolerance
  burnerRow = burnerRow + getAsCell(burner.burnerTypeKey) + ',,,,,,'; // BurnerTypeID
  burnerRow = burnerRow + getAsCell((burner.notes ? burner.notes : '')); // Notes
  // Other fields blank...
  return burnerRow;
}

function getAttributeRow(attribute: IAttribute, path: string): string {
  let attributeRow = getAsCell(path) + ','; // Parent
  attributeRow = attributeRow + getAsCell(attribute.attributeKey) + ','; // ID
  attributeRow = attributeRow + getAsCell(attribute.name) + ','; // Name
  attributeRow = attributeRow + getAsCell(OBJECT_TYPES.ATTRIBUTE) + ','; // ObjectType
  attributeRow = attributeRow + ','; // OpportunityScoreType
  attributeRow = attributeRow + ','; // OpportunityScoreMultiplier
  attributeRow = attributeRow + ','; // LowerTolerance
  attributeRow = attributeRow + ','; // UpperTolerance
  attributeRow = attributeRow + ','; // BurnerTypeID
  attributeRow = attributeRow +
    getAsCell((attribute.direction ? attribute.direction : '')) + ','; // AttributeDirection
  attributeRow = attributeRow + getAsCell(attribute.attributeType) + ','; // AttributeType
  attributeRow = attributeRow + getAsCell((attribute.dataType ? attribute.dataType : '')) + ','; // AttributeDataType
  attributeRow = attributeRow +
    getAsCell((attribute.value || attribute.value === '0' ? attribute.value : '')) + ','; // AttributeValue
  attributeRow = attributeRow + getAsCell((attribute.tagName ? attribute.tagName : '')) + ','; // AttributeTagName
  attributeRow = attributeRow + getAsCell((attribute.notes ? attribute.notes : '')); // Notes
  return attributeRow;
}

function addRowToOutput(rowText: string): void {
  fileOutput = fileOutput + rowText + '\n';
}

function getAsCell(cellText?: string): string {
  if (!cellText) {
    return '';
  }
  let parsedCellText = cellText.replace(/"/g, '""');
  if (cellText.search(/([",\n])/g) >= 0) {
    parsedCellText = `"${parsedCellText}"`;
  }
  return parsedCellText;
}

export function exportBurnerTypes(asset: IAsset): string {
  fileOutput = BURNER_TYPE_COLUMNS.join(',') + '\n';
  if (asset.burnerList) {
    asset.burnerList.forEach((burnerType) => {
      let burnerTypeRow = burnerType.burnerName + ',';
      burnerTypeRow = burnerTypeRow + burnerType.burnerKey + ',';
      burnerTypeRow = burnerTypeRow + burnerType.burnerDetail.zoneType;
      addRowToOutput(burnerTypeRow);
    });
  }
  return fileOutput;
}
