import store from '@/store';
import { IAsset } from '@/view-models/assets-view-models';
import { IFormulaFunction, IFormulaOperand } from '@/view-models/hierarchy-view-models';
import { ROOT_ZONE_KEY } from '@/components/hierarchy/modals/hierarchy-file';
import { HierarchyFileRecord } from '@/components/hierarchy/modals/hierarchy-file-record';
import { IParserError, ParserError } from '@/view-models/parser-error';
import { ATTRIBUTE_TYPES, ATTRIBUTE_DIRECTIONS, ATTRIBUTE_DATA_TYPES } from '@/enums/hierarchy-builder-types';
import StringUtil from '@/utils/stringUtil';
import HelperMethods from '@/shared/helper-methods';

const ROOT_ZONE_LINE_NUMBER: number = 1;
const DEFAULT_ATTRIBUTE_VALUE: string = 'Default';

enum PI_AF_OBJECT_TYPES {
  ELEMENT = 'Element',
  ATTRIBUTE = 'Attribute'
}

enum PI_AF_CATEGORIES {
  NONE = '',
  COMBUSTION_PRODUCTS = 'Combustion Products',
  JOHNZINK = 'JohnZink',
  ONPOINT_INPUT = 'OnPoint_Input',
  ONPOINT_RESULTS = 'OnPoint_Results'
}

export enum PI_AF_NAME {
  BURNER_KEY = 'BurnerKey',
  BURNER_IDENTIFIER = 'BurnerIdentifier'
}

enum PI_AF_ATTRIBUTE_TYPES {
  NONE = '',
  BOOLEAN = 'Boolean',
  DOUBLE = 'Double',
  STRING = 'String'
}

enum PI_AF_ATTRIBUTE_DATA_REFERENCES {
  NONE = '',
  FORMULA = 'Formula',
  PI_POINT = 'PI Point',
  STRING_BUILDER = 'String Builder'
}

enum PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES {
  // Database-relative (same database) prefixes
  DATABASE_PATH = '\\',

  // Element-relative (different element) prefixes
  CHILD_ELEMENT = '.\\',
  PARENT_ELEMENT = '..\\',

  // Attribute-relative (same element) prefixes
  CHILD_ATTRIBUTE = '.|',
  SIBLING_ATTRIBUTE = '..|',
  PARENT_ATTRIBUTE = '..',
  TOP_LEVEL_ATTRIBUTE = '|'
}

export enum COLUMNS {
  SELECTED = 'Selected(x)',
  PARENT = 'Parent',
  NAME = 'Name',
  OBJECT_TYPE = 'ObjectType',
  CATEGORIES = 'Categories',
  ATTRIBUTE_TYPE = 'AttributeType',
  ATTRIBUTE_VALUE = 'AttributeValue',
  ATTRIBUTE_DATA_REFERENCE = 'AttributeDataReference',
  ATTRIBUTE_CONFIG_STRING = 'AttributeConfigString'
}

export class PiAfFileRecord extends HierarchyFileRecord {
  private static readonly supportedFns: IFormulaFunction[] = [
    { fn: 'abs', dataMapperFn: 'abs', usage: 'abs(<attribute>)' },
    { fn: 'avg', dataMapperFn: 'avg', usage: 'avg(<attribute1>, ..., <attributeN>)' },
    { fn: 'pow', dataMapperFn: 'exp', usage: 'pow(<attribute>, <constant>)' },
    { fn: 'max', dataMapperFn: 'max', usage: 'max(<attribute1>, ..., <attributeN>)' },
    { fn: 'median', dataMapperFn: 'med', usage: 'median(<attribute1>, ..., <attributeN>)' },
    { fn: 'min', dataMapperFn: 'min', usage: 'min(<attribute1>, ..., <attributeN>)' },
    { fn: 'total', dataMapperFn: 'sum', usage: 'total(<attribute1>, ..., <attributeN>)' },
    { fn: 'if', dataMapperFn: 'if', usage: 'if(<condition(s)>, <trueValue>, <falseValue>)' },
    { fn: 'isnonnumeric', dataMapperFn: 'isnonnumeric', usage: 'isnonnumeric(<attribute1>,<defaultValue>' }
  ];

  public lineNumber: number;
  public id: string = '';
  public burnerTypeKey: string;
  public isObjectZone: boolean = false;
  public isObjectBurner: boolean = false;
  private zone: string;
  private formula: string = '';
  private piAfParent: string;
  private piAfName: string;
  private piAfObjectType: string;
  private piAfCategories: string;
  private piAfAttributeType: string;
  private piAfAttributeValue: string;
  private piAfAttributeDataReference: string;
  private piAfAttributeConfigString: string;
  private piAfRootZonePath: string;
  private operands: IFormulaOperand[] = [];

  constructor(record: any, rootZonePath: string, lineNumber: number) {
    super();
    this.lineNumber                 = lineNumber;
    this.piAfParent                 = record[COLUMNS.PARENT];
    this.piAfName                   = record[COLUMNS.NAME];
    this.piAfObjectType             = record[COLUMNS.OBJECT_TYPE];
    this.piAfCategories             = record[COLUMNS.CATEGORIES];
    this.piAfAttributeType          = record[COLUMNS.ATTRIBUTE_TYPE];
    this.piAfAttributeValue         = record[COLUMNS.ATTRIBUTE_VALUE];
    this.piAfAttributeDataReference = record[COLUMNS.ATTRIBUTE_DATA_REFERENCE];
    this.piAfAttributeConfigString  = record[COLUMNS.ATTRIBUTE_CONFIG_STRING];
    this.piAfRootZonePath           = rootZonePath;
    this.isObjectZone               = this.piAfObjectType === PI_AF_OBJECT_TYPES.ELEMENT;
    this.mapBurnerTypeId();

    // Set the zone
    if (this.isRootZone()) {
      this.zone = '';
    } else {
      const subZone = this.piAfParent.replace(this.piAfRootZonePath, '').replace(/\\/g, '/');
      if (subZone !== '') {
        this.zone = ROOT_ZONE_KEY + subZone;
      } else {
        this.zone = ROOT_ZONE_KEY;
      }
    }
  }

  public get parent(): string {
    if (this.isRootZone()) {
      return '';
    }

    const lastIndex: number = this.piAfName.lastIndexOf('|');
    if (lastIndex > -1) {
      return this.zone + '/' + this.piAfName.slice(0, lastIndex).replace(/\|/g, '/');
    } else {
      return this.zone;
    }
  }

  public get name(): string {
    if (this.isRootZone()) {
      return ROOT_ZONE_KEY;
    }

    const lastIndex: number = this.piAfName.lastIndexOf('|');
    if (lastIndex > -1) {
      return this.piAfName.slice(lastIndex + 1);
    } else {
      return this.piAfName;
    }
  }

  public get path(): string {
    if (this.isRootZone()) {
      return this.name;
    } else {
      return this.parent + '/' + this.name;
    }
  }

  public get opportunityScoreType(): string {
    return '';
  }

  public get opportunityPriority(): number {
    return 1;
  }

  public get lowerTolerance(): number {
    return 0;
  }

  public get upperTolerance(): number {
    return 0;
  }

  public get notes(): string {
    return '';
  }

  public get attributeDirection(): string {
    if (this.piAfObjectType === PI_AF_OBJECT_TYPES.ATTRIBUTE) {
      if (this.piAfCategories.includes(PI_AF_CATEGORIES.ONPOINT_RESULTS)) {
        return ATTRIBUTE_DIRECTIONS.RESULT;
      } else {
        return ATTRIBUTE_DIRECTIONS.INPUT;
      }
    }

    return '';
  }

  public get attributeDataType(): string {
    switch (this.piAfAttributeType) {
      case PI_AF_ATTRIBUTE_TYPES.BOOLEAN:
        return ATTRIBUTE_DATA_TYPES.BOOLEAN;

      case PI_AF_ATTRIBUTE_TYPES.DOUBLE:
        return ATTRIBUTE_DATA_TYPES.NUMERIC;

      case PI_AF_ATTRIBUTE_TYPES.STRING:
        return ATTRIBUTE_DATA_TYPES.STRING;

      default:
        return '';
    }
  }

  public get attributeType(): string {
    switch (this.piAfAttributeDataReference) {
      case PI_AF_ATTRIBUTE_DATA_REFERENCES.PI_POINT:
        return ATTRIBUTE_TYPES.DYNAMIC;

      case PI_AF_ATTRIBUTE_DATA_REFERENCES.FORMULA:
        return ATTRIBUTE_TYPES.FORMULA;

      default:
        return ATTRIBUTE_TYPES.STATIC;
    }
  }

  public get attributeValue(): string {
    switch (this.piAfAttributeDataReference) {
      case PI_AF_ATTRIBUTE_DATA_REFERENCES.FORMULA:
        return this.formula;

      case PI_AF_ATTRIBUTE_DATA_REFERENCES.STRING_BUILDER:
      case PI_AF_ATTRIBUTE_DATA_REFERENCES.NONE:
        return !!this.piAfAttributeValue ? this.piAfAttributeValue : DEFAULT_ATTRIBUTE_VALUE;

      default:
        return '';
    }
  }

  public get attributeTagName(): string {
    if (this.piAfAttributeDataReference === PI_AF_ATTRIBUTE_DATA_REFERENCES.PI_POINT) {
      const lastIndex = this.piAfAttributeConfigString.lastIndexOf('\\');
      if (lastIndex > -1) {
        return (this.piAfAttributeConfigString.slice(lastIndex + 1)).toUpperCase();
      }

      return this.piAfAttributeConfigString.toUpperCase();
    }

    return '';
  }

  public get formulaRaw(): string {
    if (this.piAfAttributeDataReference === PI_AF_ATTRIBUTE_DATA_REFERENCES.FORMULA) {
      return this.piAfAttributeConfigString;
    }

    return '';
  }

  public get formulaOperands(): IFormulaOperand[] {
    return this.operands;
  }

  public get formulaSupportedFns(): IFormulaFunction[] {
    return PiAfFileRecord.supportedFns;
  }

  public isAttribute(): boolean {
    return this.piAfObjectType === PI_AF_OBJECT_TYPES.ATTRIBUTE;
  }

  public isBurner(): boolean {
    return this.isObjectBurner;
  }

  public isZone(): boolean {
    return this.isObjectZone;
  }

  public isRootZone(): boolean {
    return this.isZone() && this.lineNumber === ROOT_ZONE_LINE_NUMBER;
  }

  public validateFields(errors: IParserError[]): void {
    this.validateParent(errors);
    this.validateField(this.piAfName, COLUMNS.NAME, errors);
    this.validateObjectType(errors);
    this.validateCategories(errors);
    this.validateAttribute(errors);
  }

  private validateField(field: any, column: string, errors: IParserError[]): void {
    if (field === null || field === undefined || field === '') {
      errors.push(new ParserError(this.lineNumber, `Missing ${ column }`));
    }
  }

  private validateParent(errors: IParserError[]): void {
    this.validateField(this.piAfParent, COLUMNS.PARENT, errors);
    if (this.lineNumber === ROOT_ZONE_LINE_NUMBER && !this.isRootZone()) {
      errors.push(new ParserError(this.lineNumber, `Missing Parent`));
    }
  }

  private validateObjectType(errors: IParserError[]): void {
    this.validateField(this.piAfObjectType, COLUMNS.OBJECT_TYPE, errors);
    if (!Object.values(PI_AF_OBJECT_TYPES).includes(this.piAfObjectType as PI_AF_OBJECT_TYPES)) {
      errors.push(new ParserError(this.lineNumber,
        `Invalid ObjectType. Valid ObjectTypes are 'Element' and 'Attribute'. ` +
        `Received ${ this.piAfObjectType }`));
    }
  }

  private validateCategories(errors: IParserError[]): void {
    this.piAfCategories.split(';').forEach((category) => {
      if (!Object.values(PI_AF_CATEGORIES).includes(category.trim() as PI_AF_CATEGORIES)) {
        errors.push(new ParserError(this.lineNumber,
          `Invalid Category. Valid Categories are '', 'Combustion Products', 'JohnZink', ` +
          `'OnPoint_Input', and 'OnPoint_Results'. Received ${ category.trim() }`));
      }
    });
  }

  private validateAttributeType(errors: IParserError[]): void {
    if (!Object.values(PI_AF_ATTRIBUTE_TYPES).includes(this.piAfAttributeType as PI_AF_ATTRIBUTE_TYPES)) {
      errors.push(new ParserError(this.lineNumber,
        `Invalid AttributeType. Valid AttributeTypes are '', 'Boolean', 'Double', ` +
        `and 'String'. Received ${ this.piAfAttributeType }`));
    }
  }

  private validateAttributeValue(errors: IParserError[]): void {
    if (this.piAfAttributeDataReference !== PI_AF_ATTRIBUTE_DATA_REFERENCES.NONE &&
        this.piAfAttributeDataReference !== PI_AF_ATTRIBUTE_DATA_REFERENCES.STRING_BUILDER) {
      return;
    }

    const errorMessage: string = `Invalid AttributeValue. AttributeValue must be valid for declared ` +
      `type ${ this.piAfAttributeType }. Received ${ this.piAfAttributeValue }`;

    switch (this.piAfAttributeType) {
      case PI_AF_ATTRIBUTE_TYPES.BOOLEAN:
        this.validateField(this.piAfAttributeValue, COLUMNS.ATTRIBUTE_VALUE, errors);
        if (!StringUtil.isBoolean(this.piAfAttributeValue)) {
          errors.push(new ParserError(this.lineNumber, errorMessage));
        }
        break;

      case PI_AF_ATTRIBUTE_TYPES.DOUBLE:
        this.validateField(this.piAfAttributeValue, COLUMNS.ATTRIBUTE_VALUE, errors);
        try {
          if (isNaN(Number(this.piAfAttributeValue))) {
            errors.push(new ParserError(this.lineNumber, errorMessage));
          }
        } catch (error) {
          errors.push(new ParserError(this.lineNumber, errorMessage));
        }
        break;

      default:
        break;
    }
  }

  private validateAttributeDataReference(errors: IParserError[]): void {
    if (!Object.values(PI_AF_ATTRIBUTE_DATA_REFERENCES)
      .includes(this.piAfAttributeDataReference as PI_AF_ATTRIBUTE_DATA_REFERENCES)) {
      errors.push(new ParserError(this.lineNumber,
        `Invalid AttributeDataReference. Valid AttributeDataReferences are '', 'Formula', ` +
        `'PI Point', and 'String Builder'. Received ${ this.piAfAttributeDataReference }`));
    }
  }

  private validateAttributeConfigString(errors: IParserError[]): void {
    switch (this.piAfAttributeDataReference) {
      case PI_AF_ATTRIBUTE_DATA_REFERENCES.PI_POINT:
        this.validateField(this.piAfAttributeConfigString, COLUMNS.ATTRIBUTE_CONFIG_STRING, errors);
        break;

      case PI_AF_ATTRIBUTE_DATA_REFERENCES.FORMULA:
        this.validateFormula(errors);
        break;

      default:
        break;
    }
  }

  private validateAttribute(errors: IParserError[]): void {
    if (this.piAfObjectType !== PI_AF_OBJECT_TYPES.ATTRIBUTE) {
      return;
    }

    this.validateAttributeType(errors);
    this.validateAttributeValue(errors);
    this.validateAttributeConfigString(errors);
    this.validateAttributeDataReference(errors);
  }

  private validateFormula(errors: IParserError[]): void {
    this.validateField(this.piAfAttributeConfigString, COLUMNS.ATTRIBUTE_CONFIG_STRING, errors);
    try {
      this.convertFormula(errors);
    } catch (error) {
      errors.push(new ParserError(this.lineNumber, `Unable to parse formula '${ this.piAfAttributeConfigString }'`));
    }
  }

  private convertOperand(piAfElement: string, piAfAttribute: string, operand: string, depth: number = 0): string {
    let prefix: string = '';

    if (this.startsWithDatabasePrefix(operand)) {
      const ret = this.processDatabasePrefix(piAfElement, piAfAttribute, operand);
      prefix = ret.prefix;
      piAfElement = ret.piAfElement;
      piAfAttribute = ret.piAfAttribute;
    } else if (this.startsWithElementPrefix(operand)) {
      const ret = this.processElementPrefix(piAfElement, piAfAttribute, operand);
      prefix = ret.prefix;
      piAfElement = ret.piAfElement;
      piAfAttribute = ret.piAfAttribute;
    } else if (this.startsWithAttributePrefix(operand) || depth === 0) {
      const ret = this.processAttributePrefix(piAfAttribute, operand, depth);
      prefix = ret.prefix;
      piAfAttribute = ret.piAfAttribute;
    } else {
      let piAfPath: string;
      if (piAfAttribute !== '') {
        piAfPath = `"${piAfElement}|${piAfAttribute}${operand}"`;
      } else if (piAfElement.endsWith('\\')) {
        piAfPath = `"${piAfElement}${operand}"`;
      } else if (piAfElement !== '') {
        piAfPath = `"${piAfElement}|${operand}"`;
      } else {
        piAfPath = `"${operand}"`;
      }

      return piAfPath.replace(this.piAfRootZonePath, ROOT_ZONE_KEY)
                     .replace(/[\\\|]/g, '/');
    }

    return this.convertOperand(piAfElement, piAfAttribute, operand.substring(prefix.length), depth + 1);
  }

  private startsWithDatabasePrefix(operand: string): boolean {
    return operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.DATABASE_PATH);
  }

  private startsWithElementPrefix(operand: string): boolean {
    return (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.CHILD_ELEMENT) ||
            operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.PARENT_ELEMENT));
  }

  private startsWithAttributePrefix(operand: string): boolean {
    return (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.CHILD_ATTRIBUTE) ||
            operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.SIBLING_ATTRIBUTE) ||
            operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.PARENT_ATTRIBUTE) ||
            operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.TOP_LEVEL_ATTRIBUTE));
  }

  private processDatabasePrefix(piAfElement: string,
                                piAfAttribute: string,
                                operand: string): {prefix: string, piAfElement: string, piAfAttribute: string} {
    let prefix: string = '';

    if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.DATABASE_PATH)) {
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.DATABASE_PATH;
      piAfElement = '';
      piAfAttribute = '';
    }

    return {prefix, piAfElement, piAfAttribute};
  }

  private processElementPrefix(piAfElement: string,
                               piAfAttribute: string,
                               operand: string): {prefix: string, piAfElement: string, piAfAttribute: string} {
    let prefix: string = '';

    if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.CHILD_ELEMENT)) {
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.CHILD_ELEMENT;
      piAfElement += '\\';
      piAfAttribute = '';
    } else if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.PARENT_ELEMENT)) {
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.PARENT_ELEMENT;
      piAfElement = piAfElement.substring(0, piAfElement.lastIndexOf('\\')); // Traverse up one element
      piAfAttribute = '';
    }

    return {prefix, piAfElement, piAfAttribute};
  }

  private processAttributePrefix(piAfAttribute: string,
                                 operand: string,
                                 depth: number): {prefix: string, piAfAttribute: string} {
    let prefix: string = '';

    if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.CHILD_ATTRIBUTE)) {
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.CHILD_ATTRIBUTE;
      piAfAttribute += '|';
    } else if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.SIBLING_ATTRIBUTE)) {
      const index = piAfAttribute.lastIndexOf('|', piAfAttribute.length - 2); // Get last index of '|',
                                                                              // ignoring the last character
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.SIBLING_ATTRIBUTE;
      piAfAttribute = piAfAttribute.substring(0, index + 1); // Traverse up one attribute
    } else if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.PARENT_ATTRIBUTE)) {
      const index = piAfAttribute.lastIndexOf('|', piAfAttribute.length - 2); // Get last index of '|',
                                                                              // ignoring the last character
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.PARENT_ATTRIBUTE;
      piAfAttribute = piAfAttribute.substring(0, index); // Traverse up one attribute
    } else if (operand.startsWith(PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.TOP_LEVEL_ATTRIBUTE)) {
      prefix = PI_AF_ATTRIBUTE_DATA_REFERENCE_PREFIXES.TOP_LEVEL_ATTRIBUTE;
      piAfAttribute = ''; // Traverse to the top
    } else if (depth === 0) { // Alternate sibling case
      piAfAttribute = piAfAttribute.substring(0, piAfAttribute.lastIndexOf('|') + 1); // Traverse up one attribute
    }

    return {prefix, piAfAttribute};
  }

  private convertFormula(errors: IParserError[]): void {
    let match: RegExpExecArray | null;

    // Split the PI AF formula into components, separated by ";"
    // The first components are the operands, and the final component is the formula
    // e.g. A=Cell 1|ZoneO2;B=Cell 2|ZoneO2;C=Cell 3|ZoneO2;[(A+B+C)/3]
    const components = this.piAfAttributeConfigString.split(';');

    // Convert the operands
    for (let i = 0; i < components.length - 1; i++) {
      match = /^(?<variable>[A-Z])=(?<attribute>.+)$/.exec(components[i]);
      if (match && match.groups && 'variable' in match.groups && 'attribute' in match.groups) {
        this.operands.push({
          path: this.convertOperand(this.piAfParent, this.piAfName, match.groups.attribute),
          rawPath: match.groups.attribute,
          variable: match.groups.variable
        });
      } else {
        errors.push(new ParserError(this.lineNumber,
          `Unable to parse operand '${ components[i] }' in ` +
          `formula '${ this.piAfAttributeConfigString }'`));
      }
    }

    // Format the formula
    let formula = components[components.length - 1];
    formula = formula.replace(/[\[\]]/g, ''); // Remove square brackets
    // Replace functions with the Data Mapper equivalents
    PiAfFileRecord.supportedFns.forEach((fn: IFormulaFunction) => {
      formula = formula.replace(new RegExp(fn.fn, 'gi'), fn.dataMapperFn);
    });

    // Identify the variables in the formula
    const variableRegExp = /(?<variable>[A-Z])(\W|$)/g;
    const variables: number[] = [];
    while ((match = variableRegExp.exec(formula)) !== null) {
      if (match.groups && 'variable' in match.groups) {
        variables.push(match.index);
      }
    }

    // Replace the variables in the formula with the operands
    // Start from the end of the formula so as to not corrupt the indexes of variables
    variables.reverse().forEach((index) => {
      const variable = formula.charAt(index);
      const operand = this.operands.find((oper) => oper.variable === variable);
      if (operand) {
        formula = formula.slice(0, index) +
          operand.path +
          formula.slice(index + 1);
      } else {
        errors.push(new ParserError(this.lineNumber,
          `Variable '${ variable }' is undefined in ` +
          `formula '${ this.piAfAttributeConfigString }'`));
      }
    });

    this.formula = formula;
  }

  private mapBurnerTypeId(): void {
    const piAfBurnerTypeKeys = store.state.hierarchyState.piAfBurnerTypeKeys;
    const hierarchy = store.state.hierarchyState.hierarchy;
    const assets = store.state.hierarchyState.assets;
    const asset = assets.find((a: IAsset) => a.key === hierarchy.assetKey && !HelperMethods.isArrayEmpty(a.burnerList));

    if (this.piAfName === PI_AF_NAME.BURNER_KEY) {
      piAfBurnerTypeKeys.set(this.piAfParent, this.piAfAttributeValue);
      this.burnerTypeKey = '';
    }
    if (this.isBurner() && !HelperMethods.isNullOrUndefined(piAfBurnerTypeKeys)) {
      let ancestorIndex = this.piAfParent.length;
      let ancestorPath = this.piAfParent;
      do {
        ancestorPath = this.piAfParent.substring(0, ancestorIndex);
        const ancestorBurnerKey = piAfBurnerTypeKeys.get(ancestorPath);
        if (!HelperMethods.isStringEmpty(ancestorBurnerKey)) {
          this.burnerTypeKey = ancestorBurnerKey;
          return;
        }
        ancestorIndex = this.piAfParent.lastIndexOf('//');
      } while (ancestorIndex >= 0);
    }
    this.burnerTypeKey = asset ? asset.burnerList[0].burnerKey : '';
  }
}
