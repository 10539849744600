




















































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ITreeItem,
  ITreeLiaison,
  IEmitBurnerCountObject,
  IBurnerDefinition,
  IZone,
  IEmitObject,
  IUpdateZoneDetails
} from '@/view-models/hierarchy-view-models';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import store from '@/store';
import { hbEventBus } from '@/eventBus/hierarchy-event-bus';
import { cloneDeep } from 'lodash';
import { showAndCommitError, showError } from '@/utils/Helpers';
import { findZone } from '@/utils/StoreHelpers';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'asset-tree',
  components: {
    Dropdown: () => import('../common/Dropdown.vue'),
    FormField: () => import('@/components/common/FormField.vue'),
    OnpointModal: () => import('@/components/common/OnpointModal.vue')
  }
})
export default class AssetTree extends Vue {
  @Prop({ required: true })
  private item: ITreeItem;
  @Prop({ required: true })
  private currentPage: string;
  @Prop({ required: false, default: false })
  private rootOnly: string;
  private store = store;
  private burnerCount: number = this.item && this.item.burnerCount ? this.item.burnerCount : 0;
  private zoneName: string = this.item && this.item.name ? this.item.name : '';
  private opportunityPriority: number = this.getRefreshedOpportunityPriority();
  private opportunityScoreType: string = this.getRefreshedOpportunityScoreType();
  private lowerTolerance: number = this.getRefreshedLowerTolerance();
  private upperTolerance: number = this.getRefreshedUpperTolerance();
  private notes: string = this.getRefreshedNotes();
  private showDetailsModal: boolean = false;
  private showDeleteModal: boolean = false;
  private showDeleteBurnerModal: boolean = false;
  private showDeleteConfigModal: boolean = false;
  private editBurners: boolean = false;
  private canUndo: boolean = false;
  private subzoneNameInput: string = this.item && this.item.name ? this.item.name : '';
  private clientZoneHeight = 0;

  get hasChildren(): boolean {
    return this.childrenItems(this.item).length > 0;
  }

  private get testCurrentPage(): string {
    return this.$route?.name;
  }

  private childrenItems(parentItem: ITreeItem): ITreeItem[] {
    let children: ITreeItem[] = [];
    switch (this.currentPage) {
      case 'walking-order':
        children = store.getters['hierarchyState/walkingOrderTreeItems']().filter(
          (item: ITreeItem) => item.name !== ''
        );
        break;
      case 'attributes':
        children = store.getters['hierarchyState/attributesTreeItems']().filter((item: ITreeItem) => item.name);
        break;
      default:
        children = store.getters['hierarchyState/treeItems']();
        break;
    }
    return children
      .filter((item: ITreeItem) => item.parentKey === parentItem.key)
      .sort((a: ITreeItem, b: ITreeItem) => {
        return HelperMethods.sortString(a.name, b.name);
      });
  }

  get selectedTreeItem(): boolean {
    if (this.currentPage === 'burners' || this.currentPage === 'attributes') {
      return store.state.hierarchyState.selectedTreeItem === this.item;
    } else {
      return false;
    }
  }

  private get itemIsVisible(): boolean {
    if (HelperMethods.isNullOrUndefined(this.item)) {
      return false;
    }
    if (this.item.type === 'Zone' && this.currentPage === 'walking-order') {
      return this.childrenAreNotEmpty(this.item);
    }
    return true;
  }

  private childrenAreNotEmpty(item: ITreeItem): boolean {
    if (item.type === 'Leaf') {
      return true;
    }
    const children = this.childrenItems(item);
    if (HelperMethods.isArrayEmpty(children)) {
      return false;
    }
    return children.some((child) => this.childrenAreNotEmpty(child));
  }

  private definedBurners(item: ITreeItem): string {
    if (this.currentPage === 'walking-order') {
      const zone = findZone(item.associatedZoneKey || '');
      const defined = zone.burnerDefinitions.filter((def) => def.burnerName);
      const definedCount = defined.length;
      return `${definedCount}`;
    } else {
      const zone = findZone(item.associatedZoneKey || '');
      const typeMatch = zone.burnerDefinitions.filter((def) => def.burnerTypeKey === item.burnerTypeKey);
      const totalCount = typeMatch.length;
      const defined = typeMatch.filter((def) => def.burnerName);
      const definedCount = defined.length;
      return `${definedCount}/${totalCount}`;
    }
  }

  get isOpen(): boolean {
    return store.state.hierarchyState.openZones.includes(this.item.key);
  }

  private get fullZoneData(): IZone {
    return store.state.hierarchyState.hierarchy.zones.find((zone: IZone): boolean => {
      if (this.item) {
        return zone.zoneKey === this.item.associatedZoneKey || false;
      }
    });
  }

  private get detailsAreCopied(): boolean {
    if (
      store.state.hierarchyState.copiedZoneDetails &&
      store.state.hierarchyState.copiedZoneDetails.opportunityPriority
    ) {
      return store.state.hierarchyState.copiedZoneDetails.opportunityPriority > 0;
    }
  }

  private get attributesAreCopied(): boolean {
    return store.state.hierarchyState.copiedAttributes !== undefined;
  }

  private get allBurnersAreAssigned(): boolean {
    const allBurnersAssignedToWalkingOrder =
      store.getters['hierarchyState/allDefinedBurnersForZoneAreAssignedToWalkingOrder'];
    return allBurnersAssignedToWalkingOrder(this.item.associatedZoneKey);
  }

  private get countOfBurnersInWalkingOrderForZone(): number {
    if (store.state.hierarchyState.selectedWalkingOrder) {
      return store.getters['hierarchyState/countOfDefinedBurnersForZoneInWalkingOrder'](this.item.associatedZoneKey);
    }
  }

  private mounted(): void {
    hbEventBus.$on(TreeActionTypeEnums.RenameZone, (item: ITreeItem) => {
      if (item && item.key === this.item.key) {
        this.subzoneNameInput = item.name ? item.name : '';
      }
    });
    hbEventBus.$on(TreeActionTypeEnums.UpdateZoneDetails, (item: ITreeItem) => {
      if (item && item.key === this.item.key) {
        this.subzoneNameInput = item.name ? item.name : '';
        this.opportunityScoreType = this.getRefreshedOpportunityScoreType();
        this.opportunityPriority = this.getRefreshedOpportunityPriority();
        this.lowerTolerance = this.getRefreshedLowerTolerance();
        this.upperTolerance = this.getRefreshedUpperTolerance();
        this.notes = this.getRefreshedNotes();
      }
    });
    hbEventBus.$on(TreeActionTypeEnums.UpdateBurnerInputs, () => {
      this.burnerCount = this.item.burnerCount ? this.item.burnerCount : 0;
    });
    hbEventBus.$on('ALL_BURNERS_ADDED', () => {
      this.refreshUndoOption();
    });
    this.refreshUndoOption();
    this.refreshHeights();
  }

  private updated(): void {
    this.refreshHeights();
  }

  private getRefreshedOpportunityPriority(): number {
    return this.fullZoneData && this.fullZoneData.opportunityPriority ? this.fullZoneData.opportunityPriority : 1;
  }

  private getRefreshedOpportunityScoreType(): string {
    return this.fullZoneData && this.fullZoneData.opportunityScoreType ? this.fullZoneData.opportunityScoreType : null;
  }

  private getRefreshedLowerTolerance(): number {
    return this.fullZoneData && this.fullZoneData.lowerTolerance ? this.fullZoneData.lowerTolerance : null;
  }

  private getRefreshedUpperTolerance(): number {
    return this.fullZoneData && this.fullZoneData.upperTolerance ? this.fullZoneData.upperTolerance : null;
  }

  private getRefreshedNotes(): string {
    return this.fullZoneData && this.fullZoneData.notes ? this.fullZoneData.notes : '';
  }

  private refreshHeights(): void {
    if (this.$refs.dropParent) {
      this.clientZoneHeight = (this.$refs.dropParent as any).offsetTop;
    }
  }

  private refreshUndoOption(): void {
    // set tree items to UNDO if the burners already existing in the walking order
    if (this.currentPage === 'walking-order' && this.store.state.hierarchyState.selectedWalkingOrder) {
      const zone: IZone =
        this.store.state.hierarchyState.hierarchy.zones.find((z) => z.zoneKey === this.item.associatedZoneKey) || {};
      let definedBurnersInZone: IBurnerDefinition[] = [];
      if (zone.burnerDefinitions) {
        definedBurnersInZone = zone.burnerDefinitions.filter((def) => def.burnerName);
      }
      const burnersForZoneInOrder = this.store.state.hierarchyState.selectedWalkingOrder.burnerOrder.filter(
        (bo) => bo.zoneKey === this.item.associatedZoneKey
      );

      if (store.state.hierarchyState.selectedWalkingOrder.name === '') {
        if (definedBurnersInZone.length === burnersForZoneInOrder.length) {
          this.canUndo = true;
        }
      } else {
        if (definedBurnersInZone.length > 0 && burnersForZoneInOrder.length > 0) {
          this.canUndo = true;
        }
      }
    }
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.RenameZone);
    hbEventBus.$off(TreeActionTypeEnums.UpdateZoneDetails);
    hbEventBus.$off(TreeActionTypeEnums.UpdateBurnerInputs);
    hbEventBus.$off('ALL_BURNERS_ADDED');
    store.commit('hierarchyState/clearSelectedTreeItem');
  }

  private closeTree(): void {
    store.commit('hierarchyState/toggleZone', this.item.key);
  }

  private async submitDetails(): Promise<void> {
    const isSubzone = this.item.parentKey ? true : false;

    const zoneDetails: IUpdateZoneDetails = {
      name: this.item.name || '',
      opportunityPriority: this.opportunityPriority,
      opportunityScoreType: this.opportunityScoreType,
      lowerTolerance: this.lowerTolerance,
      upperTolerance: this.upperTolerance,
      notes: this.notes,
      zoneKey: this.item.associatedZoneKey || '',
      treeItemKey: this.item.key
    };

    if (isSubzone) {
      zoneDetails.name = this.subzoneNameInput;
    }

    const prevName = this.item.name;
    const prevOppScore = this.fullZoneData.opportunityPriority || 1;
    const prevLowerTolerance = this.fullZoneData.lowerTolerance;
    const prevUpperTolerance = this.fullZoneData.upperTolerance;

    store.commit('hierarchyState/updateSubzone', zoneDetails);
    this.showDetailsModal = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      hbEventBus.$emit(TreeActionTypeEnums.UpdateZoneDetails, this.item);
    } catch (error) {
      showAndCommitError(error);
      const prevDetails: IUpdateZoneDetails = {
        name: prevName || '',
        opportunityPriority: prevOppScore,
        lowerTolerance: prevLowerTolerance,
        upperTolerance: prevUpperTolerance,
        zoneKey: this.item.associatedZoneKey || '',
        treeItemKey: this.item.key
      };
      store.commit('hierarchyState/updateSubzone', prevDetails);

      // revert v-model values for the details modal
      this.subzoneNameInput = prevName || '';
      this.opportunityPriority = prevOppScore;
      this.lowerTolerance = prevLowerTolerance;
      this.upperTolerance = prevUpperTolerance;
    }
  }

  private hideDeleteBurnerModal(): void {
    this.showDeleteBurnerModal = false;
  }

  private hideDeleteConfigModal(): void {
    this.showDeleteConfigModal = false;
  }

  private openDeleteModal(): void {
    this.showDeleteModal = true;
  }

  private hideDeleteModal(): void {
    this.showDeleteModal = false;
  }

  private hideDetailsModal(): void {
    this.showDetailsModal = false;
  }

  private openDetailsModal(): void {
    this.showDetailsModal = true;
  }

  private copyZoneDetails(item: ITreeItem): void {
    store.commit('hierarchyState/copyZoneDetails', item);
  }

  private async pasteZoneDetails(item: ITreeItem): Promise<void> {
    if (this.detailsAreCopied) {
      const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);
      const zoneDetailsDeepCopy = cloneDeep(store.state.hierarchyState.copiedZoneDetails);
      store.commit('hierarchyState/pasteZoneDetails', item);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.opportunityPriority = zoneDetailsDeepCopy.opportunityPriority || 1;
        this.upperTolerance = zoneDetailsDeepCopy.upperTolerance;
        this.lowerTolerance = zoneDetailsDeepCopy.lowerTolerance;
      } catch (error) {
        showAndCommitError(error);
        store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
      }
    }
  }

  private async pasteAttributesViaTree(item: ITreeItem, type: string): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    if (type === 'toZones') {
      store.commit('hierarchyState/pasteAttributesToZones', item);
    } else {
      store.commit('hierarchyState/pasteAttributesToBurners', item);
    }

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private handleLeafUndoEmission(item: ITreeItem): void {
    const emittedItem: IEmitObject = { zoneKey: item.associatedZoneKey, treeItem: item };
    this.canUndo = false;
    hbEventBus.$emit(TreeActionTypeEnums.LeafSelectedUndo, emittedItem);
  }

  private handleLeafEmission(item: ITreeItem): void {
    let emittedItem: IEmitObject = {};
    if (this.currentPage === 'attributes') {
      store.commit('hierarchyState/selectTreeItem', item);
      emittedItem = { zoneKey: item.associatedZoneKey, burnerKey: item.burnerKey };
    } else {
      emittedItem = { zoneKey: item.associatedZoneKey, type: item.name, burnerCount: item.burnerCount, treeItem: item };

      if (this.currentPage === 'burners') {
        store.commit('hierarchyState/selectTreeItem', item);
      }
    }

    if (this.currentPage === 'walking-order') {
      this.canUndo = true;
    }

    hbEventBus.$emit(TreeActionTypeEnums.LeafSelected, emittedItem);
  }

  private handleZoneEmission(item: ITreeItem): void {
    if (item.type === 'Zone') {
      const emittedItem: IEmitObject = { zoneKey: item.associatedZoneKey };
      hbEventBus.$emit(TreeActionTypeEnums.ZoneSelected, emittedItem);

      if (this.currentPage === 'attributes') {
        store.commit('hierarchyState/selectTreeItem', item);
      }
    }
  }

  private async handleSubzoneRename(item: ITreeItem, newValue: string): Promise<void> {
    const renameSubzone = { item, value: newValue };
    const prevName = item.name;
    const prevSubzone = { item, value: prevName };

    if (newValue === prevName) {
      return;
    }

    item.name = newValue;
    store.commit('hierarchyState/renameSubzone', renameSubzone);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      hbEventBus.$emit(TreeActionTypeEnums.RenameZone, item);
    } catch (error) {
      showAndCommitError(error);
      item.name = prevName;
      this.subzoneNameInput = prevName || '';
      store.commit('hierarchyState/renameSubzone', prevSubzone);
    }
  }

  private async handleDeleteSubzone(): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    await store.dispatch('hierarchyState/deleteSubzone', this.item);
    this.showDetailsModal = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private toggleIsOpen(item: ITreeItem): void {
    store.commit('hierarchyState/toggleZone', item.key);
  }

  private async handleAddSubzone(item: ITreeItem): Promise<void> {
    if (!this.isOpen) {
      this.toggleIsOpen(item);
    }

    const liaisonOb: ITreeLiaison = {
      source: 'newSubZone',
      target: item
    };

    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    store.commit('hierarchyState/updateHierarchySubzones', liaisonOb);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private async handleDelete(): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    await store.dispatch('hierarchyState/handleDeleteLeaf', this.item);
    this.showDeleteConfigModal = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private showEditBurners(): void {
    this.editBurners = true;
    this.$nextTick().then(() => {
      const input: HTMLInputElement = this.$refs.burnerInput as HTMLInputElement;
      input.focus();
    });
  }

  private get burnerString(): string {
    return `${this.item.burnerCount} Burners`;
  }

  private async handleBurnerCountUpdate(item: ITreeItem, event: FocusEvent | KeyboardEvent): Promise<void> {
    // don't allow burners to be removed --- must be removed via burners page
    const inputVal = (event.target as HTMLInputElement).value;
    const zone = store.state.hierarchyState.hierarchy.zones.find((z) => z.zoneKey === item.associatedZoneKey);

    let typeMatch: IBurnerDefinition[] = [];
    if (zone && zone.burnerDefinitions) {
      typeMatch = zone.burnerDefinitions.filter((def) => def.burnerTypeName === item.burnerTypeName);
    }

    if (typeMatch.length > parseInt(inputVal, 10)) {
      this.showDeleteBurnerModal = true;
      this.editBurners = false;
      this.burnerCount = zone.burnerDefinitions.length;
      return;
    }

    if ((!this.burnerCount && this.burnerCount !== 0) || this.burnerCount > 1000 || this.burnerCount < 0) {
      showError('Please enter a valid value.');
      return;
    }

    // store previous count before state is updated
    const prevBurnerCount = item.burnerCount;
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    this.editBurners = false;

    const burnerObject: IEmitBurnerCountObject = {
      item,
      count: parseInt(inputVal, 10)
    };

    await store.dispatch('hierarchyState/generateBurners', burnerObject);
    // updates v-models in burner items to match state change
    hbEventBus.$emit(TreeActionTypeEnums.UpdateBurnerInputs);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      const revertEmitObject = {
        item,
        count: prevBurnerCount
      };
      await store.dispatch('hierarchyState/generateBurners', revertEmitObject);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private onClickCollapser(item: ITreeItem): void {
    store.commit('hierarchyState/toggleZone', item.key);
  }

  private async dropItem(event: DragEvent, target: ITreeItem): Promise<void> {
    if (event == null) {
      return;
    }
    // get data thats being dropped
    const source = JSON.parse(event.dataTransfer.getData('text/plain'));

    // if dragging and dropping to same place, do nothing
    if (target.key === source.key || target.key == null) {
      return;
    }
    // handle drags from element list
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);
    if (!source.key) {
      const newElement: ITreeLiaison = {
        source,
        target
      };

      await store.dispatch('hierarchyState/handleLeafToZoneDrag', newElement);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      } catch (error) {
        showAndCommitError(error);
        store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
      }
      return;
    }

    const actionType: TreeActionTypeEnums =
      source.type === 'Zone' ? TreeActionTypeEnums.ZoneToZone : TreeActionTypeEnums.LeafToZone;

    const data: ITreeLiaison = {
      source,
      target
    };

    if (actionType === TreeActionTypeEnums.LeafToZone) {
      await store.dispatch('hierarchyState/handleLeafToZoneDrag', data);
    } else if (actionType === TreeActionTypeEnums.ZoneToZone) {
      store.commit('hierarchyState/handleZoneToZoneDrag', data);
    }

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private handleDragEnd(event: DragEvent, item: ITreeItem): void {
    const zone: IZone =
      this.store.state.hierarchyState.hierarchy.zones.find((z) => z.zoneKey === item.associatedZoneKey) || {};
    let definedBurnersInZone: IBurnerDefinition[] = [];
    if (zone.burnerDefinitions) {
      definedBurnersInZone = zone.burnerDefinitions.filter((def) => def.burnerName);
    }
    const burnersForZoneInOrder = this.store.state.hierarchyState.selectedWalkingOrder.burnerOrder.filter(
      (bo) => bo.zoneKey === item.associatedZoneKey
    );
    if (definedBurnersInZone.length === burnersForZoneInOrder.length) {
      this.canUndo = true;
    }
  }

  private drag(event: DragEvent, item: ITreeItem): void {
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', JSON.stringify(item));
    }
  }
}
