import { IAttribute, IFormulaAttribute, IFormulaFunction, IFormulaOperand } from '@/view-models/hierarchy-view-models';
import { ATTRIBUTE_DATA_TYPES } from '@/enums/hierarchy-builder-types';
import StringUtil from '@/utils/stringUtil';
import HelperMethods from '@/shared/helper-methods';
import { evaluate } from 'mathjs';
import escapeStringRegexp from 'escape-string-regexp';

interface IDataMapperFunction {
  fn: string;
  format?: string;
  minAttributes?: number;
  maxAttributes?: number;
  minConstants?: number;
  maxConstants?: number;
  mathJsFn?: string;
}

export class FormulaHelper {
  private static readonly attrPlaceholder: string = '#attr#';
  private static readonly defaultDataMapperFormat: string = '\\((((#attr#)|([\\d.-]+))(,(?!\\)))?)+\\)';
  private static readonly dataMapperFns: IDataMapperFunction[] = [
    { fn: 'abs', minAttributes: 1, maxAttributes: 1, maxConstants: 0, mathJsFn: 'abs' },
    { fn: 'avg', minAttributes: 2, maxConstants: 0, mathJsFn: 'mean' },
    { fn: 'exp', format: '\\(#attr#,[\\d.-]\\)',
      minAttributes: 1, maxAttributes: 1, minConstants: 1, maxConstants: 1, mathJsFn: 'pow' },
    { fn: 'max', minAttributes: 2, maxConstants: 0, mathJsFn: 'max' },
    { fn: 'med', minAttributes: 2, maxConstants: 0, mathJsFn: 'median' },
    { fn: 'min', minAttributes: 2, maxConstants: 0, mathJsFn: 'min' },
    { fn: 'sum', minAttributes: 2, maxConstants: 0, mathJsFn: 'sum' },
    { fn: 'if', minAttributes: 0, format: `\\(.+,.+,.+\\)` },
    { fn: 'isnonnumeric', minAttributes: 0, format: `\\(.+,.+\\)`}
  ];

  public static validateIntegrity(
    attributeHashTable: any,
    attributeParentKey: string,
    attribute: IFormulaAttribute
  ): string {
    let error: string;
    const references: string[] = [];

    if (attribute.dataType !== ATTRIBUTE_DATA_TYPES.BOOLEAN && attribute.dataType !== ATTRIBUTE_DATA_TYPES.NUMERIC) {
      return `Formula must have data type of ${ATTRIBUTE_DATA_TYPES.BOOLEAN} or ${ATTRIBUTE_DATA_TYPES.NUMERIC}.`;
    }

    if (StringUtil.empty(attribute.value?.trim())) {
      return `Formula is empty. A formula must be defined`;
    }

    error = this.validateAttributes(attributeHashTable, attributeParentKey, attribute, references);
    if (!HelperMethods.isNullOrUndefined(error)) {
      return error;
    }

    error = this.validateFormat(attribute, references);
    if (!HelperMethods.isNullOrUndefined(error)) {
      return error;
    }

    error = this.testFormula(attribute, references);
    if (!HelperMethods.isNullOrUndefined(error)) {
      return error;
    }
  }

  private static validateAttributes(
    attributeHashTable: any,
    attributeParentKey: string,
    attribute: IFormulaAttribute,
    references: string[]
  ): string {
    const formula: string = attribute.value;
    let matches: RegExpMatchArray;

    // Check for empty double quotes
    if (formula.includes('""')) {
      return `Malformed formula contains empty double quotes. Received formula '${formula}'`;
    }

    // Check that double quotes exist in pairs
    matches = formula.match(/"/g);
    if (matches && matches.length % 2 !== 0) {
      return `Malformed formula contains an uneven number of double quotes. Received formula '${formula}'`;
    }

    // Match all attributes in the formula
    matches = formula.match(/"[^"]*"/g); // e.g. "/root/AttributeName"

    // Check that at least one attribute is referenced
    if (!matches) {
      return `Formula must contain a reference to an attribute. Received formula '${attribute.rawFormula}'`;
    }

    // Check attribute references
    let reference: string;
    while ((reference = matches.pop())) {
      let referenceName: string | undefined;
      let referenceParentKey: string | undefined;

      // Determine the parent key and name of the attribute reference
      const lastIndex = reference.lastIndexOf('/');
      if (lastIndex > -1) { // Top-level attribute reference
        referenceName = reference.substring(lastIndex + 1);
        referenceParentKey = Object.keys(attributeHashTable).find((key: string) =>
          key.toUpperCase() === reference.substring(0, lastIndex).replace(/"/g, '').toUpperCase());
      } else { // Same-level attribute reference
        referenceName = reference;
        referenceParentKey = attributeParentKey;
      }

      if (!referenceParentKey || referenceParentKey === '') {
        return `Formula references unknown attribute '${this.rawReference(attribute, reference)}'`;
      }

      const refAttribute: IAttribute = attributeHashTable[referenceParentKey].find((attr: IAttribute) =>
        attr.name?.toUpperCase() === referenceName?.replace(/"/g, '').toUpperCase());

      if (!refAttribute) {
        return `Formula references unknown attribute '${this.rawReference(attribute, reference)}'`;
      }

      if (!StringUtil.equal(refAttribute.dataType, ATTRIBUTE_DATA_TYPES.NUMERIC)) {
        return `Formula references non-numeric attribute '${this.rawReference(attribute, reference)}'. ` +
          `Only numeric attributes can be referenced`;
      }

      references.push(reference);
    }
  }

  private static validateFormat(attribute: IFormulaAttribute, references: string[]): string {
    let formula: string = attribute.value;

    // Replace attribute references with a placeholder
    for (const reference of references) {
      formula = formula.replace(new RegExp(escapeStringRegexp(reference), 'gi'), this.attrPlaceholder);
    }

    // Remove spaces not around and/or operators
    formula = formula.replace(/(?<!and|or)\s(?!and|or)/gi, '');

    // Check if the formula contains one or more supported functions, and validate each instance
    for (const fn of attribute.supportedFns) {
      if (formula.toLowerCase().includes(fn.dataMapperFn)) {
        const dmFn: IDataMapperFunction = this.dataMapperFns.find((func) => func.fn === fn.dataMapperFn);
        if (!dmFn) {
          continue;
        }

        // Build the function RegExp (e.g. sum(<argFormat>))
        const fnRegExp = new RegExp(`${dmFn.fn}${dmFn.format ?? this.defaultDataMapperFormat}`, 'gi');

        // Validate that the function is formatted properly
        const fnMatches = fn.dataMapperFn === 'if'
          ? this.ifFnMatches(formula, dmFn)?.map((obj) => obj.formula)
          : formula.match(fnRegExp);
        if (!fnMatches) {
          return `Function ${fn.fn}() requires a format of '${fn.usage}'. Received formula '${attribute.rawFormula}'`;
        }

        // For each instance of this formula, validate the arguments
        for (const fnMatch of fnMatches) {
          // Validate the arguments
          const error = this.validateArguments(fnMatch, fn, dmFn);
          if (!HelperMethods.isNullOrUndefined(error)) {
            return error;
          }

          // Mock the result of the function from the formula
          formula = formula.replace(fnMatch, '1');
        }
      }
    }

    // Remove the supported characters/operators from the formula
    formula = formula.replace(new RegExp(this.attrPlaceholder, 'gi'), '');
    formula = formula.replace(/[0-9\(\)\+\-\*\/\.]| and | or |true|false|<>|>=|<=|=|>|</gi, '');

    // Validate that the formula is now empty
    // This will confirm that the formula only consists of a function, or the supported characters/operators
    if (!StringUtil.empty(formula.trim())) {
      return `Formula '${attribute.rawFormula}' is not supported`;
    }
  }

  private static validateArguments(parsedFn: string, fn: IFormulaFunction, dmFn: IDataMapperFunction): string {
    const argRegExp: RegExp = new RegExp(`(((?<attribute>${this.attrPlaceholder})|(?<constant>[\\d.-]+)),*)`, 'gi');
    let numAttributes = 0;
    let numConstants = 0;

    // Classify the arguments as attributes or constants, and track the number of each
    // Each argRegExp.exec loop will only parse a single argument, so loop until all are parsed
    let argMatch: RegExpExecArray;
    while ((argMatch = argRegExp.exec(parsedFn)) !== null) {
      if (!HelperMethods.isNullOrUndefined(argMatch.groups?.attribute)) {
        numAttributes++;
      }

      if (!HelperMethods.isNullOrUndefined(argMatch.groups?.constant)) {
        numConstants++;
      }
    }

    if (!HelperMethods.isNullOrUndefined(dmFn.minAttributes) && numAttributes < dmFn.minAttributes) {
      return `Function ${fn.fn}() must reference a minimum of ${dmFn.minAttributes} attribute(s)`;
    } else if (!HelperMethods.isNullOrUndefined(dmFn.maxAttributes) && numAttributes > dmFn.maxAttributes) {
      if (dmFn.maxAttributes === 0) {
        return `Function ${fn.fn}() must not reference any attributes`;
      } else {
        return `Function ${fn.fn}() may only reference a maximum of ${dmFn.minAttributes} attribute(s)`;
      }
    } else if (!HelperMethods.isNullOrUndefined(dmFn.minConstants) && numConstants < dmFn.minConstants) {
      return `Function ${fn.fn}() must contain a minimum of ${dmFn.minConstants} constant(s)`;
    } else if (!HelperMethods.isNullOrUndefined(dmFn.maxConstants) && numConstants > dmFn.maxConstants) {
      if (dmFn.maxConstants === 0) {
        return `Function ${fn.fn}() must not contain any constants`;
      } else {
        return `Function ${fn.fn}() may only contain a maximum of ${dmFn.maxConstants} constant(s)`;
      }
    }
  }

  private static testFormula(attribute: IFormulaAttribute, references: string[]): string {
    let formula = attribute.value;

    // Replace functions with the math.js equivalents
    for (const dmFn of this.dataMapperFns) {
      if (dmFn.mathJsFn) {
        formula = formula.replace(new RegExp(dmFn.fn, 'gi'), dmFn.mathJsFn);
      } else if (dmFn.fn === 'if' && formula.toLowerCase().includes('if(')) {
        // No equivalent function for 'if' so reformatting to use conditional expression
        const conversion = this.convertIfFnMatches(formula, dmFn, references);
        if (conversion.error) {
          return conversion.error;
        } else if (conversion.formula) {
          formula = conversion.formula;
        }
      } else if (dmFn.fn === 'isnonnumeric' && formula.toLowerCase().includes('isnonnumeric(')) {
        const conversion = this.convertIsNonNumericFnMatches(formula, dmFn, references);
        if (conversion.error) {
          return conversion.error;
        } else if (conversion.formula) {
          formula = conversion.formula;
        }
      }
    }

    formula = this.replaceReferencesAndExcelOperators(formula, references);

    // Attempt to evaluate statement, Note: false = 0 and true = 1
    try {
      const evaluation = evaluate(formula);
      if (!['boolean', 'number'].includes(typeof evaluation)) {
        return `Formula result was not a boolean or number. The result was a ${typeof evaluation}: '${evaluation}'`;
      } else if (
        (typeof evaluation === 'boolean' && attribute.dataType !== ATTRIBUTE_DATA_TYPES.BOOLEAN) ||
        (typeof evaluation === 'number' && attribute.dataType !== ATTRIBUTE_DATA_TYPES.NUMERIC)
      ) {
        return `Formula result was not ${attribute.dataType}. The result was a ${typeof evaluation}: '${evaluation}'`;
      }
    } catch (error) {
      return `Unable to evaluate formula '${attribute.rawFormula}'. Tested '${formula}'`;
    }
  }

  /**
   * Replace the references and excel operators to work with mathJS evaluation
   */
  private static replaceReferencesAndExcelOperators(formula: string, references: string[]): string {
    let newFormula = formula;

    // Replace attribute references with a value for evaluation
    for (const reference of references) {
      newFormula = newFormula.replace(new RegExp(escapeStringRegexp(reference), 'gi'), '1');
    }

    // Replace excel logical comparison format with mathJS format
    newFormula = newFormula.replace(new RegExp('(?<![<>])=', 'gi'), '==');
    newFormula = newFormula.replace(new RegExp('<>', 'gi'), '!=');

    return newFormula;
  }

  /**
   * Checks if the formula contains the if function, and then closes the string at the correct parenthesis.
   * RegEx does not work for this due to potentially infinite parenthesis nesting. Regex also does not work with
   * commas as other functions can be nested within the if function, so use logic to get the correct positions.
   */
  private static ifFnMatches(formula: string, dmFn: IDataMapperFunction): Array<{
    formula: string,
    qmarkPos: number,
    colonPos: number
  }> | null {
    const substrings = [];
    const ifRegExp = new RegExp(dmFn.fn + dmFn.format, 'gi');
    // Loop over every if function used inside the formula
    for (
      let index = formula.toLowerCase().indexOf('if(');
      index >= 0;
      index = formula.toLowerCase().indexOf('if(', index+1)
    ) {
      let leftCount = 0, rightCount = 0; // Count of all left and right parenthesis
      let commaCount = 0; // Count of commas that are NOT inside any nested parenthesis
      let qmarkPos, colonPos; // Positions of the commas to use when converting to JS format
      let substring = formula.slice(index); // Start the substring at the correct index
      for (let i = 0; i < substring.length; i++) {
        if (substring.charAt(i) === '(') {
          leftCount++;
        } else if (substring.charAt(i) === ')') {
          rightCount++;
        } else if (substring.charAt(i) === ',' && leftCount === rightCount+1) {
          commaCount++;
          if (commaCount === 1) {
            qmarkPos = i;
          } else if (commaCount === 2) {
            colonPos = i;
          } else if (commaCount === 3) {
            return null;
          }
        }
        // The if function is closed and has correct number of unnested commas
        if (leftCount > 0 && commaCount === 2 && leftCount === rightCount) {
          substring = substring.slice(0, i+1);
          // Check substring matches correct format
          if (!substring.match(ifRegExp)) {
            return null;
          }
          // Check there are no nested if functions
          if ((substring.match(/if\(/gi))?.length > 1) {
            return null;
          }
          substrings.push({formula: substring, qmarkPos, colonPos});
          break;
        }
        // Reached end of formula without meeting correct conditions
        if (i === substring.length-1) {
          return null;
        }
      }
    }
    return substrings?.length > 0 ? substrings : null;
  }

  /** Used to simulate and convert the isNonNumeric custom function from datamapper to use the local mathjs library */
  private static convertIsNonNumericFnMatches(
    formula: string, dmFn: IDataMapperFunction, reference: string[]): { formula: string, error: string } {
    const pattern = /isnonnumeric\(([^,]+),([^)]+)\)/gmi;
    let values = this.replaceReferencesAndExcelOperators(formula, reference);
    const match = values.match(pattern);

    if (match.length > 0) {
      match.forEach((value) => {
        const temp = value;
        const result = temp.replace('IsNonNumeric(', '').replace(')','').split(',');
        if (result.length > 0) {
          if (!Number.isNaN(parseFloat(result[0]))) {
            values = values.replace(value, result[0]);
          } else {
            values = values.replace(value, result[1]);
          }
        }
      });
    }
    return { formula: values, error: null };
  }
  /**
   * Convert the if function into conditional expression format used in mathJS
   * and test that the trueValue and falseValue both return the same type.
   * ex: if(a>b,true,false) will be converted into (a>b ? true : false)
   */
  private static convertIfFnMatches(
    formula: string, dmFn: IDataMapperFunction, references: string[]
  ): { formula: string, error: string } {
    const oldFormats = this.ifFnMatches(formula, dmFn);
    for (const oldFormat of oldFormats || []) {
      const ifValue = oldFormat.formula.slice(3, oldFormat.qmarkPos);
      let trueValue = oldFormat.formula.substring(oldFormat.qmarkPos+1, oldFormat.colonPos);
      let falseValue = oldFormat.formula.slice(oldFormat.colonPos+1, -1);
      const newFormat = `(${ifValue} ? ${trueValue} : ${falseValue})`;
      formula = formula.replace(oldFormat.formula, newFormat);
      // Test that the outputs are of the same type
      try {
        trueValue = this.replaceReferencesAndExcelOperators(trueValue, references);
        falseValue = this.replaceReferencesAndExcelOperators(falseValue, references);
        const evalTrue = evaluate(trueValue);
        const evalFalse = evaluate(falseValue);
        if (typeof evalTrue !== typeof evalFalse) {
          const error = `If function cannot have different types for trueValue and falseValue results. ` +
            `Testing trueValue of '${trueValue}' resulted in a ${typeof evalTrue}: ${evalTrue}, while ` +
            `testing falseValue of '${falseValue}' resulted in a ${typeof evalFalse}: ${evalFalse}.`;
          return { error, formula: null };
        }
      } catch (error) {
        // Syntax inside either or both of true/false values failed
        // Catch error here so as to throw for full formula later
      }
    }
    return { formula, error: null };
  }

  private static rawReference(attribute: IFormulaAttribute, reference: string): string {
    if (attribute.operands) {
      const operand: IFormulaOperand = attribute.operands.find((oper: IFormulaOperand) => oper.path === reference);
      if (operand) {
        return operand.rawPath;
      }
    }

    return reference;
  }
}
