



































































































































































































































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import { IBurnerDefinition } from '@/view-models/hierarchy-view-models';
import store from '../../store';
import { v4 as uuid } from 'uuid';
import Dropdown from '../common/Dropdown.vue';
import OnpointModal from '../common/OnpointModal.vue';
import FormField from '@/components/common/FormField.vue';
import { cloneDeep } from 'lodash';
import { getZoneNamesFromRootToChild } from '@/utils/StoreHelpers';
import { showAndCommitError, showError } from '@/utils/Helpers';
import HelperMethods from '@/shared/helper-methods';

@Component({
  components: { FormField, Dropdown, OnpointModal }
})
export default class BurnerDetails extends Vue {
  @PropSync('details', { required: true })
  private selectedBurnerDetails: IBurnerDefinition[];
  private burnerToEdit: { index: number; burner: IBurnerDefinition } = {
    index: -1,
    burner: {}
  };
  private burnerClipboard: IBurnerDefinition = {};
  private burnerToDelete: IBurnerDefinition = {};
  private showClearModal = false;
  private showDeleteModal = false;
  private showClearAllModal = false;
  private showPasteAllModal = false;
  private pasteToZone = false;
  private namePrefix = 'Burner 1-';
  private startIndex = 1;
  private pasteAllOppScore = 1;
  private pasteAllLower = null;
  private pasteAllUpper = null;
  private pasteAllNotes = '';

  private created(): void {
    this.$validator.extend('eloNameIsUnique', {
      getMessage: (field) => `${field} must be unique`,
      validate: this.eloNameIsValid
    });
    this.$validator.extend('burnerNameIsUnique', {
      getMessage: (field) => `${field} must be unique`,
      validate: this.burnerNameIsValid
    });
  }

  private eloNameIsValid(eloName: string): boolean {
    const index = this.burnerToEdit.index;
    const burnerToSave = this.selectedBurnerDetails[index];
    const hasFlow = this.hasFlowNetwork;
    return !hasFlow || (hasFlow && eloName &&
      (eloName === burnerToSave.eloName ||
        this.isUniqueElo(eloName)));
  }

  private burnerNameIsValid(burnerName: string): boolean {
    const index = this.burnerToEdit.index;
    const burnerToSave = this.selectedBurnerDetails[index];
    const burnersWithName = this.getBurnerWithName(burnerName);
    return HelperMethods.isArrayEmpty(burnersWithName) || burnerToSave.burnerName === burnerName;
  }

  private editBurner(detail: IBurnerDefinition, index: number): void {
    if (this.burnerToEdit.index !== -1) {
      this.closeEdit();
    }
    this.burnerToEdit.burner = { ...detail };
    this.burnerToEdit.index = index;
    this.$nextTick(() => {
      this.$validator.reset();
    });
  }

  private copyBurner(copyBurner: IBurnerDefinition): void {
    this.burnerClipboard.burnerName = copyBurner.burnerName;
    this.burnerClipboard.lowerTolerance = copyBurner.lowerTolerance;
    this.burnerClipboard.upperTolerance = copyBurner.upperTolerance;
    this.burnerClipboard.opportunityPriority = copyBurner.opportunityPriority;
    this.burnerClipboard.notes = copyBurner.notes;
  }

  private pasteBurner(pasteToBurner: IBurnerDefinition, index: number): void {
    if (this.burnerClipboard.opportunityPriority || this.burnerClipboard.opportunityPriority === 0) {
      this.burnerToEdit.index = index;
      this.burnerToEdit.burner = { ...pasteToBurner };
      this.burnerToEdit.burner.lowerTolerance = this.burnerClipboard.lowerTolerance;
      this.burnerToEdit.burner.upperTolerance = this.burnerClipboard.upperTolerance;
      this.burnerToEdit.burner.opportunityPriority = this.burnerClipboard.opportunityPriority;
      this.burnerToEdit.burner.notes = this.burnerClipboard.notes;
    } else {
      showError('No burner in clipboard.');
    }
  }

  private get hasFlowNetwork(): boolean {
    const caeAsset = store.state.hierarchyState.assets.find(
      (asset) => asset.key === store.state.hierarchyState.hierarchy.assetKey
    );
    return !!(caeAsset && caeAsset.subscription && caeAsset.subscription.flowNetwork);
  }

  private isUniqueElo(eloName?: string): boolean {
    const zones = store.state.hierarchyState.hierarchy.zones;
    return !zones.some((zone) => {
      return zone.burnerDefinitions && zone.burnerDefinitions?.some((burner) => burner.eloName === eloName);
    });
  }

  private getBurnerWithName(name: string): IBurnerDefinition[] {
    return this.selectedBurnerDetails.filter((burner) => burner.burnerName === name);
  }

  private async saveBurner(burnerToSave: IBurnerDefinition): Promise<void> {
    const allFieldsValid = await this.$validator.validateAll();
    if (!allFieldsValid) {
      return;
    }

    if (!this.burnerToEdit.burner.burnerKey) {
      burnerToSave.burnerKey = uuid();
    }
    burnerToSave.burnerName = this.burnerToEdit.burner.burnerName;
    burnerToSave.opportunityPriority = this.burnerToEdit.burner.opportunityPriority;
    burnerToSave.upperTolerance = this.burnerToEdit.burner.upperTolerance;
    burnerToSave.lowerTolerance = this.burnerToEdit.burner.lowerTolerance;
    burnerToSave.eloName = this.burnerToEdit.burner.eloName;
    burnerToSave.notes = this.burnerToEdit.burner.notes;
    const tempWalkOrders = cloneDeep(store.state.hierarchyState.hierarchy.walkingOrders);
    if (store.state.hierarchyState.hierarchy.walkingOrders) {
      store.state.hierarchyState.hierarchy.walkingOrders.forEach((walkOrder) => {
        walkOrder.status = 'Draft';
      });
    }
    this.closeEdit();
    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.state.hierarchyState.hierarchy.walkingOrders = tempWalkOrders;
    }
  }

  private openClear(burner: IBurnerDefinition): void {
    this.burnerToDelete = burner;
    this.showClearModal = true;
  }

  private openClearAll(): void {
    this.showClearAllModal = true;
  }

  private openPasteAll(pasteToZone: boolean): void {
    this.showPasteAllModal = true;
    this.pasteToZone = pasteToZone;
  }

  private closeClearAll(): void {
    this.showClearAllModal = false;
  }

  private closePasteAll(): void {
    this.showPasteAllModal = false;
  }

  private async clearAll(): Promise<void> {
    const tempDetails = [...this.selectedBurnerDetails];
    const tempWalkOrders = cloneDeep(store.state.hierarchyState.hierarchy.walkingOrders);
    this.selectedBurnerDetails.forEach((detail) => {
      this.burnerToDelete = detail;
      if (detail.burnerName) {
        this.clearDetail();
        this.removeWalkingOrderForBurner(detail);
      }
    });

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      this.closeClearAll();
    } catch (error) {
      showAndCommitError(error);
      this.selectedBurnerDetails = [...tempDetails];
      store.state.hierarchyState.hierarchy.walkingOrders = tempWalkOrders;
    }
  }

  private async pasteAll(): Promise<void> {
    const duplicateEloBurnerNames: string[] = [];
    const hasFlow = this.hasFlowNetwork;
    const requiredFieldsDefined = this.namePrefix && this.startIndex;
    if (!requiredFieldsDefined) {
      showError('Missing value for required field.');
      return;
    }
    const tempWalkOrders = cloneDeep(store.state.hierarchyState.hierarchy.walkingOrders);
    if (store.state.hierarchyState.hierarchy.walkingOrders) {
      store.state.hierarchyState.hierarchy.walkingOrders.forEach((walkOrder) => {
        walkOrder.status = 'Draft';
      });
    }
    if (this.pasteToZone) {
      this.pasteBurnersToZone(hasFlow, duplicateEloBurnerNames);
    } else {
      const tempDetails = [...this.selectedBurnerDetails];
      this.pasteBurnerDetails(this.selectedBurnerDetails, hasFlow, duplicateEloBurnerNames);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.closePasteAll();
        this.namePrefix = 'Burner 1-';
        this.startIndex = 1;
        this.pasteAllOppScore = 1;
        this.pasteAllLower = null;
        this.pasteAllUpper = null;
        this.pasteAllNotes = '';
      } catch (error) {
        showAndCommitError(error);
        this.selectedBurnerDetails = [...tempDetails];
        store.state.hierarchyState.hierarchy.walkingOrders = tempWalkOrders;
      }
    }
    if (duplicateEloBurnerNames.length) {
      showError('Pasted burner(s) had a duplicate ELOName: ' + duplicateEloBurnerNames.toString());
    }
  }

  private async pasteBurnersToZone(hasFlow: boolean, duplicateEloBurnerNames: string[]): Promise<void> {
    const zoneKey = this.selectedBurnerDetails[0].zoneKey;
    const tempWalkOrders = cloneDeep(store.state.hierarchyState.hierarchy.walkingOrders);
    if (store.state.hierarchyState.hierarchy.walkingOrders) {
      store.state.hierarchyState.hierarchy.walkingOrders.forEach((walkOrder) => {
        walkOrder.status = 'Draft';
      });
    }
    const zone = store.state.hierarchyState.hierarchy.zones.find((z) => z.zoneKey === zoneKey);
    if (zone) {
      const tempBurners = [...zone.burnerDefinitions];
      this.pasteBurnerDetails(zone.burnerDefinitions, hasFlow, duplicateEloBurnerNames);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.closePasteAll();
        this.namePrefix = 'Burner 1-';
        this.startIndex = 1;
        this.pasteAllOppScore = 1;
        this.pasteAllLower = null;
        this.pasteAllUpper = null;
        this.pasteAllNotes = '';
      } catch (error) {
        showAndCommitError(error);
        zone.burnerDefinitions = [...tempBurners];
        store.state.hierarchyState.hierarchy.walkingOrders = tempWalkOrders;
      }
    }
  }

  private pasteBurnerDetails(burners: IBurnerDefinition[], hasFlow: boolean, duplicateEloBurnerNames: string[]): void {
    let index = this.startIndex;
    burners.forEach((detail) => {
      detail.burnerName = this.namePrefix + index;
      detail.lowerTolerance = this.pasteAllLower != null ? this.pasteAllLower : null;
      detail.upperTolerance = this.pasteAllUpper != null ? this.pasteAllUpper : null;
      detail.opportunityPriority = this.pasteAllOppScore;
      detail.eloName = (
        getZoneNamesFromRootToChild(detail.zoneKey)
          .reverse()
          .join('_') +
        '_' +
        detail.burnerName
      ).replace(/ /gi, '_');
      detail.notes = this.pasteAllNotes;
      if (hasFlow && this.isUniqueElo(detail.eloName)) {
        duplicateEloBurnerNames.push(detail.burnerName);
      }
      index++;
    });
  }

  private async clearBurner(): Promise<void> {
    const tempBurner = { ...this.burnerToDelete };
    const tempWalkOrders = cloneDeep(store.state.hierarchyState.hierarchy.walkingOrders);
    this.clearDetail();
    this.removeWalkingOrderForBurner(this.burnerToDelete);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      this.closeClear();
    } catch (error) {
      showAndCommitError(error);
      this.burnerToDelete = { ...tempBurner };
      store.state.hierarchyState.hierarchy.walkingOrders = tempWalkOrders;
    }
  }

  private removeWalkingOrderForBurner(burner: IBurnerDefinition): void {
    if (store.state.hierarchyState.hierarchy.walkingOrders) {
      store.state.hierarchyState.hierarchy.walkingOrders.forEach((walkOrder) => {
        let orderToDelete = -1;
        walkOrder.burnerOrder.forEach((burnerOrder) => {
          if (burnerOrder.burnerKey === burner.burnerKey) {
            orderToDelete = walkOrder.burnerOrder.indexOf(burnerOrder);
          }
          if (orderToDelete > -1) {
            burnerOrder.order -= 1;
          }
        });
        if (orderToDelete > -1) {
          store.commit('hierarchyState/removeOrder', { walkOrder, indexToDelete: orderToDelete });
        }
      });
    }
  }

  private closeClear(): void {
    this.showClearModal = false;
  }

  private clearDetail(): void {
    this.burnerToDelete.burnerName = null;
    this.burnerToDelete.lowerTolerance = null;
    this.burnerToDelete.upperTolerance = null;
    this.burnerToDelete.eloName = null;
    this.burnerToDelete.attributes = [];
    this.burnerToDelete.opportunityPriority = 1;
  }

  private openDelete(burner: IBurnerDefinition): void {
    this.burnerToDelete = burner;
    this.showDeleteModal = true;
  }

  private closeDelete(): void {
    this.showDeleteModal = false;
  }

  private async deleteBurner(): Promise<void> {
    await store.dispatch('hierarchyState/deleteBurner', this.burnerToDelete);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      this.closeDelete();
    } catch (error) {
      showAndCommitError(error);
      this.selectedBurnerDetails.push(this.burnerToDelete);
    }
  }

  @Watch('selectedBurnerDetails')
  private closeEdit(): void {
    if (!this.burnerToEdit.burner.burnerName) {
      this.burnerToDelete = this.burnerToEdit.burner;
      this.clearDetail();
    }
    this.burnerToEdit.index = -1;
  }

  @Watch('burnerToEdit.burner.burnerName')
  private setEloNameFromBurnerName(val: string, oldVal: string): void {
    if (this.burnerToEdit.index !== -1 && this.burnerToEdit.burner && this.burnerToEdit.burner.burnerName && oldVal) {
      this.burnerToEdit.burner.eloName = (
        getZoneNamesFromRootToChild(this.burnerToEdit.burner.zoneKey)
          .reverse()
          .join('_') +
        '_' +
        this.burnerToEdit.burner.burnerName
      ).replace(/ /gi, '_');
    }
  }
}
