// string utilities

export default class StringUtil {
  // determine str is null, undefined, or empty
  public static empty(str: string): boolean {
    return (!str || 0 === str.length);
  }

  // compare two strings for equality - null, undefined, and empty strings are euqal.
  public static equal(str1: string, str2: string, ignoreCase: boolean = true): boolean {
    if (this.empty(str1) && this.empty(str2)) {
      return true;
    }

    if ((this.empty(str1) && !this.empty(str2)) || (!this.empty(str1) && this.empty(str2))) {
      return false;
    }

    if (!ignoreCase) {
      return str1 === str2;
    }
    return str1.toUpperCase() === str2.toUpperCase();
  }

  public static isBoolean(str: string): boolean {
    return this.equal(str, 'true') ||
      this.equal(str, '1') ||
      this.equal(str, 'false') ||
      this.equal(str, '0');
  }
}
