export interface IParserError {
  line: number;
  error: string;
}

export class ParserError implements IParserError {
  public error: string;
  public line: number;

  constructor(line: number, error: string) {
    this.error = error;
    this.line = line;
  }
}
