


























import { Component, Vue, Prop, Emit, Model, Watch } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'search-bar'
})
export default class SearchBar extends Vue {
  @Model('input')
  public value?: string;
  @Prop({ default: 'Search' })
  public placeholder: string;
  @Prop({ default: false })
  private doValidation: boolean;
  @Prop({ default: '' })
  public validationRule: string | object;

  public localSearchTerm: string = null;

  public get searchTermIsEmpty(): boolean {
    return HelperMethods.isStringEmpty(this.localSearchTerm);
  }

  public onEnterPress(): void {
    if (this.doValidation && this.$validator.errors.any()) {
      return;
    }
    const input = this.$refs.searchInput as BFormInput;
    this.emitKeyPressEnter(input.localValue);
    input.focus();
  }

  public validate(): Promise<boolean> {
    return this.$validator.validateAll();
  }

  @Watch('value')
  private onValueChange(newValue: string): void {
    this.localSearchTerm = newValue;
  }

  @Watch('localSearchTerm')
  private onLocalSearchTermChange(newLocalSearch: string): void {
    this.emitSearchTerm(newLocalSearch);
  }

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitSearchTerm(val: string): void {}

  @Emit('enter-pressed')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private emitKeyPressEnter(currentText: string): void {}
}
