













import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';
import OnpointModal from '@/components/common/OnpointModal.vue';

Vue.use(BootstrapVue);

@Component({
  components: {OnpointModal}
})
export default class Error extends Vue {
  private store = store;

  get error(): any {
    return store.getters['errorState/getError'];
  }

  private clearError(): void {
    store.commit('errorState/clearError');
  }
}
