// attribute utilities
import {IAttribute} from '@/view-models/hierarchy-view-models';
import StringUtil from '@/utils/stringUtil';

export default class AttributeUtil {
  // determine if attribute type is valid
  public static dataTypeValidationError(attribute: IAttribute): string {
    if (attribute) {
      attribute.value = attribute.value?.trim();
      const { dataType, value, attributeType } = attribute;

      // when there is no data type selected, there is no value entered, or the attribute type is dynamic,
      // don't run the rest of the validation and return a blank string
      if (!dataType || !value || attributeType === 'DYNAMIC') {
        return '';
      }

      if (dataType === 'BOOLEAN' && !StringUtil.isBoolean(value)) {
        return 'Please enter a valid boolean value.';
      }

      const regexDate = /^((0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/](19|20)?[0-9]{2})*$/;
      if (dataType === 'DATE' && !regexDate.test(value)) {
        return 'Please enter a valid date value in the format MM/DD/YYYY';
      }

      if (dataType === 'NUMERIC' && !Number(value)) {
        return 'Please enter a valid number value.';
      }
    }
    return '';
  }
}
