














import {Component, Vue, Watch} from 'vue-property-decorator';
import AttributesTree from '../attributes/AttributesTree.vue';
import AssignAttributes from '../attributes/AssignAttributes.vue';
import {IBurnerDefinition, IZone} from '@/view-models/hierarchy-view-models';
import store from '../../store';
import {TreeActionTypeEnums} from '@/enums/treeActions';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import { cloneDeep } from 'lodash';

@Component({
  components: { AttributesTree, AssignAttributes }
})
export default class Attributes extends Vue {
  private currentSelection: IBurnerDefinition | IZone = {};
  private isBurner: boolean = false;
  private store = store;

  private get showComponents(): boolean | string {
    return (store.state.hierarchyState.hierarchy.assetKey &&
      store.state.hierarchyState.hierarchy.hierarchyName);
  }

  private handleLeafSelection(item: {zoneKey: string, burnerKey: string}): void {
    this.currentSelection = cloneDeep(store.getters['hierarchyState/getBurner'](item)[0]);
    this.isBurner = true;
  }

  private handleZoneSelection(item: {zoneKey: string, burnerKey: string}): void {
    this.currentSelection = cloneDeep(store.getters['hierarchyState/getZone'](item));
    this.isBurner = false;
  }

  private mounted(): void {
    hbEventBus.$on(TreeActionTypeEnums.LeafSelected, (item: { zoneKey: string; burnerKey: string; }) => {
      this.handleLeafSelection(item);
    });
    hbEventBus.$on(TreeActionTypeEnums.ZoneSelected, (item: { zoneKey: string; burnerKey: string; }) => {
      this.handleZoneSelection(item);
    });
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.ItemOpen);
    hbEventBus.$off(TreeActionTypeEnums.LeafSelected);
    hbEventBus.$off(TreeActionTypeEnums.ZoneSelected);
  }

  @Watch('store.state.hierarchyState.hierarchy')
  private refreshPage(): void {
    if ((this.currentSelection as IBurnerDefinition).burnerKey) {
      this.handleLeafSelection(
        {zoneKey: this.currentSelection.zoneKey, burnerKey: (this.currentSelection as IBurnerDefinition).burnerKey}
      );
    } else {
      this.handleZoneSelection(
        {zoneKey: this.currentSelection.zoneKey, burnerKey: ''}
      );
    }
  }
}
