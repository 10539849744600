




























































import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import Dropdown from '@/components/common/Dropdown.vue';
import NoBurnersMessage from '@/components/common/NoBurnersMessage.vue';
import CreateWalkingOrder from '@/components/walkOrder/CreateWalkingOrder.vue';
import {v4 as uuid} from 'uuid';
import {IWalkingOrder, IWalkingOrderItem, ITreeItem} from '@/view-models/hierarchy-view-models';
import OnpointModal from '../common/OnpointModal.vue';
import {cloneDeep} from 'lodash';
import { showAndCommitError, showError } from '@/utils/Helpers';

@Component({
  components: { CreateWalkingOrder, Dropdown, NoBurnersMessage, OnpointModal }
})
export default class WalkingOrders extends Vue {
  private walkingOrderFields = [
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'burners',
      label: 'Burners Assigned'
    },
    {
      key: 'menu',
      label: ''
    }
  ];
  private isCreate: boolean = false;
  private store = store;
  private showDeleteModal: boolean = false;
  private orderToDelete = {};
  private showComponents: boolean = this.burnerHasCount;

  private get walkingOrderItems(): IWalkingOrderItem[] {
    const items: IWalkingOrderItem[] = [];
    for (const item of store.state.hierarchyState.hierarchy.walkingOrders) {
      items.push({
        name: item.name,
        status: item.status,
        burners: item.burnerOrder.length + '/' + this.burnersWithDetails + ' burners assigned',
        menu: '',
        key: item.walkingOrderKey
      });
    }
    return items;
  }

  private get burnersWithDetails(): number {
    let burnersWithDetails = 0;
    const zones = store.state.hierarchyState.hierarchy.zones;
    for (const zone of zones) {
      const burners = zone.burnerDefinitions;
      if (burners && burners.length) {
        for (const burner of burners) {
          if (burner.burnerName) {
            burnersWithDetails++;
          }
        }
      }
    }
    return burnersWithDetails;
  }

  private get burnerHasCount(): boolean {
    let isBurnerCount = false;
    for (const item of this.treeItems) {
      if (item.burnerCount && item.burnerCount > 0) {
        isBurnerCount = true;
      }
    }
    return isBurnerCount;
  }

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/treeItems']();
  }

  private createWalkOrder(): void {
    if (store.state.hierarchyState.hierarchy.walkingOrders &&
        store.state.hierarchyState.hierarchy.walkingOrders.length < 1) {
      this.isCreate = true;
      store.commit('hierarchyState/updateSelectedWalkingOrder',
        {
          walkingOrderKey: uuid(),
          status: 'Draft',
          name: '',
          burnerOrder: []
        } as IWalkingOrder
      );
    } else {
      showError('You can only have one walking order at this time.');
    }
  }

  private editWalkingOrder(key: string): void {
    this.isCreate = true;
    const walkingOrder = store.state.hierarchyState.hierarchy.walkingOrders.find(
      (walkOrder) => walkOrder.walkingOrderKey === key
    );
    if (walkingOrder) {
      const tempOrder = cloneDeep(walkingOrder);
      store.commit(
        'hierarchyState/updateSelectedWalkingOrder',
        tempOrder
      );
    }
  }

  private openDelete(orderToDelete: string): void {
    this.showDeleteModal = true;
    this.orderToDelete = orderToDelete;
  }

  private closeDelete(): void  {
    this.showDeleteModal = false;
  }

  private async deleteWalkingOrder(): Promise<void> {
    const walkingOrder = store.state.hierarchyState.hierarchy.walkingOrders.find(
      (walkOrder) => walkOrder.walkingOrderKey === this.orderToDelete
    );
    const temp = {...walkingOrder} as IWalkingOrder;
    store.commit('hierarchyState/deleteWalkingOrder', walkingOrder);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      this.showDeleteModal = false;
    } catch (error) {
      showAndCommitError(error);
      store.state.hierarchyState.hierarchy.walkingOrders.push(temp);
    }
  }
}
