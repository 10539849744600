








































































































import { Component, Vue } from 'vue-property-decorator';
import ElementList from './ElementList.vue';
import RootZoneGridView from './RootZoneGridView.vue';
import AssetTree from '@/components/common/AssetTree.vue';
import ImportHierarchyModal from '@/components/hierarchy/modals/ImportHierarchyModal.vue';
import Dropdown from '../common/Dropdown.vue';
import OnpointModal from '../../components/common/OnpointModal.vue';
import FormField from '../../components/common/FormField.vue';
import { ITreeItem, IZone, IUpdateZoneDetails, ITreeLiaison } from '@/view-models/hierarchy-view-models';
import {TreeActionTypeEnums} from '@/enums/treeActions';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import { cloneDeep } from 'lodash';
import store from '../../store';
import { showAndCommitError, showError } from '@/utils/Helpers';
import {exportHierarchy, exportBurnerTypes} from '@/components/hierarchy/modals/export-parser';
import * as moment from 'moment';
import {IAsset} from '@/view-models/assets-view-models';

@Component({
  components: { ElementList, AssetTree, RootZoneGridView , Dropdown, OnpointModal, FormField, ImportHierarchyModal}
})
export default class Hierarchy extends Vue {
  private store = store;
  private treeView = false;
  private showDetailsModal = false;
  private showExportModal = false;
  private enableExportBurnerTypes = true;
  private enableExportHierarchy = true;
  private opportunityPriority: number = this.fullRootZoneData && this.fullRootZoneData.opportunityPriority ?
    this.fullRootZoneData.opportunityPriority :
    1;
  private opportunityScoreType: string = this.fullRootZoneData && this.fullRootZoneData.opportunityScoreType ?
      this.fullRootZoneData.opportunityScoreType :
      null;
  private lowerTolerance: number = this.fullRootZoneData && this.fullRootZoneData.lowerTolerance ?
    this.fullRootZoneData.lowerTolerance :
    null;
  private upperTolerance: number = this.fullRootZoneData && this.fullRootZoneData.upperTolerance ?
    this.fullRootZoneData.upperTolerance :
    null;
  private notes: string = this.fullRootZoneData && this.fullRootZoneData.notes ?
    this.fullRootZoneData.notes :
    '';
  private showDeleteModal = false;

  private openDeleteModal(): void {
    this.showDeleteModal = true;
  }

  private hideDeleteModal(): void {
    this.showDeleteModal = false;
  }

  private hideDetailsModal(): void {
    this.showDetailsModal = false;
  }

  private openDetailsModal(): void {
      this.showDetailsModal = true;
  }

  private async submitDetails(): Promise<void> {
    if (!this.opportunityPriority) {
      showError('Missing value for required field.');
      return;
    }
    const zoneDetails: IUpdateZoneDetails = {
      opportunityPriority: this.opportunityPriority,
      opportunityScoreType: this.opportunityScoreType,
      lowerTolerance: this.lowerTolerance,
      upperTolerance: this.upperTolerance,
      notes: this.notes || '',
      zoneKey: this.treeItems[0].associatedZoneKey || '',
      treeItemKey: this.treeItems[0].key
    };

    const prevOppScore = this.fullRootZoneData.opportunityPriority || 1;
    const prevOppScoreType = this.fullRootZoneData.opportunityScoreType;
    const prevLowerTolerance = this.fullRootZoneData.lowerTolerance;
    const prevUpperTolerance = this.fullRootZoneData.upperTolerance;
    const prevNotes = this.fullRootZoneData.notes || '';

    store.commit('hierarchyState/updateSubzone', zoneDetails);
    this.hideDetailsModal();

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      hbEventBus.$emit(TreeActionTypeEnums.UpdateZoneDetails, this.treeItems[0]);
    } catch (error) {
      showAndCommitError(error);
      const prevDetails: IUpdateZoneDetails = {
          opportunityPriority: prevOppScore,
          opportunityScoreType: prevOppScoreType,
          lowerTolerance: prevLowerTolerance,
          upperTolerance: prevUpperTolerance,
          notes: prevNotes || '',
          zoneKey: this.treeItems[0].associatedZoneKey,
          treeItemKey: this.treeItems[0].key
        };
      store.commit('hierarchyState/updateSubzone', prevDetails);

      // revert v-model values for the details modal
      this.opportunityPriority = prevOppScore;
      this.opportunityScoreType = prevOppScoreType;
      this.lowerTolerance = prevLowerTolerance;
      this.upperTolerance = prevUpperTolerance;
      this.notes = prevNotes;
    }
  }

  private async handleDeleteSubzone(): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    store.commit('hierarchyState/clearHierarchy');
    this.showDeleteModal = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private toggleIsOpen(item: ITreeItem): void {
    store.commit('hierarchyState/toggleZone', item.key);
  }

  private addRootZone(item: ITreeItem): void {
    if (!this.isOpen) {
      this.toggleIsOpen(item);
    }

    const emitOb: ITreeLiaison = {
      source: 'newSubZone',
      target: item
    };
    store.commit('hierarchyState/updateHierarchySubzones', emitOb);
  }

  private goToTreeView(): void {
    this.treeView = true;
  }

  private goToZoneView(): void {
    if (this.largestZoneDepth > 3) {
      showError('Nesting is too deep to change views.');
      return;
    }
    this.treeView = false;
  }

  private getTreeItemByKey(key: string): ITreeItem {
    return this.treeItems.find((item) => {
      return key === item.key;
    });
  }

  private get largestZoneDepth(): number {
    let largestDepth = 0;

    this.treeItems.forEach((item) => {
      if (item.type === 'Leaf') { return; }
      const itemDepth = this.calculateDepth(0, item);
      if (itemDepth > largestDepth) {
        largestDepth = itemDepth;
      }
    });

    return largestDepth;
  }

  private calculateDepth(depth: number, item: ITreeItem): number {
    if (!item || !item.parentKey) { return depth; }
    const parentItem = this.getTreeItemByKey(item.parentKey);
    return this.calculateDepth(depth + 1, parentItem);
  }

  private get showComponents(): boolean | string {
    return (store.state.hierarchyState.hierarchy.assetKey &&
      store.state.hierarchyState.hierarchy.hierarchyName);
  }

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/treeItems']();
  }

  private get assetName(): string {
    return store.state.hierarchyState.hierarchy.assetName;
  }

  private get currentPage(): string {
    return this.$route?.name;
  }

  private get fullRootZoneData(): IZone {
    return store.state.hierarchyState.hierarchy.zones[0];
  }

  private get isOpen(): boolean {
    const root = store.getters['hierarchyState/treeItems']()[0];
    return store.state.hierarchyState.openZones.includes(root.key);
  }

  private mounted(): void {
    this.$root.$on('bv::modal::show', (unused1: any, unused2: string) => {
      hbEventBus.$emit(TreeActionTypeEnums.UpdateZoneDetails, this.treeItems[0]);
    });

    hbEventBus.$emit(TreeActionTypeEnums.UpdateZoneDetails, this.treeItems[0]);
    hbEventBus.$on(TreeActionTypeEnums.UpdateZoneDetails, this.updateRootZoneData);

    // when nesting is too deep, start in tree view
    if (this.largestZoneDepth > 3) {
      this.treeView = true;
    }
  }

  private updateRootZoneData(item: ITreeItem): void {
    if (item && item.key === this.treeItems[0].key) {
      this.opportunityPriority = this.fullRootZoneData &&
        this.fullRootZoneData.opportunityPriority ? this.fullRootZoneData.opportunityPriority : 1;
      this.opportunityScoreType = this.fullRootZoneData &&
        this.fullRootZoneData.opportunityScoreType ? this.fullRootZoneData.opportunityScoreType : null;
      this.lowerTolerance = this.fullRootZoneData &&
        this.fullRootZoneData.lowerTolerance ? this.fullRootZoneData.lowerTolerance : null;
      this.upperTolerance = this.fullRootZoneData &&
        this.fullRootZoneData.upperTolerance ? this.fullRootZoneData.upperTolerance : null;
    }
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.UpdateZoneDetails);
  }

  private showImportHierarchyModal(): void {
    const importModal = this.$refs.importHierarchyModal as ImportHierarchyModal;
    importModal.clearUpload();
    this.$bvModal.show('import-hierarchy-modal');
  }

  get isImportEnabled(): string {
    return store.state.hierarchyState.hierarchy.assetKey;
  }

  private hideExportModal(): void {
    this.showExportModal = false;
  }

  private disableOkButton(): boolean {
    return !this.enableExportBurnerTypes && !this.enableExportHierarchy;
  }

  private displayExportModal(): void {
    this.enableExportBurnerTypes = true;
    this.enableExportHierarchy = true;
    this.showExportModal = true;
  }

  private exportSelections(): void {
    if (this.enableExportBurnerTypes) {
      this.exportBurnerTypesCSV();
    }
    if (this.enableExportHierarchy) {
      this.exportHierarchyCSV();
    }
    this.hideExportModal();
  }

  private exportHierarchyCSV(): void {
    const exportedHierarchy = exportHierarchy(store.state.hierarchyState.hierarchy);
    const blob = new Blob([exportedHierarchy], { type: 'text/csv' });
    const link = document.createElement('a');
    const hierarchyName = store.state.hierarchyState.hierarchy.hierarchyName;
    const timeStamp = (moment(new Date())).format('MM-DD-YYYY_HH-mm-ss');
    link.href = URL.createObjectURL(blob);
    link.download = hierarchyName + '_' + timeStamp + '_' + 'export.csv';
    link.click();
    URL.revokeObjectURL(link.href);
  }

  private exportBurnerTypesCSV(): void {
    const asset: IAsset = store.state.hierarchyState.assets
        .find((x: IAsset) => x.key === store.state.hierarchyState.hierarchy.assetKey);
    const exportedBurnerTypes = exportBurnerTypes(asset);
    const blob = new Blob([exportedBurnerTypes], { type: 'text/csv' });
    const link = document.createElement('a');
    const assetName = asset.equipmentName;
    const timeStamp = (moment(new Date())).format('MM-DD-YYYY_HH-mm-ss');
    link.href = URL.createObjectURL(blob);
    link.download = assetName + '_' + timeStamp + '_' + 'burner_types.csv';
    link.click();
    URL.revokeObjectURL(link.href);
  }
}
