import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import {IHierarchyDefiniton, IZone} from '@/view-models/hierarchy-view-models';

export class HierarchyService extends BaseService {
  constructor(axios: AxiosInstance, hbApiUrl: string) {
    super(hbApiUrl, axios);
  }

  public async saveHierarchy(hierarchy: IHierarchyDefiniton): Promise<IHierarchyDefiniton> {
    return this.httpClient.post<IHierarchyDefiniton>('hierarchies', hierarchy);
  }

  public async getActiveCustomerHierarchies(customerKey: string): Promise<IHierarchyDefiniton[]> {
    return this.httpClient.get<IHierarchyDefiniton[]>('customers/' + customerKey + '/hierarchies');
  }

  public async getHierarchyZones(customerKey: string, assetKey: string, hierarchyKey: string): Promise<IZone[]> {
    return this.httpClient.get<IZone[]>(
      'customers/' + customerKey + '/assets/' + assetKey + '/hierarchies/' + hierarchyKey + '/zones');
  }

  public async deleteHierarchy(hierarchyKey: string): Promise<number> {
    return this.httpClient.delete<number>('hierarchies/' + hierarchyKey);
  }
}
