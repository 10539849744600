












import { Component, Vue } from 'vue-property-decorator';
import store from '../../store';
import BurnerDetails from '../burners/BurnerDetails.vue';
import BurnersTree from '../burners/BurnersTree.vue';
import NoBurnersMessage from '../common/NoBurnersMessage.vue';
import { IBurnerDefinition } from '../../view-models/hierarchy-view-models';
import { ITreeItem } from '@/view-models/hierarchy-view-models';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';

@Component({
  components: { BurnersTree, BurnerDetails, NoBurnersMessage }
})
export default class Burners extends Vue {
  private currentZone: string = '';
  private currentTypeKey: string = '';

  private get showComponents(): boolean {
    let isBurnerCount = false;
    for (const item of this.treeItems) {
      if (item.burnerCount && item.burnerCount > 0) {
        isBurnerCount = true;
      }
    }
    return isBurnerCount;
  }

  private get burnerDetails(): IBurnerDefinition[] {
    const getBurnersByZoneAndType = store.getters['hierarchyState/getBurnersByZoneAndType'];
    return getBurnersByZoneAndType({subzone: this.currentZone, typeKey: this.currentTypeKey });
  }

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/treeItems']();
  }

  public handleSelection(item: any): void {
    this.currentZone = item.zoneKey;
    this.currentTypeKey = item.treeItem.burnerTypeKey;
  }

  private mounted(): void {
    hbEventBus.$on(TreeActionTypeEnums.LeafSelected, (item: ITreeItem) => {
      this.handleSelection(item);
    });
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.ItemOpen);
    hbEventBus.$off(TreeActionTypeEnums.LeafSelected);
  }
}
