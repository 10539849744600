import { IZone } from '@/view-models/hierarchy-view-models';
import { IParserError } from '@/view-models/parser-error';

export interface IParserResult {
  zones: IZone[];
  errors: IParserError[];
}

export class ParserResult implements IParserResult {
  public zones: IZone[];
  public errors: IParserError[];

  constructor(zones: IZone[], errors: IParserError[]) {
    this.zones = zones;
    this.errors = errors;
  }
}
