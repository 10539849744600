















import { Component, Vue } from 'vue-property-decorator';
import ElementListItem from './ElementListItem.vue';
import store from '../../store';
import ConfigFactory from '../../services/config-factory';
import { IBurnerViewModel } from '@/view-models/burner-view-model';

@Component({
  components: { ElementListItem }
})
export default class ElementList extends Vue {
  private customerAssetLink: string = '';
  private store = store;

  private async mounted(): Promise<void> {
    // gets link for customer asset editor in different environments
    const conf = await ConfigFactory.GetConfig();
    const redirectUri = process.env.VUE_APP_PORTAL_API_URL ?
      process.env.VUE_APP_PORTAL_API_URL :
      conf.get('redirectUriParent');
    this.customerAssetLink = redirectUri + '/utilities/ember/asset-editor/';
  }

  private get burnerTypes(): void {
    const burnersFromAsset = store.getters['hierarchyState/getBurners'];
    return burnersFromAsset.map((burner: IBurnerViewModel) => {
      return {
        name: burner.burnerName,
        burnerTypeKey: burner.burnerKey,
        description: burner.burnerDetail.zoneType
      };
    });
  }
}
