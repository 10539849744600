









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NoBurnersMessage extends Vue {
  @Prop({required: true})
  private messageType: string;
  @Prop({required: true})
  private link: string;

  private messages = {
    config: {
      line1: 'There are no Burner Configurations for this Hierarchy.',
      line2: 'Add all Burner Configurations to the correct Zones in the Hierarchy via the Hierarchy Tab.',
      linkText: 'Burner Configurations',
    },
    details: {
      line1: 'There are no saved Burner Details for this Hierarchy.',
      line2: 'Add Burner Details to all Burner Configurations via the Burners Tab.',
      linkText: 'Burner Details',
    }
  };

  private onLinkClick(): void {
    this.$router.push(this.link);
  }
}
