import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IAsset, IEquipmentKey } from '../view-models/assets-view-models';

export class KESCustomerAssetService extends BaseService {
  constructor(axios: AxiosInstance, caeApiUrl: string) {
    super(caeApiUrl, axios);
  }

  public async generateEquipmentKeyPair(): Promise<IEquipmentKey> {
    return this.httpClient.get<IEquipmentKey>('assets/equipment-keypair');
  }

  public async getActiveCustomerAssets(customerKey: string): Promise<IAsset[]> {
    return this.httpClient.get<IAsset[]>('customers/' + customerKey + '/asset-details');
  }

  public async saveAsset(asset: IAsset): Promise<IAsset> {
    return this.httpClient.post<IAsset>('assets', asset);
  }
}
