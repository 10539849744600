import Vue from 'vue';
import Vuex from 'vuex';
import { AppStore, IAppStoreState } from './app/appStore';
import { AssetStore, IAssetStoreState } from './assetStore/assetStoreHB';
import { ErrorStore, IErrorStoreState } from './errorStore/errorStore';
import {NavStore, INavStoreState} from './navStore/navStore';
import { HierarchyStore, IHierarchyStoreState } from './hierarchyStore/hierarchyStore';

Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  assetState: IAssetStoreState;
  hierarchyState: IHierarchyStoreState;
  errorState: IErrorStoreState;
  navState: INavStoreState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    asset: AssetStore,
    hierarchyState: HierarchyStore,
    errorState: ErrorStore,
    navState: NavStore
  }
});
