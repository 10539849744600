export interface IErrorBusMessage {
  errorTag: string;
  error: any;
  sendLog: boolean; // default true,
  showMessage: boolean; // default true
}

export interface IErrorMessageConfig {
  sendLog?: boolean;
  showMessage?: boolean;
}

export class ErrorBusMessage implements IErrorBusMessage {
  public errorTag: string;
  public error: any;
  public sendLog: boolean = true;
  public showMessage: boolean = true;

  constructor(errorTag: string, error: any, config?: IErrorMessageConfig) {
    this.errorTag = errorTag;
    this.error = error;
    this.sendLog = config && config.sendLog != null ? config.sendLog : this.sendLog;
    this.showMessage = config && config.showMessage != null ? config.showMessage : this.showMessage;
  }
}
