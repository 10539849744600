











import { Component, Vue } from 'vue-property-decorator';
import AssetTree from '../../components/common/AssetTree.vue';
import store from '../../store';
import { ITreeItem } from '@/view-models/hierarchy-view-models';

@Component({
  components: {AssetTree}
})
export default class AttributesTree extends Vue {
  private store = store;

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/attributesTreeItems']();
  }

  private get currentPage(): string {
    return this.$route?.name;
  }
}
