



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ITreeItem, IEmitBurnerCountObject } from '../../view-models/hierarchy-view-models';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import { cloneDeep } from 'lodash';
import OnpointModal from '../common/OnpointModal.vue';
import store from '../../store';
import { showAndCommitError, showError } from '@/utils/Helpers';

@Component({
  components: { OnpointModal }
})
export default class SubzoneItem extends Vue {
  @Prop({ required: true })
  private item: ITreeItem;
  private store = store;
  private editBurners: boolean = false;
  private showDeleteModal: boolean = false;
  private showPreventDeleteModal: boolean = false;
  private burnerCountInput: number = this.item.burnerCount || 0;

  private mounted(): void {
    hbEventBus.$on(TreeActionTypeEnums.UpdateBurnerInputs, () => {
      this.burnerCountInput = this.item.burnerCount ? this.item.burnerCount : 0;
    });
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.UpdateBurnerInputs);
  }

  private showEditBurners(): void {
    this.editBurners = true;
    this.$nextTick().then(() => {
      const input: HTMLInputElement = this.$refs.burnerInput as HTMLInputElement;
      input.focus();
    });
  }

  private async submitBurners(item: ITreeItem): Promise<void> {
    // don't allow burners to be removed --- must be removed via burners page
    const zone = store.state.hierarchyState.hierarchy.zones.find((z) => z.zoneKey === item.associatedZoneKey);
    let typeMatch = [];
    if (zone && zone.burnerDefinitions) {
      typeMatch = zone.burnerDefinitions.filter((def) => def.burnerTypeName === item.burnerTypeName);
    }
    if (this.burnerCountInput && this.burnerCountInput < typeMatch.length) {
      this.showPreventDeleteModal = true;
      this.editBurners = false;
      this.burnerCountInput = typeMatch.length;
      return;
    }

    if ((!this.burnerCountInput && this.burnerCountInput !== 0) ||
      this.burnerCountInput > 1000 || this.burnerCountInput < 0) {
      showError('Please enter a valid value.');
      return;
    }

    // store previous count before state is updated
    const prevBurnerCount = item.burnerCount;
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    const burnerObject: IEmitBurnerCountObject = {
      item,
      count: this.burnerCountInput
    };

    await store.dispatch('hierarchyState/generateBurners', burnerObject);
    this.editBurners = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      const revertEmitObject = {
          item,
          count: prevBurnerCount
        };
      await store.dispatch('hierarchyState/generateBurners', revertEmitObject);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private async deleteItem(): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    this.showDeleteModal = false;
    await store.dispatch('hierarchyState/handleDeleteLeaf', this.item);

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private hidePreventDeleteModal(): void {
    this.showPreventDeleteModal = false;
  }

  private openDeleteModal(): void {
    this.showDeleteModal = true;
  }

  private hideDeleteModal(): void {
    this.showDeleteModal = false;
  }

  private get burnerString(): string {
    return `${this.item.burnerCount} Burners`;
  }
}

