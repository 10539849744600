














































































import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { exportHierarchy, IColumn } from '../hierarchy/modals/export-parser';
import SearchBar from '../common/SearchBar.vue';
import AssetProperty from '../common/AssetProperty.vue';
import { isEqual } from 'lodash';
import { Route } from 'vue-router';
import OnPointModal from '../common/OnpointModal.vue';
import { parseFile } from '../hierarchy/modals/import-parser';
import { IParserResult } from '@/view-models/parser-result';
import { showAndCommitError } from '@/utils/Helpers';
import { BTable } from 'bootstrap-vue';

@Component({
  components: { SearchBar, AssetProperty, OnPointModal },
  beforeRouteLeave(to, from, next): void {
    const self = this as QuickEdits;
    if (self.isDirty) {
      self.onLeavePrompt();
      self.attemptedLeaveRoute = to;
      next(false);
    } else {
      next();
    }
  }
})
export default class QuickEdits extends Vue {
  public rowParsed: IColumn[] = [];
  public rowFilter = '';
  public attemptedLeaveRoute: Route = null;
  public originalValues = new Map<string, IColumn>();
  public modifiedValues = new Map<string, IColumn>();
  public isDirty = false;
  public unexpectedError: IParserResult = {} as IParserResult;
  public tableRef = {} as BTable;
  public isSaved = false;
  public showPreviousValues = false;
  public rowFields = [
    {
      key: 'Parent',
      label: 'Parent',
      sortable: true,
      sortDirection: 'asc'
    },
    {
      key: 'Name',
      sortable: true,
      label: 'Name'
    },
    {
      key: 'AttributeValue',
      label: 'Value',
      thClass: 'value-col'
    }
  ];

  get hierarchy() {
    return exportHierarchy(store.state.hierarchyState.hierarchy).split('\n');
  }

  public async mounted() {
    this.tableRef = this.$refs.editHierarchyTableRef as BTable;
    const storageShowPrevious = window.localStorage.getItem('hb:quickedits:showPrevious');
    this.showPreviousValues = (storageShowPrevious === 'true');
    this.initiatialize();
  }

  public initiatialize() {
    this.originalValues.clear();
    this.modifiedValues.clear();
    // parse hierarchy to table format
    this.rowParsed = this.parseHierarchyToTable();
  }

  public onLeavePrompt() {
    this.$bvModal.show('hierarchy-edits');
  }

  public close(id: string) {
    this.$bvModal.hide(id);
  }

  public toggleShowPreviousValue(flag: boolean): void {
    window.localStorage.setItem('hb:quickedits:showPrevious', flag.toString());
    this.showPreviousValues = flag;
  }

  public async save() {
    // fetch hiearchy from network and merge
    // await this.getLatestHierarchy();
    // table data and exported hierarchy.

    store.commit('app/updateIsLoading', true);
    for (const value of Array.from(this.modifiedValues.values())) {
      this.originalValues.set(`${value.Parent}-${value.ID}`, value);
    }

    const originalValueArray = Array.from(this.originalValues.values());
    const formatedValues = originalValueArray.map((item) => [
      item.Parent,
      item.ID,
      item.Name,
      item.ObjectType,
      item.OpportunityScoreType,
      item.OpportunityScoreMultiplier,
      item.LowerTolerance,
      item.UpperTolerance,
      item.BurnerTypeID,
      item.AttributeDirection,
      item.AttributeType,
      item.AttributeDataType,
      item.AttributeValue,
      item.AttributeTagName,
      item.Notes
    ]);

    const parsedResults: IParserResult = parseFile(formatedValues.join('\r\n'));

    if (parsedResults.errors.length > 0) {
      this.$bvModal.show('unexpected-error');
      this.unexpectedError = parsedResults;
    } else {
      const tempHierarchy: any = {...store.state.hierarchyState.hierarchy};
      tempHierarchy.zones = parsedResults.zones;

      // update the hierarchy
      store.commit('hierarchyState/updateHierarchy', tempHierarchy);

      try {
        await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
        this.$bvModal.show('hierarchy-update-success');
        this.initiatialize();
        this.isDirty = false;
        this.unexpectedError= {} as IParserResult;
        this.isSaved = true;
        await this.tableRef.refresh();
      } catch (error) {
        showAndCommitError(error);
      } finally {
        this.isSaved = false;
        store.commit('app/updateIsLoading', false);
      }
    }
  }

  public leave() {
    this.isDirty = false;
    this.$router.push(this.attemptedLeaveRoute?.path);
  }

  public filterRows(value: string) {
    this.rowFilter = value;
  }

  public async getLatestHierarchy() {
    store.dispatch('hierarchyState/retrieveHierarchies');
  }

  public valueChange(item: IColumn, value) {
    const modifiedItem = {...item};
    modifiedItem.AttributeValue = value;
    this.modifiedValues.set(`${item.Parent}-${item.ID}`, modifiedItem);
    this.isDirty = this.valuesModified();
  }

  public valuesModified() {
    for (const value of Array.from(this.modifiedValues.values())) {
      const original = this.originalValues.get(`${value.Parent}-${value.ID}`);

      if (!isEqual(value, original)) {
        return true;
      }
    }
    return false;
  }

  public parseHierarchyToTable() {
    // pattern to ignore comma inside parenthesis.
    const pattern = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
    // try to spread array items to type.
    const rows: string[][] = this.hierarchy.filter((f) => f !== '').map((value) => {
      return value.split(pattern);
    });

    const rowParsed: IColumn[] = rows.map((value) => {
      const [
        Parent,
        ID,
        Name,
        ObjectType,
        OpportunityScoreType,
        OpportunityScoreMultiplier,
        LowerTolerance,
        UpperTolerance,
        BurnerTypeID,
        AttributeDirection,
        AttributeType,
        AttributeDataType,
        AttributeValue,
        AttributeTagName,
        Notes ] = value;

      return (
      {
        Parent,
        ID,
        Name,
        ObjectType,
        OpportunityScoreType,
        OpportunityScoreMultiplier,
        LowerTolerance,
        UpperTolerance,
        BurnerTypeID,
        AttributeDirection,
        AttributeType,
        AttributeDataType,
        AttributeValue,
        AttributeTagName,
        Notes
      } as IColumn);
    });

    rowParsed.forEach((value) => {
      this.originalValues.set(`${value.Parent}-${value.ID}`, value);
    });
    rowParsed.shift();
    return rowParsed.filter((value) =>
                         (value.AttributeDataType === 'NUMERIC' || value.AttributeDataType === 'BOOLEAN') &&
                         value.AttributeType === 'STATIC' &&
                         value.AttributeDirection === 'INPUT'
                         );
  }
}

