














































import VueRouter, { Route } from 'vue-router';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import PageNotFound from './components/pageNotFound/PageNotFound.vue';
import HomePage from './components/homePage/HomePage.vue';
import Error from './components/common/Error.vue';
import Loading from './components/common/Loading.vue';
import Header from './components/header/Header.vue';
import Hierarchy from './components/hierarchy/Hierarchy.vue';
import Attributes from './components/attributes/Attributes.vue';
import { authGuard } from './services/auth';
import {Vue, Component} from 'vue-property-decorator';
import store from './store';
import VeeValidate from './vee-validate';
import Burners from './components/burners/Burners.vue';
import WalkingOrders from './components/walkOrder/WalkingOrders.vue';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import OnpointModal from '@/components/common/OnpointModal.vue';
import {TreeActionTypeEnums} from '@/enums/treeActions';
import { cloneDeep } from 'lodash';
import { appRouteBase } from './utils/FromParent';
import HierarchyPage from './components/hierarchy/HierarchyPage.vue';
import DataMapping from './components/dataMapping/DataMapping.vue';
import QuickEdits from './components/QuickEdits/QuickEdits.vue';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VeeValidate);

const routes = [
  { path: '/', component: HomePage },
  { path: '/create',
    component: HierarchyPage,
    name: 'hierarchy-creation-page'
  },
  { path: '/hierarchy/:key',
    name: 'hierarchy-page',
    component: HierarchyPage,
    children: [
      { path: 'hierarchy', name: 'hierarchy', component: Hierarchy },
      { path: 'data-mapping', name: 'data-mapping', component: DataMapping },
      { path: 'burners', name: 'burners', component: Burners },
      { path: 'attributes', name: 'attributes', component: Attributes },
      { path: 'walking-order', name: 'walking-order', component: WalkingOrders },
      { path: 'quick-edits', name: 'quick-edits', component: QuickEdits},
    ]},
  { path: '*', component: PageNotFound }
];

export const hierarchyBuilderRouter = new VueRouter({
  mode: 'history',
  base: appRouteBase(),
  routes
});

hierarchyBuilderRouter.beforeEach(authGuard);

@Component({
  name: 'app',
  router: hierarchyBuilderRouter,
  components: {OnpointModal, Loading, Error, Header, Hierarchy }
})
export default class App extends Vue {
  public store = store;

  private created(): void {
    this.$router.afterEach(() => {
      store.commit('app/updateIsLoading', false);
    });
    this.clearEventBus();
  }

  private deactivated(): void {
    // Get rid of old vue instance
    this.$destroy();
    // Used when integrated into parent app
    store.commit('app/updateIsLoading', true);
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$off(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }

  private mounted(): void {
    // Handle route changes from parent
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$on(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
    store.commit('asset/selectSite', null);
    store.commit('asset/selectAsset', null);
  }

  private handleRouteChange(parentRoute: Route): void {
    if (parentRoute.path.startsWith(appRouteBase())) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(appRouteBase(), '/');
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  private closeCloneModal(): void {
    store.commit('hierarchyState/updateHierarchy', cloneDeep(store.state.hierarchyState.prevHierarchy));
    store.commit('hierarchyState/toggleCloneModal');
  }

  private submitCloneModal(): void {
    hbEventBus.$emit(TreeActionTypeEnums.ConfirmHierarchyClone);
  }

  get isLoading(): boolean {
    return store.getters['app/isLoading'];
  }

  private clearEventBus(): void {
    hbEventBus.$off();
  }

  private closeImportSuccess(): void {
    this.$bvModal.hide('import-success');
  }

  private closeHierarchySaveSuccess(): void {
    this.$bvModal.hide('hierarchy-update-success');
  }
}
