





















import {Vue, Component, Prop} from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'onpoint-modal'
})
export default class OnpointModal extends Vue {
  @Prop({required: true})
  private id?: string;
  @Prop({required: true})
  private isOpen: boolean = false;
  @Prop({required: false})
  private title?: string;
  @Prop({required: false})
  private buttonOk?: string;
  @Prop({required: false})
  private buttonCancel?: string;
  @Prop({required: false})
  private onOk?: () => {};
  @Prop({required: false, type: Function, default: (): boolean => false })
  private okDisabled?: () => boolean;
  @Prop({required: false})
  private onCancel?: () => {};
  @Prop({required: false})
  private onClose?: () => {};
}
