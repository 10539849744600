







import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header/Header.vue';
import store from '@/store';
import { showAndCommitError, showError } from '@/utils/Helpers';
import { IHierarchyDefiniton } from '@/view-models/hierarchy-view-models';
import { cloneDeep } from 'lodash';
import HelperMethods from '@/shared/helper-methods';
import { IHierarchyStoreState } from '@/store/hierarchyStore/hierarchyStore';
import Loading from '../common/Loading.vue';

@Component({
  name: 'hierarchy-page',
  components: {
    Header,
    Loading
  }
})
export default class HierarchyPage extends Vue {
  private loading: number = 0;

  private get hierarchyState(): IHierarchyStoreState {
    return store.state.hierarchyState;
  }

  private getHierarchyByKey(key: string): IHierarchyDefiniton {
    return this.hierarchyState.hierarchies.find((hierarchy) => hierarchy.key === key);
  }

  private async mounted(): Promise<void> {
    const hierarchyKey = this.$route.params.key;
    if (HelperMethods.isArrayEmpty(this.hierarchyState.hierarchies)) {
      this.loading++;
      try {
        await store.dispatch('hierarchyState/retrieveServiceConfig');
        await store.dispatch('hierarchyState/retrieveAssets');
        await store.dispatch('hierarchyState/retrieveHierarchies');
      } catch (error) {
        showError(error.response ? error.response.data.message : error.message);
      } finally {
        this.loading--;
      }
    }
    const hierarchy: IHierarchyDefiniton = this.getHierarchyByKey(hierarchyKey);
    if (!hierarchy) {
      return;
    }

    if (!hierarchy.zones || !hierarchy.zones.length) {
      this.loading++;
      try {
        await store.dispatch('hierarchyState/retrieveZones', hierarchy);
      } catch (error) {
        showError(error.response ? error.response.data.message : error.message);
      } finally {
        this.loading--;
      }
    }

    store.commit('hierarchyState/updateHierarchy', hierarchy);
    store.commit('hierarchyState/updatePrevHierarchy', cloneDeep(hierarchy));
  }

  private async createHierarchy(newHierarchy: IHierarchyDefiniton): Promise<void> {
    store.commit('hierarchyState/updateHierarchy', newHierarchy);
    store.commit('hierarchyState/initRootZone');
    this.loading++;
    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      const hierarchyKey = store.state.hierarchyState.hierarchy.key;
      await this.$router.replace(`hierarchy/${hierarchyKey}/hierarchy`);
    } catch (error) {
      showAndCommitError(error);
      const revertHierarchy = Object.assign(store.state.hierarchyState.hierarchy, {
          hierarchyName: '',
          assetKey: '',
          assetName: '',
          siteKey: '',
          siteName: '',
        });
      store.commit('hierarchyState/updateHierarchy', revertHierarchy);
      store.commit('hierarchyState/removeRootZone');
    } finally {
      this.loading--;
    }
  }
}
