






































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IBurnerDefinition, IAttribute, IZone} from '../../view-models/hierarchy-view-models';
import Dropdown from '@/components/common/Dropdown.vue';
import store from '../../store';
import OnpointModal from '@/components/common/OnpointModal.vue';
import FormField from '@/components/common/FormField.vue';
import RadioField from '@/components/common/RadioField.vue';
import NestedAttribute from '@/components/attributes/NestedAttribute.vue';
import { cloneDeep } from 'lodash';
import { showAndCommitError, showError } from '@/utils/Helpers';
import {v4 as uuid} from 'uuid';
import AttributeUtil from '@/utils/attributeUtil';

@Component({
  components: {NestedAttribute, RadioField, FormField, Dropdown, OnpointModal}
})
export default class AssignAttributes extends Vue {
  @Prop( {required: true})
  private selection: IBurnerDefinition | IZone;
  @Prop( {required: true})
  private isBurner: boolean;
  private showAttributeModal: boolean = false;
  private attributeToCreate: IAttribute = {};
  private copiedAttributes: IAttribute[] = [];
  private deleteIsOpen: boolean = false;

  private created(): void {
    this.initializeAttributes();
  }

  @Watch('selection')
  private initializeAttributes(): void {
    if (!this.selection.attributes) {
      this.selection.attributes = [];
    }
  }

  private async createAttribute(): Promise<void> {
    if (this.dataTypeValidationError) { return showError(this.dataTypeValidationError); }
    if (!this.attributeToCreate.name ||
        !this.attributeToCreate.attributeType ||
        !this.attributeToCreate.dataType ||
        !this.attributeToCreate.direction ||
        (!this.attributeToCreate.value && this.attributeToCreate.attributeType !== 'DYNAMIC')) { return showError('Enter a value for all fields.'); }
    this.attributeToCreate.attributeKey = uuid();
    this.selection.attributes.push({...this.attributeToCreate});
    this.updateSelection();
    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      this.closeAttributeModal();
    } catch (error) {
      showAndCommitError(error);
      this.selection.attributes.pop();
    }
    this.updateSelection();
  }

  private closeAttributeModal(): void {
    this.showAttributeModal = false;
    this.attributeToCreate = {};
  }

  private openDelete(): void {
    this.deleteIsOpen = true;
  }

  private closeDelete(): void {
    this.deleteIsOpen = false;
  }

  private async deleteAttributes(): Promise<void> {
    const tempAtts = [...this.selection.attributes];
    this.selection.attributes.splice(0, this.selection.attributes.length);
    this.updateSelection();
    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      this.closeDelete();
    } catch (error) {
      showAndCommitError(error);
      this.selection.attributes = [...tempAtts];
    }

    this.updateSelection();
  }

  private copyAttributes(): void {
    this.copiedAttributes = cloneDeep(this.selection.attributes);
    store.commit('hierarchyState/copyAttributes', this.copiedAttributes);
  }

  private async pasteAttributes(): Promise<void> {
    if (!store.state.hierarchyState.copiedAttributes ||
      !store.state.hierarchyState.copiedAttributes.length) { return showError('No attributes are copied.'); }

    store.state.hierarchyState.copiedAttributes.forEach((attribute) => {
      this.selection.attributes.push({...attribute});
    });
    this.updateSelection();
    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
    }
    this.updateSelection();
  }

  private updateSelection(): void {
    if (this.isBurner) {
      store.commit('hierarchyState/updateBurner', {
        burnerKey: (this.selection as IBurnerDefinition).burnerKey,
        zoneKey: (this.selection as IBurnerDefinition).zoneKey,
        burner: this.selection as IBurnerDefinition
      });
    } else {
      store.commit('hierarchyState/updateZone', {
        zoneKey: (this.selection as IZone).zoneKey,
        zone: this.selection as IZone
      });
    }
  }

  private get dataTypeValidationError(): string {
    return AttributeUtil.dataTypeValidationError(this.attributeToCreate);
  }
}
