import { NavigationGuard } from 'vue-router';
import ConfigFactory from './config-factory';
import store from '../store/';
import {
  getParentAppSdk,
  IAuthClient,
  authClient as parentAuthClient,
  inParentApp
} from '../utils/FromParent';

let authClient: IAuthClient = null;

async function getAuthClient(): Promise<IAuthClient> {
  // If Auth0 client already initialized, return it.  Else, create a new one.
  if (authClient == null) {
    // Retrieve app configs
    let client = getParentAppSdk()?.authClient;

    if (client == null) {
      client = await initClient(parentAuthClient);
    }
    authClient = client;
  }
  return authClient;
}

async function initClient(parentClient: IAuthClient): Promise<IAuthClient> {
  const conf = await ConfigFactory.GetConfig();
  const client = parentClient;

  const authDomain = process.env.VUE_APP_AUTH0_DOMAIN
    ? process.env.VUE_APP_AUTH0_DOMAIN
    : conf.get('domain');

  const authClientID = process.env.VUE_APP_AUTH0_CLIENT_ID
    ? process.env.VUE_APP_AUTH0_CLIENT_ID
    : conf.get('clientId');

  const authAudience = process.env.VUE_APP_AUTH0_AUDIENCE
    ? process.env.VUE_APP_AUTH0_AUDIENCE
    : conf.get('audience');

  const redirectUriPropName = inParentApp() ? 'redirectUriParent' : 'redirectUriStandalone';
  const authRedirectUri = process.env.VUE_APP_AUTH0_REDIRECT_URI
    ? process.env.VUE_APP_AUTH0_REDIRECT_URI
    : conf.get(redirectUriPropName);

  await client.init({ authDomain, authClientID, authAudience, authRedirectUri });
  return client;
}

export async function getAuthToken(): Promise<string> {
  const client = await getAuthClient();
  return client.getAuthToken();
}

export const authGuard: NavigationGuard = async (to: any, from: any, next: () => void) => {
  // Silently check for the presence of a token
  getAuthToken().then(() => {
    next();
  }).catch(async () => {
    const client = await getAuthClient();
    client.loginWithRedirect();
  });
};

if (!inParentApp()) {
  window.addEventListener('load', async () => {
    if (window.location.search.includes('code=') &&
      window.location.search.includes('state=')) {
      try {
        // Only process this event if you are expecting it, i.e., your Auth0 client is initialized
        if (authClient !== null) {
          const client = await getAuthClient();
          await client.handleRedirectCallback();
        }
      } catch (err) {
        const errorString = 'Authorization error. \n' + JSON.stringify(err);
        store.commit('errorState/setError', {
          error: errorString,
          handleError: true,
          routeHomeAfterError: false
        });
      }
    }
  });
}
