



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Dropdown from '../common/Dropdown.vue';
import FormField from '../common/FormField.vue';
import OnpointModal from '../common/OnpointModal.vue';
import SubzoneItem from './SubzoneItem.vue';
import store from '../../store';
import { ITreeLiaison, ITreeItem, IZone, IUpdateZoneDetails } from '@/view-models/hierarchy-view-models';
import {hbEventBus} from '@/eventBus/hierarchy-event-bus';
import { cloneDeep } from 'lodash';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import { showAndCommitError, showError } from '@/utils/Helpers';

@Component({
  components: { Dropdown, FormField, OnpointModal, SubzoneItem }
})
export default class Subzone extends Vue {
  @Prop({ required: true })
  private item: ITreeItem;

  private nameInput: string = this.item.name ? this.item.name : '';
  private editName: boolean = !this.item.name;
  private showDetailsModal: boolean = false;
  private opportunityPriority: number = this.getRefreshedOpportunityPriority();
  private lowerTolerance: number  = this.getRefreshedLowerTolerance();
  private upperTolerance: number  = this.getRefreshedUpperTolerance();
  private notes: string = this.getRefreshedNotes();
  private showDeleteModal: boolean = false;
  private depth: number = 1;
  private showChangeViewMessage: boolean = false;
  private draggingBurners: boolean = false;

  private mounted(): void {
    if (this.editName) {
      const input: HTMLInputElement = this.$refs.nameInput as HTMLInputElement;
      input.focus();
    }

    this.depth = this.calculateDepth(this.depth, this.$refs.subzoneElement as Element);

    hbEventBus.$on(TreeActionTypeEnums.RenameZone, (item: ITreeItem) => {
      if (item && item.key === this.item.key) {
        this.nameInput = item.name ? item.name : '';
        if (item.name) { this.editName = false; }
      }
    });

    hbEventBus.$on(TreeActionTypeEnums.UpdateZoneDetails, (item: ITreeItem) => {
      if (item && item.key === this.item.key) {
        this.nameInput = item.name ? item.name : '';
        this.opportunityPriority = this.getRefreshedOpportunityPriority();
        this.lowerTolerance = this.getRefreshedLowerTolerance();
        this.upperTolerance = this.getRefreshedUpperTolerance();
        this.notes = this.getRefreshedNotes();
      }
    });

    hbEventBus.$on(TreeActionTypeEnums.StartDragBurner, () => {
      this.draggingBurners = true;
    });

    hbEventBus.$on(TreeActionTypeEnums.EndDragBurner, () => {
      this.draggingBurners = false;
    });
  }

  private beforeDestroy(): void {
    hbEventBus.$off(TreeActionTypeEnums.RenameZone);
    hbEventBus.$off(TreeActionTypeEnums.UpdateZoneDetails);
    hbEventBus.$off(TreeActionTypeEnums.StartDragBurner);
    hbEventBus.$off(TreeActionTypeEnums.EndDragBurner);
  }

  private getRefreshedOpportunityPriority(): number {
    return this.fullZoneData && this.fullZoneData.opportunityPriority ? this.fullZoneData.opportunityPriority : 1;
  }

  private getRefreshedLowerTolerance(): number {
    return this.fullZoneData && this.fullZoneData.lowerTolerance ? this.fullZoneData.lowerTolerance : null;
  }

  private getRefreshedUpperTolerance(): number {
    return this.fullZoneData && this.fullZoneData.upperTolerance ? this.fullZoneData.upperTolerance : null;
  }

  private getRefreshedNotes(): string {
    return this.fullZoneData && this.fullZoneData.notes ? this.fullZoneData.notes : '';
  }

  private toggleIsOpen(item: ITreeItem): void {
    store.commit('hierarchyState/toggleZone', item.key);
  }

  private showEditName(): void {
    this.editName = true;
    this.$nextTick().then(() => {
      const input: HTMLInputElement = this.$refs.nameInput as HTMLInputElement;
      input.focus();
    });
  }

  private async submitName(item: ITreeItem, newValue: string): Promise<void> {
    if (!newValue) { return; }

    const newSubzone = {item, value: newValue};
    const prevName = item.name;
    const prevSubzone = {item, value: prevName};

    // update the tree item (item) and the zone with new name in state
    item.name = newValue;
    store.commit('hierarchyState/renameSubzone', newSubzone);

    // hide the input
    this.editName = false;

    // save hierarchy to database

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      hbEventBus.$emit(TreeActionTypeEnums.RenameZone, item);
    } catch (error) {
      showAndCommitError(error);
      item.name = prevName;
      this.nameInput = prevName ? prevName : '';
      store.commit('hierarchyState/renameSubzone', prevSubzone);

      // show the input if there is no name
      if (!item.name) {
        this.editName = true;
      }
    }
  }

  private addSubzone(item: ITreeItem): void {
    if (!this.isOpen) {
      this.toggleIsOpen(this.item);
    }

    // don't allow subzone to be added when zone is nested too deep
    if (this.depth >= 3) {
      this.showChangeViewMessage = true;
      return;
    }

    const emitOb: ITreeLiaison = {
      source: 'newSubZone',
      target: item
    };
    store.commit('hierarchyState/updateHierarchySubzones', emitOb);
  }

  private async submitDetails(): Promise<void> {
    if (!this.nameInput || !this.opportunityPriority) {
      showError('Missing value for required field.');
      return;
    }

    const newDetails: IUpdateZoneDetails = {
      name: this.nameInput,
      opportunityPriority: this.opportunityPriority,
      lowerTolerance: this.lowerTolerance,
      upperTolerance: this.upperTolerance,
      zoneKey: this.item.associatedZoneKey,
      treeItemKey: this.item.key,
      notes: this.notes
    };

    const prevName = this.item.name;
    const prevOppScore = this.fullZoneData.opportunityPriority || 1;
    const prevLowerTolerance = this.fullZoneData.lowerTolerance;
    const prevUpperTolerance = this.fullZoneData.upperTolerance;
    const prevNotes = this.fullZoneData.notes || '';

    store.commit('hierarchyState/updateSubzone', newDetails);

    this.hideDetailsModal();
    this.editName = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
      hbEventBus.$emit(TreeActionTypeEnums.UpdateZoneDetails, this.item);
    } catch (error) {
      showAndCommitError(error);
      const prevDetails: IUpdateZoneDetails = {
          name: prevName,
          opportunityPriority: prevOppScore,
          lowerTolerance: prevLowerTolerance,
          upperTolerance: prevUpperTolerance,
          zoneKey: this.item.associatedZoneKey,
          treeItemKey: this.item.key,
          notes: this.notes
        };
      store.commit('hierarchyState/updateSubzone', prevDetails);

      // revert v-model values for the details modal
      this.nameInput = prevName ? prevName : '';
      this.opportunityPriority = prevOppScore;
      this.lowerTolerance = prevLowerTolerance;
      this.upperTolerance = prevUpperTolerance;
      this.notes = prevNotes;
    }
  }

  private hideDetailsModal(): void {
    this.showDetailsModal = false;
  }

  private editSubzoneDetails(): void {
    this.showDetailsModal = true;
  }

  private get isOpen(): boolean {
    return store.state.hierarchyState.openZones.includes(this.item.key);
  }

  private async deleteSubzone(): Promise<void> {
    const hierarchyDeepCopy = cloneDeep(store.state.hierarchyState.hierarchy);

    await store.dispatch('hierarchyState/deleteSubzone', this.item);
    this.showDeleteModal = false;

    try {
      await store.dispatch('hierarchyState/saveHierarchy', store.state.hierarchyState.hierarchy);
    } catch (error) {
      showAndCommitError(error);
      store.commit('hierarchyState/updateHierarchy', hierarchyDeepCopy);
    }
  }

  private openDeleteModal(): void {
    this.showDeleteModal = true;
  }

  private hideDeleteModal(): void {
    this.showDeleteModal = false;
  }

  private dropItem(target: ITreeItem, event: DragEvent): void {
    const element: HTMLElement = event.target as HTMLElement;

    // when the event target is not in this subzone, return
    if (this.$el !== element.closest('.subzone')) {
      event.stopPropagation();
      return;
    }

    if (!this.isOpen) {
      this.toggleIsOpen(this.item);
    }

    // get data being transfered
    const source = JSON.parse(event.dataTransfer.getData('text/plain'));
    const sourceExistsInTarget = this.childBurners.some((burner) => burner.burnerTypeKey === source.burnerTypeKey);

    // don't allow burners to be added when zone is nested too deep
    // or when the burner already exists in the subzone
    if (this.depth > 3 || sourceExistsInTarget) {
      event.stopPropagation();
      return;
    }

    // hide the change view message if it is showing and burner type can be added
    this.showChangeViewMessage = false;

    if (!source.key) {
      const newElement: ITreeLiaison = {
        source,
        target
      };
      store.dispatch('hierarchyState/handleLeafToZoneDrag', newElement);
      event.stopPropagation();
      return;
    }

    const actionType: TreeActionTypeEnums =
      source.type === 'Zone'
        ? TreeActionTypeEnums.ZoneToZone
        : TreeActionTypeEnums.LeafToZone;

    const data: ITreeLiaison = {
      source,
      target
    };
    if (actionType === TreeActionTypeEnums.LeafToZone) {
      store.dispatch('hierarchyState/handleLeafToZoneDrag', data);
    } else if (actionType === TreeActionTypeEnums.ZoneToZone) {
      store.commit('hierarchyState/handleZoneToZoneDrag', data);
    }
    event.stopPropagation();
  }

  private calculateDepth(depth: number, element: Element): number {
    const parentSubzone: Element = element.parentElement ? element.parentElement.closest('.subzone') : null;
    if (!parentSubzone) {
      return depth;
    }
    return this.calculateDepth(depth + 1, parentSubzone);
  }

  private get childSubzones(): ITreeItem[] {
    return this.treeItems.filter((item: ITreeItem) => {
      return item.parentKey === this.item.key && item.type === 'Zone';
    });
  }

  private get treeItems(): ITreeItem[] {
    return store.getters['hierarchyState/treeItems']();
  }

  private get childBurners(): ITreeItem[] {
    return this.treeItems.filter((item: ITreeItem) => {
      return item.parentKey === this.item.key && item.type === 'Leaf';
    });
  }

  private get zones(): IZone[] {
    return store.state.hierarchyState.hierarchy.zones;
  }

  private get fullZoneData(): IZone {
    return this.zones.find((zone) => {
      return zone.zoneKey === this.item.associatedZoneKey;
    });
  }
}
