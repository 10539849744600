import { IParserError, ParserError } from '@/view-models/parser-error';
import { IAttribute, IBurnerDefinition, IFormulaAttribute } from '@/view-models/hierarchy-view-models';
import { FormulaHelper } from '@/components/hierarchy/modals/formula-helper';
import { ATTRIBUTE_TYPES } from '@/enums/hierarchy-builder-types';
import StringUtil from '@/utils/stringUtil';

export function validateUniqueAttributeNames(attributes: any[], parentKey: string, errors: IParserError[]): void {
  const allNames: any[] = [];
  attributes.forEach((attribute: IAttribute) => {
    if (allNames.indexOf(attribute.name) === -1) {
      allNames.push(attribute.name);
    } else {
      errors.push(
        new ParserError(
          attribute.index,
          `Invalid ObjectName. Received name '${attribute.name}' is not unique for parent ${parentKey}`
        )
      );
    }
  });
}

export function validateUniqueAttributeTagNames(
  attributeTagNames: string[],
  attribute: IAttribute,
  errors: IParserError[]
): boolean {
  if (attributeTagNames.includes(attribute.tagName)) {
    errors.push(
      new ParserError(
        attribute.index || -1,
        `Duplicate Attribute Tag Name. Received name '${attribute.tagName}' is not unique for Hierarchy`
      )
    );
    return false;
  }
  return true;
}

export function validateUniqueBurnerNames(burnerDefinitions: any[], parentKey: string, errors: IParserError[]): void {
  const allNames: any[] = [];
  burnerDefinitions.forEach((burnerDefinition: IBurnerDefinition) => {
    if (allNames.indexOf(burnerDefinition.burnerName) === -1) {
      allNames.push(burnerDefinition.burnerName);
    } else {
      errors.push(
        new ParserError(
          burnerDefinition.index,
          `Invalid ObjectName. Received name '${burnerDefinition.burnerName}' is not unique for parent ${parentKey}`
        )
      );
    }
  });
}

export function checkAttributesForMissingParentKeys(
  attributeHashTable: any,
  parentKeys: any[],
  errors: IParserError[]
): void {
  for (const parentKey in attributeHashTable) {
    if (parentKeys.indexOf(parentKey) === -1) {
      attributeHashTable[parentKey].forEach((attribute: IAttribute) => {
        errors.push(
          new ParserError(attribute.index || -1, `Unknown parent key for attribute. Received value ${parentKey}.`)
        );
      });
    }
  }
}

export function validateAllFormulae(attributeHashTable: any, errors: IParserError[]): void {
  for (const parentKey in attributeHashTable) {
    attributeHashTable[parentKey].forEach((attribute: IAttribute) => {
      if (attribute.attributeType === ATTRIBUTE_TYPES.FORMULA) {
        const errorMessage = FormulaHelper.validateIntegrity(attributeHashTable,
          parentKey,
          (attribute as IFormulaAttribute));
        if (!StringUtil.empty(errorMessage)) {
          errors.push(new ParserError(attribute.index || -1, errorMessage));
        }
      }
    });
  }
}

export function checkBurnersForMissingBurnerKeys(
  burnerHashTable: any,
  parentKeys: any[],
  errors: IParserError[]
): void {
  for (const parentKey in burnerHashTable) {
    if (parentKeys.indexOf(parentKey) === -1) {
      burnerHashTable[parentKey].forEach((burner: IBurnerDefinition) => {
        errors.push(new ParserError(burner.index || -1, `Unknown parent key for burner. Received value ${parentKey}.`));
      });
    }
  }
}
