



















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { showAndCommitError, showError } from '@/utils/Helpers';
import store from '@/store';
import { BootstrapVue, BDropdown } from 'bootstrap-vue';
import { Route } from 'vue-router';
import Loading from '@/components/common/Loading.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import SelectDropdown from '@/components/common/SelectDropdown.vue';
import OnpointModal from '@/components/common/OnpointModal.vue';
import { IAsset } from '@/view-models/assets-view-models';
import { IAssignmentItem } from '@/view-models/customer-view-models';
import AssetTree from '@/components/common/AssetTree.vue';
import { IHierarchyDefiniton } from '@/view-models/hierarchy-view-models';
import { exportHierarchy, exportBurnerTypes } from '@/components/hierarchy/modals/export-parser';
import * as moment from 'moment';
import ImportHierarchyModal from '@/components/hierarchy/modals/ImportHierarchyModal.vue';
import { HIERARCHY_STATES, getHierarchyStateString } from '@/utils/StoreHelpers';
import { hbEventBus } from '@/eventBus/hierarchy-event-bus';
import { TreeActionTypeEnums } from '@/enums/treeActions';
import { cloneDeep } from 'lodash';

Vue.use(BootstrapVue);

@Component({
  components: { ImportHierarchyModal, Loading, AssetTree, Dropdown, OnpointModal, SelectDropdown }
})
export default class HomePage extends Vue {
  public selectedSite: IAssignmentItem = null;
  public selectedAsset: IAsset = null;
  public refreshing: boolean = false;
  private siteDropdownHelper: boolean = false;
  private assetDropdownHelper: boolean = false;
  private enableExportBurnerTypes = true;
  private enableExportHierarchy = true;
  private hierarchySelected = {} as IHierarchyDefiniton;
  private showDeleteModal: boolean = false;
  private showPublishModal: boolean = false;
  private showTestModal: boolean = false;
  private showRevertDraftModal: boolean = false;
  private showExportModal = false;
  private store = store;
  private filteredHierarchies: IHierarchyDefiniton[] = [...store.state.hierarchyState.hierarchies];
  private statuses = HIERARCHY_STATES;
  private getHierarchyStateString = getHierarchyStateString;
  private hierarchyFields = [
    {
      key: 'hierarchyName',
      label: 'Name',
      sortable: true
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true
    },
    {
      key: 'siteName',
      label: 'Site',
      sortable: true
    },
    {
      key: 'assetName',
      label: 'Asset',
      sortable: true
    },
    {
      key: 'dateCreated',
      label: 'Date Created',
      sortable: true
    },
    {
      key: 'updatedBy',
      label: 'Last Modified By',
      sortable: true
    },
    {
      key: 'dateUpdated',
      label: 'Last Modified',
      sortable: true
    },
    {
      key: 'menu',
      label: '',
      thStyle: { width: '32px' }
    }
  ];

  public async mounted(): Promise<void> {
    // set selections
    this.selectedSite = store.getters['asset/selectedSite'];
    this.selectedAsset = store.getters['asset/selectedAsset'];

    this.filteredHierarchies = [];
    // Ensure all dropdown actions are captured
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'site-selector-dropdown' && !this.siteDropdownHelper) {
        this.siteDropdownHelper = true;
      } else if (bvEvent.componentId === 'asset-selector-dropdown' && !this.assetDropdownHelper) {
        this.assetDropdownHelper = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'site-selector-dropdown' && this.siteDropdownHelper) {
        bvEvent.preventDefault();
        this.siteDropdownHelper = false;
      } else if (bvEvent.componentId === 'asset-selector-dropdown' && this.assetDropdownHelper) {
        bvEvent.preventDefault();
        this.assetDropdownHelper = false;
      }
    });
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    hbEventBus.$off(TreeActionTypeEnums.ConfirmHierarchyClone);
    await this.refreshData();
    store.commit('hierarchyState/cleanHierarchy');
  }

  public beforeDestroy(): void {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  public async handleRouteChange(parentRoute: Route): Promise<void> {
    if (parentRoute.path.match(/^\/?utilities\/ember\/hierarchy-builder\//)) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/hierarchy-builder\//, '/');
      if (currentRelativeRoute === '/') {
        await this.refreshData();
      }
    }
  }

  public get sites(): IAssignmentItem[] {
    const assignments = this.store.state.hierarchyState.availableAssignments.filter(
      (assignment: IAssignmentItem) => assignment.entityType === 'CustomerSite'
    );
    if (assignments) {
      return assignments.sort((assignmentX, assignmentY) => (assignmentX.name > assignmentY.name ? 1 : -1));
    } else {
      return [];
    }
  }

  public get assets(): IAsset[] {
    const filteredAssets = store.state.hierarchyState.assets.filter((asset: IAsset) => {
      return asset.siteKey === this.selectedSite?.key;
    });
    return filteredAssets.sort((assetX, assetY) => (assetX.equipmentName > assetY.equipmentName ? 1 : -1));
  }

  private async refreshData(): Promise<void> {
    this.refreshing = true;
    try {
      await store.dispatch('hierarchyState/retrieveServiceConfig');
      await store.dispatch('hierarchyState/retrieveAssets');
      await store.dispatch('hierarchyState/retrieveHierarchies');
    } catch (error) {
      showError(error.response ? error.response.data.message : error.message);
    }
    this.filterBySite();
    this.filterByAsset();
    this.refreshing = false;
  }

  private getHierarchyByKey(key: string): IHierarchyDefiniton {
    return store.state.hierarchyState.hierarchies.find((hierarchy) => hierarchy.key === key);
  }

  private getAssetName(assetKey: string): string {
    const foundAsset = this.assets.find((asset) => asset.key === assetKey);
    return foundAsset?.equipmentName;
  }

  private createHierarchy(): void {
    store.commit('hierarchyState/newHierarchy');
    this.$router.push('create');
  }

  private async editHierarchy(key: string): Promise<void> {
    const hierarchy: IHierarchyDefiniton = this.getHierarchyByKey(key);
    if (!hierarchy) {
      return;
    }

    if (!hierarchy.zones || !hierarchy.zones.length) {
      try {
        await store.dispatch('hierarchyState/retrieveZones', hierarchy);
      } catch (error) {
        showError(error.response ? error.response.data.message : error.message);
      }
    }

    store.commit('hierarchyState/updateHierarchy', hierarchy);
    store.commit('hierarchyState/updatePrevHierarchy', cloneDeep(hierarchy));
    await this.$router.push(`/hierarchy/${key}/hierarchy`);
  }

  private async openDelete(key: string): Promise<void> {
    this.hierarchySelected = this.getHierarchyByKey(key);
    this.showDeleteModal = true;
  }

  private closeDelete(): void {
    this.hierarchySelected = {} as IHierarchyDefiniton;
    this.showDeleteModal = false;
  }

  private async deleteHierarchy(): Promise<void> {
    try {
      await store.dispatch('hierarchyState/deleteHierarchy', this.hierarchySelected.key);
      this.closeDelete();
    } catch (error) {
      showError(error.response ? error.response.data.message : error.message);
    }
    this.closeDelete();
    await this.refreshData();
  }

  private async openPublish(key: string): Promise<void> {
    this.hierarchySelected = this.getHierarchyByKey(key);
    this.showPublishModal = true;
  }

  private closePublish(): void {
    this.showPublishModal = false;
  }

  private async openTest(key: string): Promise<void> {
    this.hierarchySelected = this.getHierarchyByKey(key);
    this.showTestModal = true;
  }

  private closeTest(): void {
    this.showTestModal = false;
  }

  private async openRevertDraft(key: string): Promise<void> {
    this.hierarchySelected = this.getHierarchyByKey(key);
    this.showRevertDraftModal = true;
  }

  private closeRevertDraft(): void {
    this.showRevertDraftModal = false;
  }

  private async importHierarchy(hierarchyKey: string): Promise<void> {
    this.refreshing = true;
    hbEventBus.$off(TreeActionTypeEnums.ConfirmHierarchyClone);
    hbEventBus.$on(TreeActionTypeEnums.ConfirmHierarchyClone, async () => {
      hbEventBus.$off(TreeActionTypeEnums.ConfirmHierarchyClone);
      await this.refreshData();
    });
    const importModal = this.$refs.importHierarchyModal as ImportHierarchyModal;
    importModal.clearUpload();
    const hierarchy = this.getHierarchyByKey(hierarchyKey);
    await store.dispatch('hierarchyState/retrieveZones', hierarchy);
    store.commit('hierarchyState/updateHierarchy', hierarchy);
    store.commit('hierarchyState/updatePrevHierarchy', cloneDeep(hierarchy));
    this.$bvModal.show('import-hierarchy-modal');
    this.refreshing = false;
  }

  private hideExportModal(): void {
    this.showExportModal = false;
  }

  private disableOkButton(): boolean {
    return !this.enableExportBurnerTypes && !this.enableExportHierarchy;
  }

  private async displayExportModal(key: string): Promise<void> {
    const hierarchy: IHierarchyDefiniton = this.getHierarchyByKey(key);
    if (!hierarchy) {
      return;
    }
    try {
      this.refreshing = true;
      await store.dispatch('hierarchyState/retrieveZones', hierarchy);
      store.commit('hierarchyState/updateHierarchy', hierarchy);
      this.enableExportBurnerTypes = true;
      this.enableExportHierarchy = true;
      this.showExportModal = true;
    } catch (error) {
      showError(error.response ? error.response.data.message : error.message);
    } finally {
      this.refreshing = false;
    }
  }

  private exportSelections(): void {
    if (this.enableExportBurnerTypes) {
      this.exportBurnerTypesCSV();
    }
    if (this.enableExportHierarchy) {
      this.exportHierarchyCSV();
    }
    this.hideExportModal();
  }

  private exportHierarchyCSV(): void {
    const exportedHierarchy = exportHierarchy(store.state.hierarchyState.hierarchy);
    const blob = new Blob([exportedHierarchy], { type: 'text/csv' });
    const link = document.createElement('a');
    const hierarchyName = store.state.hierarchyState.hierarchy.hierarchyName;
    const timeStamp = moment(new Date()).format('MM-DD-YYYY_HH-mm-ss');
    link.href = URL.createObjectURL(blob);
    link.download = hierarchyName + '_' + timeStamp + '_' + 'export.csv';
    link.click();
    URL.revokeObjectURL(link.href);
  }

  private exportBurnerTypesCSV(): void {
    const asset: IAsset = store.state.hierarchyState.assets.find(
      (x: IAsset) => x.key === store.state.hierarchyState.hierarchy.assetKey
    );
    const exportedBurnerTypes = exportBurnerTypes(asset);
    const blob = new Blob([exportedBurnerTypes], { type: 'text/csv' });
    const link = document.createElement('a');
    const assetName = asset.equipmentName;
    const timeStamp = moment(new Date()).format('MM-DD-YYYY_HH-mm-ss');
    link.href = URL.createObjectURL(blob);
    link.download = assetName + '_' + timeStamp + '_' + 'burner_types.csv';
    link.click();
    URL.revokeObjectURL(link.href);
  }

  private async updateHierarchyStatus(newStatus: HIERARCHY_STATES): Promise<void> {
    this.hierarchySelected.status = newStatus;

    try {
      await store.dispatch('hierarchyState/retrieveZones', this.hierarchySelected);
      await store.dispatch('hierarchyState/saveHierarchy', this.hierarchySelected);
      await this.refreshData();
    } catch (error) {
      showAndCommitError(error);
      await this.refreshData();
    }
  }

  private async publishHierarchy(): Promise<void> {
    this.closePublish();
    if (!this.hierarchySelected) {
      return;
    }

    await this.updateHierarchyStatus(HIERARCHY_STATES.PUBLISH);
  }

  private async testHierarchy(): Promise<void> {
    this.closeTest();
    if (!this.hierarchySelected) {
      return;
    }

    await this.updateHierarchyStatus(HIERARCHY_STATES.TESTING);
  }

  private async revertHierarchyToDraft(): Promise<void> {
    this.closeRevertDraft();
    if (!this.hierarchySelected) {
      return;
    }

    await this.updateHierarchyStatus(HIERARCHY_STATES.DRAFT);
  }

  @Watch('store.state.hierarchyState.availableAssignments')
  private resetSiteAssetSelection(): void {
    // Reset everything when customer is changed.
    // Check that selected site key exists in current assignments to determine this.
    if (
      !this.store.state.hierarchyState.availableAssignments.filter(
        (assignment: IAssignmentItem) => this.selectedSite && assignment.key === this.selectedSite.key
      ).length
    ) {
      this.selectedSite = null;
      store.commit('asset/selectSite', null);
      this.selectedAsset = null;
      store.commit('asset/selectAsset', null);
      this.filteredHierarchies = [];
    }
  }

  @Watch('selectedSite')
  private filterBySite(): void {
    if (this.selectedAsset !== null) {
      this.filteredHierarchies = store.state.hierarchyState.hierarchies.filter(
        (hierarchy) => hierarchy.siteKey === this.selectedSite?.key
      );
    }
  }

  @Watch('selectedAsset')
  private filterByAsset(): void {
    if (this.selectedAsset !== null) {
      this.filteredHierarchies = store.state.hierarchyState.hierarchies.filter(
        (hierarchy) => hierarchy.assetKey === this.selectedAsset?.key
      );
    }
  }

  private async selectModelSite(val: IAssignmentItem): Promise<void> {
    // Set site
    this.selectedSite = val;
    store.commit('asset/selectSite', val);
    this.selectedAsset = null;
    this.filteredHierarchies = [];
  }

  private selectModelAsset(val: IAsset): void {
    // Set asset
    this.selectedAsset = val;
    store.commit('asset/selectAsset', val);
  }

  private toggleDropdown(bvEvt: any): void {
    if (bvEvt.target.classList.contains('show')) {
      return;
    }

    // Ensure the menu actually opens
    bvEvt.vueTarget.show();
    bvEvt.vueTarget.show();
  }

  private toggleSiteDropdown(): void {
    // Ensure that menu actually opens.
    const siteDropdown = this.$refs.siteSelectorDropdown as BDropdown;
    const isSiteDropdownOpen = siteDropdown.visible;
    if (!isSiteDropdownOpen) {
      siteDropdown.show();
    } else {
      siteDropdown.hide();
    }
    this.siteDropdownHelper = false;
  }

  private toggleAssetDropdown(): void {
    // Ensure that menu actually opens.
    const assetDropdown = this.$refs.assetSelectorDropdown as BDropdown;
    const isAssetDropdownOpen = assetDropdown.visible;
    if (!isAssetDropdownOpen) {
      assetDropdown.show();
    } else {
      assetDropdown.hide();
    }
    this.assetDropdownHelper = false;
  }
}
