import { ROOT_ZONE_KEY } from '@/components/hierarchy/modals/hierarchy-file';
import { HierarchyFileRecord } from '@/components/hierarchy/modals/hierarchy-file-record';
import { IFormulaFunction, IFormulaOperand } from '@/view-models/hierarchy-view-models';
import { IParserError, ParserError } from '@/view-models/parser-error';
import {
  ATTRIBUTE_TYPES,
  ATTRIBUTE_DIRECTIONS,
  ATTRIBUTE_DATA_TYPES,
  OBJECT_TYPES
} from '@/enums/hierarchy-builder-types';
import StringUtil from '@/utils/stringUtil';

export enum COLUMNS {
  PARENT = 'Parent',
  ID = 'ID',
  NAME = 'Name',
  OBJECT_TYPE = 'ObjectType',
  OPPORTUNITY_SCORE_TYPE = 'OpportunityScoreType',
  OPPORTUNITY_SCORE_MULTIPLIER = 'OpportunityScoreMultiplier',
  LOWER_TOLERANCE = 'LowerTolerance',
  UPPER_TOLERANCE = 'UpperTolerance',
  BURNER_TYPE_ID = 'BurnerTypeID',
  ATTRIBUTE_DIRECTION = 'AttributeDirection',
  ATTRIBUTE_TYPE = 'AttributeType',
  ATTRIBUTE_DATA_TYPE = 'AttributeDataType',
  ATTRIBUTE_VALUE = 'AttributeValue',
  ATTRIBUTE_TAG_NAME = 'AttributeTagName',
  NOTES = 'Notes'
}

export class NativeFileRecord extends HierarchyFileRecord {
  private static readonly supportedFns: IFormulaFunction[] = [
    { fn: 'abs', dataMapperFn: 'abs', usage: 'abs(<attribute>)' },
    { fn: 'avg', dataMapperFn: 'avg', usage: 'avg(<attribute1>, ..., <attributeN>)' },
    { fn: 'exp', dataMapperFn: 'exp', usage: 'exp(<attribute>, <constant>)' },
    { fn: 'max', dataMapperFn: 'max', usage: 'max(<attribute1>, ..., <attributeN>)' },
    { fn: 'med', dataMapperFn: 'med', usage: 'med(<attribute1>, ..., <attributeN>)' },
    { fn: 'min', dataMapperFn: 'min', usage: 'min(<attribute1>, ..., <attributeN>)' },
    { fn: 'sum', dataMapperFn: 'sum', usage: 'sum(<attribute1>, ..., <attributeN>)' },
    { fn: 'if', dataMapperFn: 'if', usage: 'if(<condition(s)>, <trueValue>, <falseValue>)' },
    { fn: 'isnonnumeric', dataMapperFn: 'isnonnumeric', usage: 'isnonnumeric(<attribute1>,<defaultValue>' }
  ];
  public lineNumber: number;
  public parent: string;
  public id: string;
  public name: string;
  public objectType: string;
  public opportunityScoreType: string;
  public nativeOpportunityScoreMultiplier: string;
  public nativeLowerTolerance: string;
  public nativeUpperTolerance: string;
  public burnerTypeKey: string;
  public attributeDirection: string;
  public attributeType: string;
  public attributeDataType: string;
  public attributeValue: string;
  public attributeTagName: string;
  public notes: string;
  private isRootFound: boolean;

  constructor(record: any, isRootFound: boolean, lineNumber: number) {
    super();
    this.lineNumber                       = lineNumber;
    this.parent                           = record[COLUMNS.PARENT].trim();
    this.id                               = record[COLUMNS.ID].trim();
    this.name                             = record[COLUMNS.NAME].trim();
    this.objectType                       = record[COLUMNS.OBJECT_TYPE].toUpperCase().trim();
    this.opportunityScoreType             = record[COLUMNS.OPPORTUNITY_SCORE_TYPE].trim();
    this.nativeOpportunityScoreMultiplier = record[COLUMNS.OPPORTUNITY_SCORE_MULTIPLIER].trim();
    this.nativeLowerTolerance             = record[COLUMNS.LOWER_TOLERANCE].trim();
    this.nativeUpperTolerance             = record[COLUMNS.UPPER_TOLERANCE].trim();
    this.burnerTypeKey                    = record[COLUMNS.BURNER_TYPE_ID].trim();
    this.attributeDirection               = record[COLUMNS.ATTRIBUTE_DIRECTION].toUpperCase().trim();
    this.attributeType                    = record[COLUMNS.ATTRIBUTE_TYPE].toUpperCase().trim();
    this.attributeDataType                = record[COLUMNS.ATTRIBUTE_DATA_TYPE].toUpperCase().trim();
    this.attributeValue                   = record[COLUMNS.ATTRIBUTE_VALUE].trim();
    this.attributeTagName                 = record[COLUMNS.ATTRIBUTE_TAG_NAME].toUpperCase().trim();
    this.notes                            = record[COLUMNS.NOTES].toUpperCase().trim();
    this.isRootFound                      = isRootFound;
  }

  public get path(): string {
    if (this.isRootZone()) {
      return this.name;
    } else {
      return this.parent + '/' + this.name;
    }
  }

  public get opportunityPriority(): number {
    if (!this.nativeOpportunityScoreMultiplier) {
      return 1;
    }

    try {
      return Number(this.nativeOpportunityScoreMultiplier);
    } catch (error) {
      return 1;
    }
  }

  public get lowerTolerance(): number {
    if (!this.nativeLowerTolerance) {
      return 0;
    }

    try {
      return Number(this.nativeLowerTolerance);
    } catch (error) {
      return 0;
    }
  }

  public get upperTolerance(): number {
    if (!this.nativeUpperTolerance) {
      return 0;
    }

    try {
      return Number(this.nativeUpperTolerance);
    } catch (error) {
      return 0;
    }
  }

  public get formulaRaw(): string {
    if (this.attributeType === ATTRIBUTE_TYPES.FORMULA) {
      return this.attributeValue;
    }

    return '';
  }

  public get formulaOperands(): IFormulaOperand[] {
    return [];
  }

  public get formulaSupportedFns(): IFormulaFunction[] {
    return NativeFileRecord.supportedFns;
  }

  public isAttribute(): boolean {
    return this.objectType === OBJECT_TYPES.ATTRIBUTE;
  }

  public isBurner(): boolean {
    return this.objectType === OBJECT_TYPES.BURNER;
  }

  public isZone(): boolean {
    return this.objectType === OBJECT_TYPES.ZONE;
  }

  public isRootZone(): boolean {
    return this.name === ROOT_ZONE_KEY && this.isZone();
  }

  public validateFields(errors: IParserError[]): void {
    this.validateObjectType(errors);
    this.validateField(this.name, COLUMNS.NAME, errors);
    this.validateParent(errors);
    if (!this.isAttribute()) {
      this.validateNumericalField(this.nativeLowerTolerance, COLUMNS.LOWER_TOLERANCE, errors);
      this.validateNumericalField(this.nativeUpperTolerance, COLUMNS.UPPER_TOLERANCE, errors);
      this.validateOpportunityScoreMultiplier(errors);
    }
    this.validateAttribute(errors);
  }

  private validateField(field: any, column: string, errors: IParserError[]): void {
    if (field === null || field === undefined || field === '') {
      errors.push(new ParserError(this.lineNumber, `Missing ${column}`));
    }
  }

  private validateNumericalField(field: any, column: string, errors: IParserError[]): void {
    if (field.trim()) {
      try {
        if (isNaN(Number(field))) {
          errors.push(
            new ParserError(this.lineNumber, `Invalid ${column}. Must be number type. ` + `Received ${field}`)
          );
        }
      } catch (error) {
        errors.push(new ParserError(this.lineNumber, `Invalid ${column}. Must be number type. ` + `Received ${field}`));
      }
    }
  }

  private validateObjectType(errors: IParserError[]): void {
    this.validateField(this, COLUMNS.OBJECT_TYPE, errors);
    if (!Object.values(OBJECT_TYPES).includes(this.objectType as OBJECT_TYPES)) {
      errors.push(
        new ParserError(
          this.lineNumber,
          `Invalid ObjectType. Valid ObjectTypes are 'Zone', 'Burner', and 'Attribute'. ` +
            `Received ${this.objectType}`
        )
      );
    }
  }

  private validateParent(errors: IParserError[]): void {
    if (this.isRootFound && this.isRootZone()) {
      errors.push(new ParserError(this.lineNumber, `Multiple root nodes found.`));
    } else if (this.name !== ROOT_ZONE_KEY) {
      this.validateField(this.parent, COLUMNS.PARENT, errors);
    }
  }

  private validateOpportunityScoreMultiplier(errors: IParserError[]): void {
    if (this.nativeOpportunityScoreMultiplier.trim()) {
      try {
        const multiplier = Number(this.nativeOpportunityScoreMultiplier);
        if (multiplier < 0) {
          errors.push(
            new ParserError(
              this.lineNumber,
              `Invalid OppScoreMultiplier. Must be greater than 0. ` +
                `Received ${this.nativeOpportunityScoreMultiplier}`
            )
          );
        } else if (isNaN(Number(multiplier))) {
          errors.push(
            new ParserError(
              this.lineNumber,
              `Invalid OppScoreMultiplier. Must be number type. ` + `Received ${this.nativeOpportunityScoreMultiplier}`
            )
          );
        }
      } catch (error) {
        errors.push(
          new ParserError(
            this.lineNumber,
            `Invalid OppScoreMultiplier. Must be number type. ` + `Received ${this.nativeOpportunityScoreMultiplier}`
          )
        );
      }
    }
  }

  private validateAttributeDirection(errors: IParserError[]): void {
    if (this.attributeType === ATTRIBUTE_TYPES.FORMULA) {
      return;
    }

    if (!Object.values(ATTRIBUTE_DIRECTIONS).includes(this.attributeDirection as ATTRIBUTE_DIRECTIONS)) {
      errors.push(
        new ParserError(
          this.lineNumber,
          `Invalid AttributeDirection. Valid AttributeDirections are 'input' and 'result'. ` +
            `Received ${this.attributeDirection}`
        )
      );
    }
  }

  private validateAttributeType(errors: IParserError[]): void {
    if (!Object.values(ATTRIBUTE_TYPES).includes(this.attributeType as ATTRIBUTE_TYPES)) {
      errors.push(
        new ParserError(
          this.lineNumber,
          `Invalid AttributeType. Valid AttributeTypes are 'static', 'dynamic', ` +
            `and 'formula'. Received ${this.attributeType}`
        )
      );
    }
  }

  private validateAttributeDataType(errors: IParserError[]): void {
    if (this.attributeType === ATTRIBUTE_TYPES.FORMULA) {
      return;
    }

    if (!Object.values(ATTRIBUTE_DATA_TYPES).includes(this.attributeDataType as ATTRIBUTE_DATA_TYPES)) {
      errors.push(
        new ParserError(
          this.lineNumber,
          `Invalid AttributeDataType. Valid AttributeDataType are 'numeric', 'date', ` +
            `'string', and 'boolean'. Received ${this.attributeDataType}`
        )
      );
    }
  }

  private validateAttributeDataValue(errors: IParserError[]): void {
    if (this.attributeType !== ATTRIBUTE_TYPES.STATIC) {
      return;
    }

    this.validateField(this.attributeValue, COLUMNS.ATTRIBUTE_VALUE, errors);

    const attributeValueError = this.getAttributeValueError();
    if (attributeValueError != null) {
      errors.push(attributeValueError);
    }

    // Check for special error masking for formulas (condition 1)
    if (
      this.attributeDataType !== ATTRIBUTE_DATA_TYPES.STRING &&
      this.attributeDataType !== ATTRIBUTE_DATA_TYPES.DATE &&
      /"/g.test(this.attributeValue)
    ) {
      errors.push(
        new ParserError(
          this.lineNumber,
          `${this.name}: AttributeValue does not match AttributeDataType. ` +
            `Should this attribute be a “formula” AttributeType?`
        )
      );
    }
  }

  private getAttributeValueError(): IParserError {
    const errorMessage =
      `Invalid AttributeValue. AttributeValue must be valid for declared ` +
      `type ${this.attributeDataType}. Received ${this.attributeValue}`;
    try {
      switch (this.attributeDataType) {
        case ATTRIBUTE_DATA_TYPES.STRING:
          String(this.attributeValue);
          break;

        case ATTRIBUTE_DATA_TYPES.BOOLEAN:
          if (!StringUtil.isBoolean(this.attributeValue)) {
            return new ParserError(this.lineNumber, errorMessage);
          }
          break;

        case ATTRIBUTE_DATA_TYPES.NUMERIC:
          if (isNaN(Number(this.attributeValue))) {
            return new ParserError(this.lineNumber, errorMessage);
          }
          break;

        case ATTRIBUTE_DATA_TYPES.DATE:
          if (isNaN(Date.parse(this.attributeValue)) || Number(this.attributeValue)) {
            return new ParserError(this.lineNumber,
              `Invalid AttributeValue. AttributeValue must be valid for declared ` +
              `type DATE (MM-DD-YYYY). Received ${ this.attributeValue }`);
          }
          break;
      }
      return null;
    } catch (error) {
      return new ParserError(this.lineNumber, errorMessage);
    }
  }

  private validateAttributeTagName(errors: IParserError[]): void {
    if (this.attributeType !== ATTRIBUTE_TYPES.DYNAMIC) {
      return;
    }

    this.validateField(this.attributeTagName, COLUMNS.ATTRIBUTE_TAG_NAME, errors);

    // Check for special error masking for formulas (condition 2)
    if (
      (this.attributeTagName === null || this.attributeTagName === undefined || this.attributeTagName === '') &&
      /"/g.test(this.attributeValue)
    ) {
      errors.push(
        new ParserError(
          this.lineNumber,
          `${this.name}: Dynamic attribute is missing AttributeTagName. ` +
            `Should this attribute be a “formula” AttributeType?`
        )
      );
    }
  }

  private validateAttribute(errors: IParserError[]): void {
    if (!this.isAttribute()) {
      return;
    }

    this.validateAttributeType(errors);
    this.validateAttributeDirection(errors);
    this.validateAttributeDataType(errors);
    this.validateAttributeDataValue(errors);
    this.validateAttributeTagName(errors);
  }
}
