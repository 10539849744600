import Vue from 'vue';
import VeeValidate from 'vee-validate';
import enValidationMessages from 'vee-validate/dist/locale/en';
import frValidationMessages from 'vee-validate/dist/locale/fr';
import spaValidationMessages from 'vee-validate/dist/locale/es';

export default function(): void {
  Vue.use(VeeValidate, {
    delay: 1,
    dictionary: {
      // TODO handle other languages
      'en-US': enValidationMessages,
      'fr-FR': frValidationMessages,
      'spa-ES': spaValidationMessages
    },
    fieldsBagName: 'veeFields',
    i18nRootKey: 'validation',
    inject: true
  });
}
