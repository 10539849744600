import { HierarchyFile } from '@/components/hierarchy/modals/hierarchy-file';
import { NativeFile } from '@/components/hierarchy/modals/native-file';
import { PiAfFile } from '@/components/hierarchy/modals/pi-af-file';
import { IParserResult, ParserResult } from '@/view-models/parser-result';
import { ParserError } from '@/view-models/parser-error';
import { COLUMNS as PIAFCOLUMNS } from '@/components/hierarchy/modals/pi-af-file-record';
import { COLUMNS as NATIVECOLUMNS } from '@/components/hierarchy/modals/native-file-record';

export function parseFile(fileContents: string): IParserResult {
  let file: HierarchyFile;
  if (PiAfFile.isValidHeader(fileContents)) {
    file = new PiAfFile(fileContents);
  } else if (NativeFile.isValidHeader(fileContents)) {
    file = new NativeFile(fileContents);
  } else {
    const errors: ParserError[] = [];
    errors.push(new ParserError(0, `Invalid header order. Headers need to be in a particular order in file.`));
    errors.push(new ParserError(0, 'Pi Af Files use the following header order.'));
    errors.push(new ParserError(0, `${Object.values(PIAFCOLUMNS).join(' | \r\n')}`));
    errors.push(new ParserError(0, 'Native Files use the following header order.'));
    errors.push(new ParserError(0, `${Object.values(NATIVECOLUMNS).join(' | \r\n')}`));
    return new ParserResult([], errors);
  }

  return file.parse();
}
