import BaseService from './base-service';
import httpInstances, { getUmApiUrl } from './api-service';
import { IUser } from '@/view-models/user-view-models';

export class UserManagementService extends BaseService {
  constructor(apiUrl: string) {
    super(apiUrl, httpInstances.sharedUmAxiosInstance);
  }

  public static async factory(): Promise<UserManagementService> {
    const url = await getUmApiUrl();

    return new UserManagementService(url);
  }

  public async getUser(): Promise<IUser> {
    return this.httpClient.get<IUser>('users/current');
  }
}
